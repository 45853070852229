import {Injectable} from '@angular/core';
import {BehaviorSubject, interval, Observable, Subscription} from "rxjs";
import {cloneDeep} from "lodash";
import moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class DateService {

  private subscription: Subscription;

  private dDay: Date;

  private milliSecondsInASecond = 1000;

  private hoursInADay = 24;

  private minutesInAnHour = 60;

  private SecondsInAMinute = 60;

  private dayInWeek = 7;

  private timeDifference;

  private secondsToDday;

  private minutesToDday;

  private hoursToDday;

  private daysToDday;

  private weeksToDday;

  private weeksLabel = 'w';

  private daysLabel = 'd';

  private hoursLabel = 'h';

  private minutesLabel = 'm';

  private secondLabel = 's';

  private difference = new BehaviorSubject<any>(null);

  constructor() {
  }

  getDateDifferenceWithFeature(featureDate: string): Observable<any> {
    let date = new Date();
    this.dDay = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds());
    this.dDay = new Date(featureDate);
    this.subscription = interval(1000)
      .subscribe(x => {
        this.difference.next(this.getTimeDifference());
      });
    return this.difference;
  }

  isPreviousDate(dateToCheck) {
    let date = new Date();
    this.dDay = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds());
    this.dDay = new Date(dateToCheck);
    return moment(date).isAfter(this.dDay);
  }

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    return this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits(timeDifference) {
    let milliSecondsLeft = timeDifference;
    const diff = new TimeDifferenceObject();

    const milliSecondsInWeek = (this.milliSecondsInASecond * this.SecondsInAMinute * this.minutesInAnHour * this.hoursInADay * this.dayInWeek);
    const milliSecondsInDay = (this.milliSecondsInASecond * this.SecondsInAMinute * this.minutesInAnHour * this.hoursInADay);
    const milliSecondsInHour = (this.milliSecondsInASecond * this.SecondsInAMinute * this.minutesInAnHour);
    const milliSecondsInMinute = (this.milliSecondsInASecond * this.SecondsInAMinute);

    diff.weeksToDday = Math.floor(timeDifference / milliSecondsInWeek);
    milliSecondsLeft = diff.weeksToDday ? milliSecondsLeft - (diff.weeksToDday * milliSecondsInWeek) : milliSecondsLeft;

    diff.daysToDday = Math.floor((milliSecondsLeft) / milliSecondsInDay);
    milliSecondsLeft = diff.daysToDday ? milliSecondsLeft - (diff.daysToDday * milliSecondsInDay) : milliSecondsLeft;

    diff.hoursToDday = Math.floor((milliSecondsLeft) / milliSecondsInHour);
    milliSecondsLeft = diff.hoursToDday ? milliSecondsLeft - (diff.hoursToDday * milliSecondsInHour) : milliSecondsLeft;

    diff.minutesToDday = Math.floor((milliSecondsLeft) / milliSecondsInMinute);
    milliSecondsLeft = diff.minutesToDday ? milliSecondsLeft - (diff.minutesToDday * milliSecondsInMinute) : milliSecondsLeft;

    diff.secondsToDday = Math.floor((milliSecondsLeft) / this.milliSecondsInASecond);
    const dateWithZero = this.addZero(diff);
    return this.generateDateString(dateWithZero);
  }

  private addZero(diff) {
    const newDiff = cloneDeep(diff)
    newDiff.weeksToDday = diff.weeksToDday && diff.weeksToDday < 10 ? '0' + diff.weeksToDday.toString() : diff.weeksToDday;
    newDiff.daysToDday = diff.daysToDday && diff.daysToDday < 10 ? '0' + diff.daysToDday.toString() : diff.daysToDday;
    newDiff.hoursToDday = diff.hoursToDday && diff.hoursToDday < 10 ? '0' + diff.hoursToDday.toString() : diff.hoursToDday;
    newDiff.minutesToDday = diff.minutesToDday && diff.minutesToDday < 10 ? '0' + diff.minutesToDday.toString() : diff.minutesToDday;
    newDiff.secondsToDday = diff.secondsToDday && diff.secondsToDday < 10 ? '0' + diff.secondsToDday.toString() : diff.secondsToDday;
    return newDiff;
  }

  private generateDateString (dateDiff) {
    let dateString = '';
    if (dateDiff.weeksToDday) dateString = dateDiff.weeksToDday + this.weeksLabel + ' ';
    if (dateDiff.daysToDday) dateString += dateDiff.daysToDday + this.daysLabel + ' ';
    if (dateDiff.hoursToDday) dateString += dateDiff.hoursToDday + this.hoursLabel + ' ';
    if (dateDiff.minutesToDday) dateString += dateDiff.minutesToDday + this.minutesLabel + ' ';
    if (dateDiff.secondsToDday) dateString += dateDiff.secondsToDday + this.secondLabel + ' ';

    return dateString;
  }
}

export class TimeDifferenceObject {
  weeksToDday: string | number
  daysToDday: string | number
  hoursToDday: string | number
  minutesToDday: string | number
  secondsToDday: string | number
}

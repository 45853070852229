import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subject} from "rxjs";
import {APP_ROUTES} from "../../../app.routes.config";
import {Router} from "@angular/router";
import {GameService} from "../../../core/services/game.service";
import {map, switchMap, takeUntil} from "rxjs/operators";
import {BreakpointObserverService} from "../../../core/breakpoint-observer/breakpoint-observer.service";
import {LiveGameDetailsModel} from "../../../shared/models/LiveGameDetail";
import {cloneDeep} from "lodash";
import {ProcessTextService} from "../../../core/services/process-text.service";
import {AuthService} from "../../../core/services/auth.service";
import {RoundTypeEnum} from "../../../shared/enums/roundTypeEnum";
import {CMSContentConfigModel} from "../../../shared/models/CMSContentConfigModel";

export interface FilterCustomOption {
  allGame: string,
  brand: string,
  other: string
}

@Component({
  selector: 'hun-upcoming-games',
  templateUrl: './upcoming-games.component.html',
  styleUrls: ['./upcoming-games.component.scss']
})

export class UpcomingGamesComponent implements OnInit, OnDestroy {

  @Input() isWeb: Observable<boolean>;

  @Input() isSmall: Observable<boolean>;

  @Input() isMedium: Observable<boolean>;

  @Input() isXSmall: Observable<boolean>;

  @Input() chipNameList: any;

  @Input() isBrandPage: boolean;

  @Input() brandId: number;

  @Input() cmsContent$: Observable<CMSContentConfigModel>;

  titleFieldName = 'name';

  sum = 10;

  start: number = 0;

  colspan = 3;

  cols = 9;

  filters: {state:boolean, label: string, name: string}[] = [];

  pageNumber = 0;

  gameList = [];

  isAllGamesLoaded = false;

  showOnlyFollowingBrands = false;

  filterOption = [];

  type = null;

  isLoaded = false;

  isLoggedIn = false;

  isFiltering = false;

  private maxChipCharactersNumber = 19;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private gameService: GameService,
    private breakpointObserverService: BreakpointObserverService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private processTextService: ProcessTextService,
  ) {
  }

  ngOnInit(): void {
    this.cmsContent$.pipe(
      switchMap((config) => {
        const filterConfig: FilterCustomOption = {
          allGame: config['text_content']['games-all-games-filter'],
          brand: config['text_content']['games-your-brands-filter'],
          other: config['text_content']['games-other-filter']
        }
        return this.gameService.getSportTypes()
          .pipe(
            map((sportTypes) => {
              return [filterConfig, sportTypes];
            })
          )
      }),
      takeUntil(this.unsubscribe$)
    )
      .subscribe(([configTypes, sportTypes]:[FilterCustomOption, string[]]) => {
        const allGames =  {state: true, label: configTypes.allGame, name: 'allGames'};
        const brands =  {state: false, label: configTypes.brand, name: 'yourClubs'};
        this.filters = [allGames, brands];
        sportTypes.forEach(type => {
          const typeObj = {
            state: false,
            name: type,
            label: this.processTextService.capitalizeFirstLetter(type)
          };
          this.filters.push(typeObj);
        })
        this.filters.push({state: false, label: configTypes.other, name: 'OTHER'});
      });
    this.isLoggedIn = !!this.authService.getCurrentUser();
    if (this.isBrandPage || !this.isLoggedIn) {
      this.filters = this.filters.filter(f => f.name !== 'yourClubs');
    }
    this.fetchLiveGames(false);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectedChange(filterName) {
    this.type = null;
    this.isFiltering = true;
    this.isLoaded = false;
    if (filterName.name === 'OTHER') {
      this.filters
        .filter(filter => filter.name !== 'yourClubs' && filter.name !== 'OTHER')
        .forEach(filter => filter.state = false);
    } else {
      if (filterName.name !== 'yourClubs') {
        this.filters.find(filter => filter.name === 'OTHER').state = false;
      }
    }

    this.filters.find(f => f.name === filterName.name).state = !filterName.state;
    const selectedItem = this.filters
      .filter(f => f.name !== 'allGames')
      .find(f => f.state);

    const isShowAllGames = !(!!selectedItem);
    this.filters.find(f => f.name === 'allGames').state = isShowAllGames;

    if (filterName.name === 'allGames' || isShowAllGames) {
      this.filters.forEach(f => f.state = false);
      this.filters.find(f => f.name === 'allGames').state = true;
      this.showOnlyFollowingBrands = false;
      this.pageNumber = 0;
      this.filterOption = [];
      this.fetchLiveGames(true);
      return;
    }
    this.filterOption = this.filters
      .filter(f => f.state && f.name !== 'allGames')
      .map(f => f.name);

    const brandIndex = this.filterOption.findIndex(f => f === 'yourClubs');
    const isOtherType = this.filters.find(filter => filter.name === 'OTHER').state;

    if (filterName.name === 'OTHER' || isOtherType) {
      this.type = 'OTHER';
      this.filterOption = [];
    }

    if (brandIndex > -1) {
      this.showOnlyFollowingBrands = true;
      this.filterOption.splice(brandIndex, 1)
      this.pageNumber = 0;
      this.fetchLiveGames(true);
      return;
    }

    this.pageNumber = 0;
    this.fetchLiveGames(true);
  }

  onScroll() {
    if (this.isAllGamesLoaded) return;
    this.fetchLiveGames(false);

    this.start = this.sum;
    this.sum += 20;
  }

  handleSelectCard(card) {
    this.router.navigate([
      `${APP_ROUTES.GAME}/${card.id}`,
    ]);
  }

  fetchLiveGames(isFiltering) {
    this.pageNumber += 1;
    this.isAllGamesLoaded = false;

    const {pageNumber, filterOption, showOnlyFollowingBrands, brandId, type} = this;

    this.gameService.getLiveGames({pageNumber, filterOption, showOnlyFollowingBrands, brandId, type})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((games: { records: LiveGameDetailsModel[], total: number }) => {
        this.isLoaded = true;
        this.isFiltering = false

        if (!games.total) this.gameList = [];

        this.prepareGameList(games.records, isFiltering);
        if (this.gameList.length >= games.total) this.isAllGamesLoaded = true;
        this.gameService.setListEmptyStatus(!games.total);
        this.cdr.detectChanges();
      });
  }

  prepareGameList(gameList, isFiltering) {
    const newGamelist = gameList.map(game => {
      const newGame = cloneDeep(game);
      newGame.type = this.processTextService.capitalizeFirstLetter(game.type);
      newGame.sport = (game.sport && game.sport.length) && this.processTextService.capitalizeFirstLetter(game.sport[0]);
      if (!this.isBrandPage && game.sport.length && (game.sport[0].length + game.brandName?.length > this.maxChipCharactersNumber)) {
        const maxBrandSize = this.maxChipCharactersNumber - game.sport[0].length - 3;
        newGame.brandName = game.brandName.substring(0, maxBrandSize) + '...';
      }
      return newGame;
    })
    if (isFiltering) {
      this.gameList = newGamelist
    } else {
      this.gameList = [...this.gameList, ...newGamelist];
    }
  }

  getChipListArray(item) {
    if (item.type.toUpperCase() === RoundTypeEnum.Sport) return this.chipNameList;
    return this.chipNameList.filter(chip => chip.label.toUpperCase() !== RoundTypeEnum.Sport);
  }
}

export const HOME_SCREEN_ROUTES = {
  PROFILE: 'profile-settings',
  CHANGE_PASSWORD: 'set-new-password',
  LEADERBOARD:'leaderboard',
  YOUR_NFTS: 'locker',
  AUTH: 'auth',
  AUTH_SIGN_IN: 'auth/sign-in',
  AUTH_SIGN_UP: 'auth/sign-up',
  AUTH_FORGOTTEN_PASSWORD: 'auth/forgotten-password',
  GAMES: ''
};

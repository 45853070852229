import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {BreakpointObserverService} from "../../../core/breakpoint-observer/breakpoint-observer.service";

@Component({
  selector: 'hun-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  isWeb: Observable<boolean>;

  constructor(
    private breakpointObserverService: BreakpointObserverService,
  ) { }

  ngOnInit(): void {
    this.isWeb = this.breakpointObserverService.isWeb;
  }

}

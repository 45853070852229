import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
class Indicator {
  constructor(public ref: number) {}
}

@Component({
  selector: 'hun-carousel-indicator',
  templateUrl: './carousel-indicator.component.html',
  styleUrls: ['./carousel-indicator.component.scss']
})
export class CarouselIndicatorComponent implements OnInit, OnChanges {

  @Input() public current: number;
  @Input() public length: number;
  public indicators: Indicator[] = [];

  private MAX_VISIBLE_INDICATORS = 4;
  private min = 0;
  private max = this.MAX_VISIBLE_INDICATORS;

  ngOnChanges(changes: SimpleChanges): void {
    const chLength = changes.length;
    if (chLength) {
      for (let i = chLength.previousValue || 0; i < chLength.currentValue; i++) {
        this.indicators.push(new Indicator(i));
      }
    }
    const chCurrent = changes.current;
    if (chCurrent) {
      if (chCurrent.currentValue < this.min) {
        this.min = chCurrent.currentValue;
        this.max = this.min + this.MAX_VISIBLE_INDICATORS;
        if (this.max > this.length) {
          this.max = this.length;
        }
      }
      if (chCurrent.currentValue > this.max) {
        this.max = chCurrent.currentValue;
        this.min = this.max - this.MAX_VISIBLE_INDICATORS;
        if (this.min < 0) {
          this.min = 0;
        }
      }
    }
  }

  getIndicatorClass(ref: number): string {
    if (ref === this.current) {
      return 'active';
    }
    if (ref >= this.min && ref <= this.max) {
      return 'std';
    }
    if (ref === this.min - 1 || ref === this.max + 1) {
      return 'small';
    }
    if (ref === this.min - 2 || ref === this.max + 2) {
      return 'micro';
    }
    return 'hidden';
  }

  ngOnInit(): void {
  }
}

<div class="tag-chip">
  <mat-spinner *ngIf="!isDataLoaded" [diameter]="18" color="primary"></mat-spinner>

  <div *ngIf="!image && isDataLoaded">
    {{chipValue}}
  </div>
  <div *ngIf="image">
    <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" width="5" alt="Photo of a Shiba Inu">
  </div>
</div>

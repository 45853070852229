import {ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {LeaderboardsService} from "../../../core/services/leaderboards.service";
import {combineLatest, Observable, Subject} from "rxjs";
import {filter, switchMap, takeUntil} from "rxjs/operators";
import {LeaderBordDetailsModel, SingleLeaderModel} from "../../../shared/models/LeaderboardsDetail";
import {LeaderbordPeriodEnum} from "../../../shared/enums/leaderbordPeriodEnum";
import {ProcessTextService} from "../../../core/services/process-text.service";
import {BreakpointObserverService} from "../../../core/breakpoint-observer/breakpoint-observer.service";
import {HOME_SCREEN_ROUTES} from "../../home-screen.routing.config";
import {AuthService} from "../../../core/services/auth.service";
import {APP_DATA} from "../../../general.app.config";
import {LocalStorageService} from "../../../core/services/local-storage.service";
import {ViewportScroller} from "@angular/common";
import {SettingsConfigDetail} from "../../../shared/models/SettingsConfigDetail";
import {SaasSetingsService} from "../../../core/services/saas-setings.service";
import {CmsContentService} from "../../../core/services/cms-content.service";
import {PagesWithFilterEnum} from "../../../shared/enums/pagesWithFilterEnum";
import {MetaService} from "../../../core/services/meta.service";

@Component({
  selector: 'hun-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit, OnDestroy, OnChanges {

  pageNumber = 0;

  period = LeaderbordPeriodEnum.Weekly;

  month: string;

  isLoading = true;

  leaderList: SingleLeaderModel[] = [];

  isAllLoaded = false;

  leaderbordPeriodEnum = LeaderbordPeriodEnum;

  endDate: string;

  pageFilterOption: { state: boolean, label: string, name: string }[];

  isWeb: Observable<boolean>;

  HOME_SCREEN_ROUTES = HOME_SCREEN_ROUTES;

  selectedTab: string;

  isLoggedIn: boolean;

  totalPoints: number;

  position: number;

  monthes = [];

  currentDate: Date;

  currentYear: number;

  previousYear: number;

  dateNow: any;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private leaderboardsService: LeaderboardsService,
    private cdr: ChangeDetectorRef,
    private processTextService: ProcessTextService,
    private breakpointObserverService: BreakpointObserverService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private scroll: ViewportScroller,
    private saasSetingsService: SaasSetingsService,
    public cmsContentService: CmsContentService,
    private metaService: MetaService
  ) {
  }

  ngOnInit(): void {
    this.metaService.setMetaTags(this.cmsContentService.cmsContent$.value['seo_content']['leaderboard-screen']);

    this.saasSetingsService.settingConfigArray$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(config => console.log(config));
    this.localStorageService.removeItem(APP_DATA.LATEST_SELECTION_FILTER)
    this.currentDate = new Date();
    this.dateNow = new Date();
    this.currentYear = this.currentDate.getFullYear();
    this.previousYear = this.currentYear - 1;
    const monthesNumber = this.getLastFiveMonth();
    const formatter = new Intl.DateTimeFormat('en-IE', {month: 'short'});
    monthesNumber.forEach((monthNumber, index) => {
      this.currentDate.setMonth(monthNumber);
      const monthName = formatter.format(this.currentDate);
      const calculatedMonthNumber = monthNumber + 1;
      const montFormattedNumber = calculatedMonthNumber < 10 ? '0' + calculatedMonthNumber : calculatedMonthNumber;
      this.monthes.push({state: index === monthesNumber.length - 1, label: monthName, number: montFormattedNumber});
    });
    combineLatest([
      this.leaderboardsService.getUserScoreInfo(),
      this.authService.getIsCurrentUser()
    ]).pipe(takeUntil(this.unsubscribe$))
      .subscribe(([userInfo, user]) => {
        this.position = userInfo ? userInfo.position : null;
        this.totalPoints = userInfo ? userInfo.totalPoints : null;
        this.isLoggedIn = user;
      });
    this.isWeb = this.breakpointObserverService.isWeb;

    this.pageFilterOption = this.processTextService.createFilter(PagesWithFilterEnum.leaderboard);
    this.fetchLeaders();
  }

  fetchLeaders(isLoadFromScratch = true) {
    this.isLoading = true;
    this.pageNumber += 1;
    this.leaderboardsService.getSelectedFilter()
      .pipe(
        filter(response => {
          const isSingleTab = response === 'Weekly' || response === 'Monthly';
          if (isSingleTab) {
            return !!response && isSingleTab && this.selectedTab !== response;
          }
          return !!response;
        }),
        switchMap(selectedPeriod => {
          this.isLoading = true;

          this.selectedTab = selectedPeriod;
          this.period = LeaderbordPeriodEnum[selectedPeriod];
          const selectedMonth = this.monthes.find((m) => m.state);
          if (this.period === LeaderbordPeriodEnum.Heroes) {
            const currentMont = this.dateNow.getMonth() + 1;
            if (currentMont < 6 && selectedMonth.number > 6) {
              this.month = `${this.previousYear}-${selectedMonth.number}`;
            } else {
              this.month = `${this.currentYear}-${selectedMonth.number}`;
            }
          }
          if (isLoadFromScratch) this.leaderList = [];
          if (this.period !== LeaderbordPeriodEnum.Heroes) this.month = null;
          const {pageNumber, period, month} = this;
          if (period === LeaderbordPeriodEnum.Heroes && !this.month) return null;
          return this.leaderboardsService.getLeaderboard({pageNumber, period: period, month})
        }),
        takeUntil(this.unsubscribe$))
      .subscribe((leaderboard: LeaderBordDetailsModel) => {
          this.leaderboardsService.setUserScoreInfo(leaderboard.user);
          this.leaderList = [...this.leaderList, ...leaderboard.leaders.records];
          this.endDate = leaderboard.lastMinute;
          if (this.leaderList.length >= leaderboard.leaders.total) this.isAllLoaded = true;
          this.isLoading = false;
          this.scroll.scrollToPosition([0, 0]);

          this.cdr.detectChanges();
        },
        () => {
          this.isLoading = false;
        })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onMonthSelect(month) {
    this.month = month;
    this.pageNumber = 0;
    this.fetchLeaders();
  }

  getLastFiveMonth() {
    const monthesNumber = [];
    for (let i = 5; i >= 1; i -= 1) {
      const newDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - i, 1);
      monthesNumber.push(newDate.getMonth());
    }
    return monthesNumber;
  }

  onScroll() {
    if (this.isAllLoaded) return;
    this.fetchLeaders(false);
  }

  selectedChange(month) {
    this.monthes.forEach(filter => filter.state = false);
    const selectedMonth = this.monthes.find((m) => m.label === month.label)
    selectedMonth.state = !month.state;
    this.createMonthRequest(selectedMonth);
  }

  createMonthRequest(selectedMonth) {
    let number: string | number = this.monthes.find(m => m.label === selectedMonth.label).number;
    if (number < 10) number = '0' + number;
    this.onMonthSelect(`${this.currentYear}-${number}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

}

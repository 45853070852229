import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BrandsService} from "../../../core/services/brands.service";
import {catchError, filter, switchMap, takeUntil} from "rxjs/operators";
import {Observable, of, Subject} from "rxjs";
import {BreakpointObserverService} from "../../../core/breakpoint-observer/breakpoint-observer.service";
import {ProcessTextService} from "../../../core/services/process-text.service";
import {cloneDeep} from "lodash";
import {SingleBrandDetail} from "../../../shared/models/BrandDetail";
import {LocalStorageService} from "../../../core/services/local-storage.service";
import {CurrentLocationService} from "../../../core/services/current-location.service";
import {APP_ROUTES} from "../../../app.routes.config";
import {DialogService} from "../../../shared/modules/common-control-components/components/dialog/dialog.service";
import {CmsContentService} from "../../../core/services/cms-content.service";

@Component({
  selector: 'hun-club-landing',
  templateUrl: './club-landing.component.html',
  styleUrls: ['./club-landing.component.scss']
})
export class ClubLandingComponent implements OnInit, OnDestroy {

  isWeb: Observable<boolean>;

  isSmall: Observable<boolean>;

  isMedium: Observable<boolean>;

  isXSmall: Observable<boolean>;

  brandId: number;

  brand: any;

  upcomingGameChips = [
    {name: 'type'},
    {name: 'sport'},
  ]

  isFollowing = false;

  followButtonText: string;

  isLoggedIn = false;

  isLoading = true;

  brandImageUrl: string;

  isBrandWithImage = true;

  fontSize = 47;

  desktopFontSize = 47;

  isImageLoading = false;

  followStatusChanges = false;

  isButtonClicked = false;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private brandsService: BrandsService,
    private breakpointObserverService: BreakpointObserverService,
    private processTextService: ProcessTextService,
    private localStorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private currentLocationService: CurrentLocationService,
    private dialogService: DialogService,
    private router: Router,
    public cmsContentService: CmsContentService
  ) {
  }

  ngOnInit(): void {
    this.isWeb = this.breakpointObserverService.isWeb;
    this.isSmall = this.breakpointObserverService.isSmallScreen;
    this.isMedium = this.breakpointObserverService.isMediumScreen;
    this.isXSmall = this.breakpointObserverService.isXSmall;

    this.isLoggedIn = !!this.localStorageService.getToken();
    this.brandId = Number(this.activatedRoute.snapshot.paramMap.get('id'));

    this.brandsService.getBrandById(this.brandId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((brand: SingleBrandDetail) => {
        this.processBrandDetail(brand);
        const pageName = this.currentLocationService.createNameWithSpaces(this.brand.name);
        this.currentLocationService.setCurrentPageName(pageName);
      })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleFollow() {
    this.isButtonClicked = true;
    if (!this.isLoggedIn) {
      this.dialogService.openNeedSignIn();
      return;
    }
    this.followStatusChanges = true;
    this.followButtonText = '';
    this.brandsService.toggleFollowBrand(this.brandId, !this.isFollowing)
      .pipe(
        catchError((e) => {
          this.followStatusChanges = false;
          return of(false);
        }),
        switchMap(() => {
          return this.brandsService.getBrandById(this.brandId);
        }))
      .subscribe(
        brandDetail => {
          this.followStatusChanges = false;
          this.processBrandDetail(brandDetail);
        },
        e => {
          this.followStatusChanges = false;
        })
  }

  processBrandDetail(brand) {
    this.isBrandWithImage = !!brand.backgroundImageUrl;
    this.brandImageUrl = brand.backgroundImageUrl;
    this.isLoading = false;
    const newBrand = cloneDeep(brand) as SingleBrandDetail;
    newBrand.numberOfFollowers = this.processTextService.createLongNumberFollowers(brand.numberOfFollowers as number);
    if (!newBrand.backgroundImageUrl) {
      newBrand.backgroundImageUrl = '../../../../../../assets/image/White-logo.png';
    }
    this.brand = newBrand;
    const delta = this.brand.name.length > 38 ? 1100 : this.brand.name.length > 23 ? 800 : 500;
    this.desktopFontSize = this.brand.name.length > 36 ? 34 : 47;
    this.fontSize = delta / this.brand.name.length;
    if (this.fontSize > 47) this.fontSize = 47;
    this.isFollowing = brand.isFollowing;
    this.isLoggedIn = !!this.localStorageService.getToken();
    this.followButtonText = this.isFollowing ? 'Unfollow' : 'Follow';
  }

  onLoadImage() {
    this.isImageLoading = true;
  }

}

<div
  [ngClass]="{'vertical-position': showDescriptionBelow}"
  class="timer"
  *ngIf="!isExpired">
  <div *ngIf="isDateSet" class="timer-container">

    <mat-icon *ngIf="!showDescriptionBelow && !label" class="material-icons material-icons-outlined timer-icon">timer</mat-icon>

    <div *ngIf="label">{{label}}&nbsp;</div>

    <div class="weeks" *ngIf="weeksToDday">
      <div class="number-element">{{weeksToDday}}</div>
      <div class="text-element">{{weeksLabel}}&nbsp;</div>
    </div>

    <div class="number-element dots" *ngIf="addDotsBetween && weeksToDday">:</div>

    <div class="days" *ngIf="daysToDday">
      <div class="number-element">{{daysToDday}}</div>
      <div class="text-element">{{daysLabel}}&nbsp;</div>
    </div>

    <div class="number-element dots" *ngIf="addDotsBetween && daysToDday">:</div>

    <div class="hours" *ngIf="hoursToDday">
      <div class="number-element">{{hoursToDday}}</div>
      <div class="text-element">{{hoursLabel}}&nbsp;</div>
    </div>

    <div class="number-element dots" *ngIf="addDotsBetween && hoursInADay">:</div>

    <div class="minutes" *ngIf="minutesToDday">
      <div class="number-element">{{minutesToDday}}</div>
      <div class="text-element">{{minutesLabel}}&nbsp;</div>
    </div>

    <div class="number-element dots" *ngIf="addDotsBetween && minutesToDday">:</div>

    <div class="seconds" *ngIf="showSeconds">
      <div class="number-element">{{secondsToDday}}</div>
      <div class="text-element">{{secondLabel}}&nbsp;</div>
    </div>
  </div>
  <mat-spinner *ngIf="!isDateSet" [diameter]="18" color="primary"></mat-spinner>


  <!--  -->
  <!--  <div *ngIf="daysToDday">{{daysToDday}}d&nbsp;</div>-->
  <!--  <div *ngIf="hoursInADay">{{hoursToDday}}h&nbsp;</div>-->
  <!--  <div>{{minutesToDday}}m</div>-->
  <!--  <span *ngIf="weeksToDday">{{weeksToDday}} w</span> <span *ngIf="daysToDday">{{daysToDday}} d</span> <span *ngIf="hoursInADay"> {{hoursToDday}} h</span> {{minutesToDday}} m-->
</div>

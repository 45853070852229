import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Location} from "@angular/common";
import {CmsContentService} from "../../../../../core/services/cms-content.service";

@Component({
  selector: 'hun-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

  @Input() customClickAction: boolean;

  @Input() disable: boolean;

  @Input() opacityBackground: boolean;

  @Input() isExitButton: boolean;

  @Input() buttonTextColor = '#FFF';

  @Input() buttonTextShadow = '1px 1px 1px #000';

  @Output() customClick = new EventEmitter<void>();

  constructor(
    private location: Location,
    public cmsContentService: CmsContentService,
  ) { }

  ngOnInit(): void {
  }

  goBack() {
    if (this.disable) return;
    if (!this.customClickAction) {
      this.location.back();
    }
    this.customClick.emit();
  }
}

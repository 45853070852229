import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { SaasSetingsService } from '../services/saas-setings.service';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OnlyFrameAccessGuard implements CanActivate {
  constructor(
    private saasSetingsService: SaasSetingsService
  ) {
  }

  canActivate() : Observable<boolean |UrlTree> {
    return this.saasSetingsService.settingConfigArray$.pipe(
      take(1),
      map(settings => {
        const isOnlyFrame = settings?.find(setting => setting.key === 'is_iframe_integration')?.value === 'true';
          if (isOnlyFrame && !window.location.href.includes('/frame')) {
            window.location.href = '/frame/landing';
            return false;
          }
          return true;
      })
    )
  }
}
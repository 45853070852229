<ng-container *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig">
  <div class="filter-list mobile" *ngIf="period === leaderbordPeriodEnum.Heroes && !(isWeb | async)">
    <mat-chip-list selectable>
      <mat-chip
        *ngFor="let month of monthes"
        [selected]="month.state"
        (click)="selectedChange(month)"
      >
        {{month.label}}
      </mat-chip>
    </mat-chip-list>
  </div>
  <div
    [ngClass]="{'desktop-style': isWeb | async, 'heroes-style': period === leaderbordPeriodEnum.Heroes}"
    class="leaderboard-container"
    infinite-scroll
    [infiniteScrollDistance]="0.5"
    (scrolled)="onScroll()">
    <h1 *ngIf="isWeb | async">
      {{period === leaderbordPeriodEnum.Heroes ?
      cmsContentConfig['text_content']['leaderboard-heroe-header-label'] :
      cmsContentConfig['text_content']['leaderboard-header-label']}}
    </h1>
    <hun-header-filter
      *ngIf="isWeb | async"
      [location]="HOME_SCREEN_ROUTES.LEADERBOARD"
      [filterOption]="pageFilterOption">
    </hun-header-filter>
    <div class="filter-list desktop" *ngIf="period === leaderbordPeriodEnum.Heroes && (isWeb | async)">
      <mat-chip-list selectable>
        <mat-chip
          *ngFor="let month of monthes"
          [selected]="month.state"
          (click)="selectedChange(month)"
        >
          {{month.label}}
        </mat-chip>
      </mat-chip-list>
    </div>
    <div class="leaderbord-content-container" [ngClass]="{}">
      <ng-container [ngSwitch]="period">
        <hun-monthly
          *ngSwitchCase="leaderbordPeriodEnum.Monthly"
          [cmsContent$]="cmsContentService.cmsContent$"
          [leadersList]="leaderList"
          [isLoading]="isLoading"
          [endDate]="endDate">
        </hun-monthly>
        <hun-weekly
          *ngSwitchCase="leaderbordPeriodEnum.Weekly"
          [cmsContent$]="cmsContentService.cmsContent$"
          [isLoading]="isLoading"
          [leadersList]="leaderList">
        </hun-weekly>
        <hun-heroes
          *ngSwitchCase="leaderbordPeriodEnum.Heroes"
          [cmsContent$]="cmsContentService.cmsContent$"
          [isLoading]="isLoading"
          [leadersList]="leaderList"
          (selectedMonth)="onMonthSelect($event)">
        </hun-heroes>
      </ng-container>
    </div>

  </div>
  <div class="statistic" [ngClass]="{
'desktop-style': isWeb | async,
'heroes': period === leaderbordPeriodEnum.Heroes}" *ngIf="isLoggedIn && position">
    <div class="text">
      <div class="score">
        <div class="text-content position">{{position}}</div>
        <div class="text-content">{{cmsContentConfig['text_content']['leaderboard-you-label']}}</div>
        <div class="text-content"> {{totalPoints}}{{cmsContentConfig['text_content']['games(profile)-points-label']}}</div>
      </div>
    </div>
  </div>
  <div class="space" [ngClass]="{
'desktop-style': isWeb | async
}"></div>
</ng-container>

<div *ngIf="isLoading" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>


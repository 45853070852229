import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FocusMonitor} from "@angular/cdk/a11y";
import {DialogWithImageModel} from "../../../../../models/DialogWithImageModel";
import {Observable, Subject} from "rxjs";
import {BreakpointObserverService} from "../../../../../../core/breakpoint-observer/breakpoint-observer.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'hun-dialog-with-image',
  templateUrl: './dialog-with-image.component.html',
  styleUrls: ['./dialog-with-image.component.scss']
})
export class DialogWithImageComponent implements OnInit, OnDestroy {

  imageUrl: string;

  isWeb: Observable<boolean>;

  private unsubscribe$: Subject<void> = new Subject();

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.isWeb = this.breakpointObserverService.isWeb;
  }

  constructor(
    public dialogRef: MatDialogRef<DialogWithImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogWithImageModel,
    private _focusMonitor: FocusMonitor,
    private breakpointObserverService: BreakpointObserverService,
  ) {
  }

  ngOnInit(): void {
    this.imageUrl = this.data.imageUrl
    this.isWeb = this.breakpointObserverService.isWeb;
    this.isWeb
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
      response ? this.dialogRef.addPanelClass('desktop-style') :
        this.dialogRef.removePanelClass('desktop-style');
    });
  }

  closeDialog() {
    this.dialogRef.close(true);
  }

  ngAfterViewInit(): void {
    this._focusMonitor.stopMonitoring(document.getElementById('stayButton'));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

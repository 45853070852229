import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UriConfig } from 'src/app/app.config';
import { SnackBarService } from './snack-bar.service';
import { CMSContentConfigModel } from "../../shared/models/CMSContentConfigModel";

@Injectable({
  providedIn: 'root'
})
export class CmsContentService {

  cmsContent$: BehaviorSubject<CMSContentConfigModel> = new BehaviorSubject(new CMSContentConfigModel);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private localStorageService: LocalStorageService,
    private snackBarService: SnackBarService,
  ) {}

  getCmsContentHash() {
    return this.http.get(`${this.uriConfig.cms}/check-modifying`);
  }

  getCmsContent(locale) {
    let params = new HttpParams();
    params = params.append('availability', locale);
    return this.http.get(`${this.uriConfig.cms}/content`, { params });
  }

  loadCmsContent(): Observable<any> {
    return this.getCmsContentHash().pipe(
      switchMap((hashResponse:{hash: string}) => {
        const hash = this.localStorageService.getCmsContentHash();
        const savedContent = this.localStorageService.getCmsContent();
        if (savedContent && hash === hashResponse.hash) {
          this.cmsContent$.next(savedContent);
          this.setUpColors(savedContent)
          return of(savedContent);
        } else {
          return this.getCmsContent('default').pipe(
            switchMap((content: any) => {
              this.localStorageService.setCmsContentHash(hashResponse.hash);
              const preparedContent = this.convertCmsContent(content)
              this.localStorageService.setCmsContent(preparedContent);
              this.cmsContent$.next(preparedContent);
              this.setUpColors(preparedContent);
              this.setUpImage(preparedContent);
              return of(content);
            })
          );
        }
      }),
      catchError(err => {
        this.snackBarService.showSnackBar(err.error.message);
        return throwError(err.error.message);
      })
    );
  }

  convertCmsContent(content): CMSContentConfigModel {
    const config = {};
    Object.keys(content).forEach(key => {
      config[key] = content[key].reduce((acc, item) => {
        acc[item.Name] = item['Colour'] || item['image_content'] || item['seo_content'] || item['Text value']
        return acc;
      }, {});
    });
    return config as CMSContentConfigModel;
  }

  setUpColors(content) {
    document.documentElement.style.setProperty('--primary-color-200', content['colour_content']['primary-200'])
    document.documentElement.style.setProperty('--primary-color-300', content['colour_content']['primary-300']);
    document.documentElement.style.setProperty('--primary-color-400', content['colour_content']['primary-400']);
    document.documentElement.style.setProperty('--primary-light-color-200', content['colour_content']['primary-light-color-200']);
    document.documentElement.style.setProperty('--primary-light-color-500', content['colour_content']['primary-light-color-500']);
    document.documentElement.style.setProperty('--primary-accent-color', content['colour_content']['primary-accent']);
    document.documentElement.style.setProperty('--secondary-color-800', content['colour_content']['secondary']);
    document.documentElement.style.setProperty('--secondary-light-color-300', content['colour_content']['secondary-light-color-300']);
    document.documentElement.style.setProperty('--secondary-light-color-100', content['colour_content']['secondary-light-color-100']);
    document.documentElement.style.setProperty('--accent-color', content['colour_content']['accent-color']);
    document.documentElement.style.setProperty('--light-color', content['colour_content']['light-color']);
    document.documentElement.style.setProperty('--light-background', content['colour_content']['light-background']);
    document.documentElement.style.setProperty('--light-background-200', content['colour_content']['light-background-200']);
    document.documentElement.style.setProperty('--dark-color', content['colour_content']['dark-color']);
    document.documentElement.style.setProperty('--game-button-background-unactive', content['colour_content']['game-button-background-unactive']);
    document.documentElement.style.setProperty('--game-button-color-unactive', content['colour_content']['game-button-color-unactive']);
    document.documentElement.style.setProperty('--dark-color-200', content['colour_content']['dark-color-200']);
    document.documentElement.style.setProperty('--correct-color', content['colour_content']['corect-color']);
    document.documentElement.style.setProperty('--wrong-color', content['colour_content']['wrong-color']);
    document.documentElement.style.setProperty('--facebook-color', content['colour_content']['facebook-color']);
    document.documentElement.style.setProperty('--google-color', content['colour_content']['google-color']);
    document.documentElement.style.setProperty('--leader-1', content['colour_content']['leader-1']);
    document.documentElement.style.setProperty('--leader-2', content['colour_content']['leader-2']);
    document.documentElement.style.setProperty('--leader-3', content['colour_content']['leader-3']);
    document.documentElement.style.setProperty('--iframe-prompt-notification-background', content['colour_content']['iframe-prompt-notification-background']);
    document.documentElement.style.setProperty('--iframe-prompt-dark', content['colour_content']['iframe-prompt-dark']);
    document.documentElement.style.setProperty('--iframe-prompt-light', content['colour_content']['iframe-prompt-light']);
    document.documentElement.style.setProperty('--iframe-upsell-background', content['colour_content']['iframe-upsell-background']);
    document.documentElement.style.setProperty('--iframe-menu-active-icon-filter', content['colour_content']['iframe-menu-active-icon-filter']);
    document.documentElement.style.setProperty('--iframe-opacity', content['colour_content']['iframe-opacity']);
    document.documentElement.style.setProperty('--iframe-light-opacity', content['colour_content']['iframe-light-opacity']);
    document.documentElement.style.setProperty('--iframe-light-opacity-100', content['colour_content']['iframe-light-opacity-100']);
    document.documentElement.style.setProperty('--iframe-dark-opacity', content['colour_content']['iframe-dark-opacity']);
    document.documentElement.style.setProperty('--iframe-dark-opacity-200', content['colour_content']['iframe-dark-opacity-200']);
    document.documentElement.style.setProperty('--iframe-dark', content['colour_content']['iframe-dark']);
    document.documentElement.style.setProperty('--iframe-primary', content['colour_content']['iframe-primary']);
    document.documentElement.style.setProperty('--iframe-accent', content['colour_content']['iframe-accent']);
    document.documentElement.style.setProperty('--iframe-accent-opacity', content['colour_content']['iframe-accent-opacity']);
    document.documentElement.style.setProperty('--iframe-timer', content['colour_content']['iframe-timer']);
    document.documentElement.style.setProperty('--iframe-user-avatar', content['colour_content']['iframe-user-avatar']);
    document.documentElement.style.setProperty('--iframe-wrong', content['colour_content']['iframe-wrong']);
    document.documentElement.style.setProperty('--iframe-corect', content['colour_content']['iframe-corect']);
    document.documentElement.style.setProperty('--iframe-shadow', content['colour_content']['iframe-shadow']);
    document.documentElement.style.setProperty('--iframe-dark-border-opacity', content['colour_content']['iframe-dark-border-opacity']);
    document.documentElement.style.setProperty('--iframe-dark-border-opacity-200', content['colour_content']['iframe-dark-border-opacity-200']);
    document.documentElement.style.setProperty('--iframe-dark-border-opacity-100', content['colour_content']['iframe-dark-border-opacity-100']);
  }

  setUpImage(content) {
    document.documentElement.style.setProperty('--mobile-background', content['image_content']['iframe-background-mobile']);
    document.documentElement.style.setProperty('--desktop-background', content['image_content']['iframe-background-desktop']);
  }
}

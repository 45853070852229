import {
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {
  FormGroup,
  NonNullableFormBuilder,
  Validators
} from "@angular/forms";
import {APP_DATA} from "../../../general.app.config";
import {takeUntil} from "rxjs/operators";
import {FormsService} from "../../../core/services/forms.service";
import {EmailFormType} from "../../../shared/FormsInterface/forgot-password-form-interface";
import {CMSContentConfigModel} from "../../../shared/models/CMSContentConfigModel";

@Component({
  selector: 'hun-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isWeb: Observable<boolean>;

  @Input() isSendingResetRequest = false;

  @Input() errorMassage: string;

  @Input() formError$:Subject<{ massage, field }>;

  @Output() email = new EventEmitter<string>();

  @Output() onChangeTitle = new EventEmitter<void>();

  @Input() cmsContent$: Observable<CMSContentConfigModel>;

  emailForm: FormGroup<EmailFormType>;

  appData = APP_DATA;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private fb: NonNullableFormBuilder,
    private formsService: FormsService,
  ) {
    this.buildForm();

  }

  ngOnInit(): void {
    this.formError$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorObject) => {
        if (errorObject) {
          const {massage, field} = errorObject;
          this.formsService.bindErrors(massage, this.emailForm, field);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private buildForm(): void {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onResetPassword() {
    this.email.emit(this.emailForm.value.email);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isSendingResetRequest) this.onChangeTitle.emit();
  }
}


<ng-container *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig">
  <div class="summary-screen-container" [ngClass]="{'desktop-style': isWeb | async}" *ngIf="!isLoading">
    <mat-toolbar *ngIf="!(isWeb | async)">
      <hun-back-button
        [buttonTextColor]="cmsContentConfig['colour_content']['primary-300']"
        [buttonTextShadow]="''"
        [customClickAction]="false"
        [opacityBackground]="true">
      </hun-back-button>
    </mat-toolbar>
    <h1>{{cmsContentConfig['text_content']['streak-summary-header-text']}}</h1>

    <div class="squiggly-background">
      <div class="squiggly-line">
        <div class="arrow-up" *ngFor="let _ of [].constructor(count + 2)"></div>
      </div>
      <div class="background-fill">
        <div class="answers-summary-container">
          <div
            *ngFor="let question of questions; let i = index"
            class="single-question-answer">
            <div class="content">
              <div class="number">
                <div class="number-circle">
                  <span>{{i + 1}}</span>
                </div>
              </div>
              <div class="text">
                <div class="question">{{question.question.text}}</div>
                <div class="answer"
                     [ngClass]="{
                   'correct': question.status === VideoStreakAnswerStatus.CORRECT,
                   'incorrect': question.status === VideoStreakAnswerStatus.INCORRECT,
                   'no-answer': question.status === VideoStreakAnswerStatus.TIME_IS_UP || (gameType === 'text' && question.status === VideoStreakAnswerStatus.PENDING)}">
                  {{question.userAnswer}}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!questions.length" class="empty-history">{{cmsContentConfig['text_content']['streak-summary-no-history-text']}}</div>

        </div>

      </div>
    </div>
  </div>
</ng-container>


<div *ngIf="isLoading" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>


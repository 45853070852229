import {Component, Input, OnInit} from '@angular/core';
import {SingleLeaderModel} from "../../../../../shared/models/LeaderboardsDetail";
import {CmsContentService} from "../../../../../core/services/cms-content.service";
import {Observable} from "rxjs";
import {CMSContentConfigModel} from "../../../../../shared/models/CMSContentConfigModel";

@Component({
  selector: 'hun-monthly',
  templateUrl: './monthly.component.html',
  styleUrls: ['./monthly.component.scss']
})
export class MonthlyComponent implements OnInit {

  @Input() leadersList: SingleLeaderModel[];

  @Input() endDate: string;

  @Input() isLoading: boolean;

  @Input() cmsContent$: Observable<CMSContentConfigModel>;

  constructor(
    public cmsContentService: CmsContentService
  ) { }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {StreakDetailModel, StreakHistoryQuestion} from "../../shared/models/Streak";
import {StreakGameDetailModel} from "../../shared/models/StreakGame";
import {HttpClient, HttpParams} from "@angular/common/http";
import {UriConfig} from "../../app.config";

@Injectable({
  providedIn: 'root'
})
export class StreakService {

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
  ) { }

  getVideoStreakByDayId(dayId): Observable<StreakDetailModel> {
    return this.http.get<StreakDetailModel>(this.uriConfig.streakDay + '/' + dayId + '/video-question');
  }

  getTextStreakByDayId(dayId): Observable<StreakDetailModel> {
    return this.http.get<StreakDetailModel>(this.uriConfig.streakDay + '/' + dayId + '/text-questions');
  }

  getStreakGameById(id): Observable<StreakGameDetailModel> {
    return this.http.get<StreakGameDetailModel>(
      this.uriConfig.rounds + '/' + id + '/streak');
  }

  getStreakRounds(pageNumber) {
    const requestBody = {pageNumber, pageSize: 20};

    let params = new HttpParams();
    Object.keys(requestBody).forEach(param => {
      params = params.set(param, requestBody[param]);
    });
    return this.http.get<any>(
      this.uriConfig.rounds + '/streak', { params });
  }

  startVideoStreak(streakDayId, videoQuestionId): Observable<any> {
    return this.http.post(this.uriConfig.streak + '/' + streakDayId + '/start-video/' + videoQuestionId, {})
  }

  getVideoStreakStatus(dayId): Observable<StreakDetailModel> {
    return this.http.get<StreakDetailModel>(this.uriConfig.streakDay + '/' + dayId + '/processing-check');
  }

  getStreakHistory(streakGameId): Observable<StreakHistoryQuestion[]> {
    return this.http.get<StreakHistoryQuestion[]>(this.uriConfig.streak + '/video/history/' + streakGameId);
  }

  getStreakTextHistory(streakGameId): Observable<StreakHistoryQuestion[]> {
    return this.http.get<StreakHistoryQuestion[]>(this.uriConfig.streak + '/history/' + streakGameId);
  }
}

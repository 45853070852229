<h2>Top Brands</h2>
<ng-container *ngIf="isWeb|async ; else mobile">
  <div class="carusel" *ngIf="isLoaded">
    <mat-icon *ngIf="(isWeb|async)&& brandList?.length > 3" (click)="scrollToLeft()">keyboard_arrow_left</mat-icon>
    <div
      class="carusel-container"
      [ngClass]="{'scrollable': brandList?.length > 3 || !(isWeb|async)}"
      #carusel
      infinite-scroll
      [horizontal]="true"
      [infiniteScrollDistance]="1"
      [fromRoot]="true"
      [scrollWindow]="false"
      (scrolled)="onScroll()"
    >
      <div *ngFor="let item of brandList" class="single-item-container">
        <hun-card
          [item]="item"
          [titleField]="titleFieldName"
          [subTitleField]="subTitleFieldName"
          [showButton]="false"
          [topTagsPosition]="false"
          [isSquareImage]="true"
          [imageName]="imageFieldName"
          (selectedCard)="handleSelectCard($event)"
        ></hun-card>
      </div>
    </div>
    <mat-icon *ngIf="(isWeb|async)&& brandList?.length > 3" (click)="scrollToRight()">keyboard_arrow_right</mat-icon>
  </div>
</ng-container>
<ng-template #mobile>
  <div class="cdk-virtual-scroll-data-source-example">
    <cdk-virtual-scroll-viewport
      orientation="horizontal"
      #carousel
      scrol
      [itemSize]="270"
      (scrolledIndexChange)="activeElementIndex = $event"
      class="example-viewport">
      <div
        *cdkVirtualFor="let item of brandList"
        class="example-item" [ngClass]="{'last': isAllBrandsLoaded}">
        <hun-card
          [item]="item"
          [titleField]="titleFieldName"
          [subTitleField]="subTitleFieldName"
          [showButton]="false"
          [topTagsPosition]="false"
          [isSquareImage]="true"
          [imageName]="imageFieldName"
          (selectedCard)="handleSelectCard($event)"
        ></hun-card>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
  <hun-carousel-indicator
    [current]="activeElementIndex"
    [length]="isAllBrandsLoaded ? brandList.length - 1 : brandList.length">
  </hun-carousel-indicator>
</ng-template>







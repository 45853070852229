import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";
import {HomeScreenModule} from "./home-screen/home-screen.module";
import {CommonControlComponentsModule} from "./shared/modules/common-control-components/common-control-components.module";
import {MatIconModule} from "@angular/material/icon";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {CommonModule} from "@angular/common";
import {CoreModule} from "./core/core.module";
import {UriConfig} from "./app.config";
import {authInterceptorProviders} from "./core/interseptors/auth.interceptor";
import {SnackBarService} from "./core/services/snack-bar.service";
import {AuthGuard} from "./core/guards/auth.guard";
import {JwtTokenInterceptor} from "./core/interseptors/jwt-token.interceptor";
import { PrivacyPolicyComponent } from './shared/policy/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './shared/policy/terms-of-use/terms-of-use.component';
import { NftExplainedComponent } from './shared/nft-explained/nft-explained.component';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MaterialModule} from "./material/material.module";
import { UpsellComponent } from './shared/upsell/upsell.component';
import { SummaryScreenStrComponent } from './shared/summary-screen/summary-screen-str.component';
import { TermsOfConditionComponent } from './shared/policy/terms-of-condition/terms-of-condition.component';
import { initializeAppFactory } from './initializeAppFactory';
import { AppInitializeService } from './core/services/app-initialize.service';

@NgModule({
  declarations: [
    AppComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    NftExplainedComponent,
    UpsellComponent,
    SummaryScreenStrComponent,
    TermsOfConditionComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    HomeScreenModule,
    CommonControlComponentsModule,
    MatIconModule,
    HttpClientModule,
    MatToolbarModule,
    MaterialModule,
  ],
  providers: [
    UriConfig,
    AuthGuard,
    SnackBarService,
    authInterceptorProviders,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtTokenInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppInitializeService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}

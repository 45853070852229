<ng-container  *ngIf="cmsContent$ | async as cmsContentConfig">
  <h2>{{cmsContentConfig['text_content']['games-prediction-section']}}</h2>
  <div class="filter-list">
    <mat-chip-list selectable multiple>
      <mat-chip
        *ngFor="let filter of filters"
        [selected]="filter.state"
        (click)="selectedChange(filter)"
      >
        {{filter.label}}
      </mat-chip>
    </mat-chip-list>
  </div>
  <div *ngIf="isLoaded && !gameList.length" class="no-game-massage">
    {{cmsContentConfig['text_content']['games-prediction-empty-text']}}
  </div>
  <mat-grid-list
    [ngClass]="{'desktop-style': isWeb | async}"
    [cols]="(isSmall | async) ? 1 : (isMedium | async) ? 2 : 3"
    [rowHeight]="gameList.length ? '435px' : 0"
    infinite-scroll
    [gutterSize]="20"
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="10"
    (scrolled)="onScroll()">
    <mat-grid-tile
      class="single-item-container"
      *ngFor="let item of gameList, let i = index"
      [ngClass]="{'last-item': (i + 1) % 3 === 0, 'fist-items': i === 0 || i % 3 === 0}"
      [colspan]="1"
      [rowspan]="1">
      <hun-card
        [ngClass]="{'mobile-style': !(isWeb | async)}"
        [item]="item"
        [showButton]="true"
        [showTimer]="true"
        [topTagsPosition]="true"
        [titleField]="titleFieldName"
        [timerLabel]="true"
        [buttonText]="cmsContentConfig['text_content']['games-play-button-streak-card']"
        [chipListName]="getChipListArray(item)"
        [cmsImageName]="'default-predition-prize-image'"
        [imageName]="'prizeImageUrl'"
        [cmsContent$]="cmsContent$"
        (selectedCard)="handleSelectCard($event)"
      ></hun-card>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="space" *ngIf="!isBrandPage" [ngClass]="{
'emptyList': !gameList.length,
'desktop-style': isWeb | async
}"></div>
</ng-container>
<div *ngIf="isFiltering" class="disabled-overlay-game">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>




import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreakpointObserverService} from "../core/breakpoint-observer/breakpoint-observer.service";
import {combineLatest, Observable, of, Subject} from "rxjs";
import {AuthService} from "../core/services/auth.service";
import {filter, switchMap, takeUntil} from "rxjs/operators";
import {CurrentLocationService} from "../core/services/current-location.service";
import {HOME_SCREEN_ROUTES} from "./home-screen.routing.config";
import {GameService} from "../core/services/game.service";
import {UserService} from "../core/services/user.service";
import {LeaderbordPeriodEnum} from "../shared/enums/leaderbordPeriodEnum";
import {ProcessTextService} from "../core/services/process-text.service";
import {NftScreenEnum} from "../shared/enums/nftScreenEnum";
import {CmsContentService} from "../core/services/cms-content.service";
import {PagesWithFilterEnum} from "../shared/enums/pagesWithFilterEnum";

@Component({
  selector: 'hun-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss']
})
export class HomeScreenComponent implements OnInit, OnDestroy {

  isWeb: Observable<boolean>;

  isLoggedIn = false;

  isHeaderWithBackground = true;

  isShowBar = true;

  isShowBackButton: boolean;

  isShowDarkHeader = false;

  stickyFooter: boolean;

  pageTitle: string;

  pageFilterOption: { state: boolean, label: string }[];

  userPts: number;

  userShortName: string;

  currentLocation: string;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private breakpointObserverService: BreakpointObserverService,
    private authService: AuthService,
    private currentLocationService: CurrentLocationService,
    private gameService: GameService,
    private userService: UserService,
    private processTextService: ProcessTextService,
    public cmsContentService: CmsContentService,
  ) {
  }

  ngOnInit(): void {
    this.isWeb = this.breakpointObserverService.isWeb;
    this.authService.getIsCurrentUser()
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((response) => {
          this.isLoggedIn = response;
          if (!response) return of(null);
          return this.userService.getUserDetails();
        }))
      .subscribe(user => {
        if (user) {
          this.userPts = user.ptsAmount;
          this.userShortName = this.processTextService.cutTextToCharacterV2(user.firstName, 7);
        } else {
          this.userPts = undefined;
          this.userShortName = null;
        }
      });

    combineLatest([
      this.currentLocationService.getCurrentPage(),
      this.gameService.getListEmptyStatus()
    ])
      .subscribe(([location, listEmptyStatus]) => {
        this.currentLocation = location;
        this.isShowBackButton = location !== 'auth' && location === '';
        this.isShowBar = location !== HOME_SCREEN_ROUTES.CHANGE_PASSWORD;
        this.isShowDarkHeader = location !== '' && location !== HOME_SCREEN_ROUTES.AUTH;
        this.isHeaderWithBackground = !!location;
        this.stickyFooter = !location.length ? listEmptyStatus : true;
        this.processHeaderData(location);
      })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  processHeaderData(location) {
    switch (location) {
      case HOME_SCREEN_ROUTES.LEADERBOARD:
        this.pageTitle = 'Leaderboard';
        this.pageFilterOption = this.processTextService.createFilter(PagesWithFilterEnum.leaderboard);
        break;
      case HOME_SCREEN_ROUTES.YOUR_NFTS:
        this.pageTitle = 'Locker';
        this.pageFilterOption = this.processTextService.createFilter(PagesWithFilterEnum.locker);
        break;
      case HOME_SCREEN_ROUTES.PROFILE:
        this.pageTitle = '';
        this.pageFilterOption = [];
        break;
      default:
        this.pageTitle = '';
        this.pageFilterOption = [];
        break;
    }
  }

  onSelectedFilter(selectedFilter) {
    this.pageTitle = selectedFilter === 'Heroes' ? 'Hall of Fame' : 'Leaderboard';
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {fromEvent, Observable, Subject, Subscription} from "rxjs";
import {Router} from "@angular/router";
import {APP_ROUTES} from "../../../app.routes.config";
import {BrandsService} from "../../../core/services/brands.service";
import {ProcessTextService} from "../../../core/services/process-text.service";
import {BrandDetailsModel} from "../../../shared/models/BrandDetail";
import {debounceTime, first, startWith, switchMap, take, takeUntil} from "rxjs/operators";
import {cloneDeep} from "lodash";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {CmsContentService} from "../../../core/services/cms-content.service";

@Component({
  selector: 'hun-top-brands',
  templateUrl: './top-brands.component.html',
  styleUrls: ['./top-brands.component.scss']
})
export class TopBrandsComponent implements OnInit, OnDestroy {

  @ViewChild('carusel', {read: ElementRef}) public carusel: ElementRef<any>;

  @ViewChild('carousel', {read: CdkVirtualScrollViewport}) public _carousel: CdkVirtualScrollViewport;

  @Input() isWeb: Observable<boolean>;

  @Output() loaded = new EventEmitter<boolean>();

  brandList = [];

  titleFieldName = 'name';

  subTitleFieldName = 'numberOfFollowers';

  imageFieldName = 'brandImage'

  pageNumber = 0;

  isAllBrandsLoaded = false;

  isLoaded = false;

  private unsubscribe$: Subject<void> = new Subject();

  private magnet$: Subscription;

  public activeElementIndex = 0;

  public scrollEnded = true;

  isCarouselSet = false;

  isShowCarousel: boolean;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.setMobileCarousel();
  }

  constructor(
    private router: Router,
    private brandsService: BrandsService,
    private processTextService: ProcessTextService,
    public cmsContentService: CmsContentService
  ) {
  }

  ngOnInit(): void {
    this.fetchBrands();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.magnet$) {
      this.magnet$.unsubscribe();
    }
  }

  fetchBrands() {
    this.pageNumber += 1;
    this.brandsService.getBrandsList(this.pageNumber)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((brands: { records: BrandDetailsModel[], total: number }) => {
        this.isLoaded = true;
        this.loaded.emit(true);
        this.isAllBrandsLoaded = !brands.records.length;
        this.prepareBrandList(brands.records);
        if (this.brandList.length >= brands.total) this.isAllBrandsLoaded = true;
        if (!this.isCarouselSet) this.setMobileCarousel();

      })
  }

  prepareBrandList(brands) {
    const newBrands = brands.map(brand => {
      const newBrand = cloneDeep(brand)
      newBrand.numberOfFollowers = this.processTextService.createShortNumberFollowers(brand.numberOfFollowers)
      if (!newBrand.brandImage) {
        newBrand.brandImage = '../../../../../../assets/image/White-logo.png';
      }
      return newBrand;
    })
    this.brandList = [...this.brandList, ...newBrands];
  }

  scrollToLeft() {
    this.carusel.nativeElement.scrollLeft -= 232;
  }

  scrollToRight() {
    this.carusel.nativeElement.scrollLeft += 232;
  }

  handleSelectCard(card) {
    this.router.navigate([
      `${APP_ROUTES.BRAND}/${card.id}`,
    ]);
  }

  onScroll () {
    if (this.isAllBrandsLoaded) return;
    this.fetchBrands();
  }

  setMobileCarousel() {
    this.isWeb
      .pipe(first())
      .subscribe(val => {
        setTimeout(() => {
          if (!val && this._carousel) {
            fromEvent(this._carousel.elementRef.nativeElement, 'touchstart').subscribe(() => (this.scrollEnded = false));
            this.isCarouselSet = true;
            const scrolled$ = fromEvent(this._carousel.elementRef.nativeElement, 'scroll');
            this.magnet$ = fromEvent(this._carousel.elementRef.nativeElement, 'touchend')
              .pipe(
                switchMap(() => scrolled$.pipe(startWith(null as Event), debounceTime(40), take(1))))
              .subscribe(() => {
                this.scrollEnded = true;
              });
            this._carousel.scrolledIndexChange.subscribe(val => {
              if (val === this.brandList.length - 4) {
                this.onScroll();
              }
            })
          }
        }, 100)
      })
  }
}

import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {BreakpointObserverService} from "../../../core/breakpoint-observer/breakpoint-observer.service";
import {GameService} from "../../../core/services/game.service";
import {ActivatedRoute, Router} from "@angular/router";
import {GameDetailModel, WinnerModel} from "../../../shared/models/Game";
import {GameStatusButton} from "../../../shared/enums/game-status-button";
import {GameStatus} from "../../../shared/enums/game-status";
import {APP_ROUTES} from "../../../app.routes.config";
import {takeUntil} from "rxjs/operators";
import {SnackBarService} from "../../../core/services/snack-bar.service";
import {Location} from "@angular/common";
import {DialogService} from "../../../shared/modules/common-control-components/components/dialog/dialog.service";
import {AuthService} from "../../../core/services/auth.service";
import {CurrentLocationService} from "../../../core/services/current-location.service";
import {DateService} from "../../../core/services/date.service";
import {APP_DATA} from "../../../general.app.config";
import {LocalStorageService} from "../../../core/services/local-storage.service";
import {environment} from "../../../../environments/environment";
import {CmsContentService} from "../../../core/services/cms-content.service";
import {MetaService} from "../../../core/services/meta.service";

@Component({
  selector: 'hun-game-landing',
  templateUrl: './game-landing.component.html',
  styleUrls: ['./game-landing.component.scss']
})
export class GameLandingComponent implements OnInit, OnDestroy {

  isWeb: Observable<boolean>;

  isLarge: Observable<boolean>;

  game: GameDetailModel;

  gameStatus = GameStatus;

  count: number;

  dateStartDiff: string;

  firstPlaceUser = 'test user';

  brandId: number;

  isLoading = true;

  isLoggedIn = false;

  winner: WinnerModel;

  GameStatus = GameStatus;

  playersPlaceholder = 'Players';

  appData = APP_DATA;

  isPreviewMode = false;

  dateDiff: string;

  private unsubscribe$: Subject<void> = new Subject();

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.count = Math.ceil(window.innerWidth / 40);
  }

  constructor(
    private router: Router,
    private breakpointObserverService: BreakpointObserverService,
    private gameService: GameService,
    private activatedRoute: ActivatedRoute,
    public snackBarService: SnackBarService,
    private location: Location,
    private dialogService: DialogService,
    private authService: AuthService,
    private currentLocationService: CurrentLocationService,
    private dateService: DateService,
    private localStorageService: LocalStorageService,
    public cmsContentService: CmsContentService,
    private metaService: MetaService
  ) {
  }

  ngOnInit(): void {
    this.metaService.setMetaTags(this.cmsContentService.cmsContent$.value['seo_content']['prediction-game-landing']);

    this.authService.processeCurrentUser();
    this.count = Math.ceil(window.innerWidth / 40);

    this.isWeb = this.breakpointObserverService.isWeb;
    this.isLarge = this.breakpointObserverService.isLargeScreen;

    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams && queryParams.accessToken && queryParams.uniqueKey) {
      this.processAsPreview(queryParams)
    } else {
      this.processAsB2CLogin();
    }

  }

  processAsB2CLogin() {
    const gameId = this.activatedRoute.snapshot.paramMap.get('id');

    this.authService.getIsCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(val => {
        this.isLoggedIn = val;
      });

    this.gameService.getGameById(gameId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((gameDetails: GameDetailModel) => {
          this.prepareGame(gameDetails);
        },
        () => {
          this.isLoading = false;
          this.snackBarService.showSnackBar(this.cmsContentService.cmsContent$.value['text_content']['snack-bar-game-no-found-text']);
          this.location.back();
        });
  }

  processAsPreview(queryParams) {
    this.gameService.getPreviewGame(queryParams.uniqueKey, queryParams.accessToken)
      .subscribe((gameDetails: GameDetailModel) => {
          this.isPreviewMode = true;
          this.prepareGame(gameDetails);
          window.addEventListener('beforeunload', function(event) {
            window.opener.postMessage('windowClosed', 'https://admin.hunch-dev.polytech.rocks');
          });
        },
        error => {
          this.isLoading = false;
          this.snackBarService.showSnackBar(this.cmsContentService.cmsContent$.value['text_content']['snack-bar-preview-error-text']);
          this.location.back();
        })
  }


  prepareGame(gameDetails: GameDetailModel) {
    if (gameDetails.winner) {
      this.winner = gameDetails.winner;
      this.winner.position = 'Winner:';
      this.winner.totalPoints = this.winner.points;
    }

    if (gameDetails.playersCount === 1) this.playersPlaceholder = 'Player';

    this.game = gameDetails;
    const pageName = this.currentLocationService.createNameWithSpaces(this.game.name);
    this.currentLocationService.setCurrentPageName(pageName);

    this.brandId = gameDetails.brandId;
    if (gameDetails.status === GameStatus.CREATED || gameDetails.status === GameStatus.PENDING) {
      const date = new Date(gameDetails.openDate);
      const timeDifference = date.getTime() - new Date().getTime();
    }
    if (gameDetails.status === GameStatus.PENDING) {
      this.dateService.getDateDifferenceWithFeature(this.game.openDate)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(dateDiff => {
          this.dateDiff = dateDiff ?? '';
        })
    }

    this.isLoading = false;
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isOpenGame() {
    return this.game.status === GameStatus.PENDING ||
      this.game.status === GameStatus.OPENED ||
      this.game.status === GameStatus.RESULT_PROCESSING;
  }

  navigateToBrand() {
    if (this.isPreviewMode) return;
    this.router.navigate([
      `${APP_ROUTES.BRAND}/${this.brandId}`,
    ]);
  }

  onPlayGame() {
    if (this.isPreviewMode) return;
    if (!this.isLoggedIn) {
      this.dialogService.openNeedSignIn();
      return;
    }
    this.localStorageService.removeItem(APP_DATA.LOCAL_STORAGE_HISTORICAL_SELECT + '_' + this.game.id)
    this.localStorageService.removeItem(APP_DATA.LOCAL_STORAGE_SELECTED_ANSWERS + '_' + this.game.id);
    this.localStorageService.removeItem(APP_DATA.LOCAL_STORAGE_ACTIVE_ANSWER + '_' + this.game.id);
    this.localStorageService.removeItem(APP_DATA.FROM_RESULT);
    this.localStorageService.setItem(APP_DATA.ACTIVE_GAME, this.game)
    this.localStorageService.setItem(APP_DATA.ACTIVE_GAME_URL, window.location.href)

    this.router.navigate([
      `${APP_ROUTES.GAME}/${APP_ROUTES.PLAYING_GAME}/${this.game.id}`,
    ]);
  }

  publishPreview() {
    const url = environment.apiUrl.replace('api', 'admin');
    window.opener.postMessage('roundPublish', url);
    window.close();
  }

  openShareDialog() {
    this.dialogService.openShareModal(this.game.name, window.location.href);
  }
}

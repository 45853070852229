import {Component, HostListener, OnInit} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {AnswerService} from "../../core/services/answer.service";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {StreakService} from "../../core/services/streak.service";
import {StreakHistoryQuestion} from "../models/Streak";
import {VideoStreakAnswerStatus} from "../enums/streakVideoGameInternalStatusEnum";
import {cloneDeep} from "lodash";
import {BreakpointObserverService} from "../../core/breakpoint-observer/breakpoint-observer.service";
import {CmsContentService} from "../../core/services/cms-content.service";
import {MetaService} from "../../core/services/meta.service";

@Component({
  selector: 'hun-summary-screen',
  templateUrl: './summary-screen.component.html',
  styleUrls: ['./summary-screen.component.scss']
})
export class SummaryScreenStrComponent implements OnInit {

  questions: StreakHistoryQuestion[];

  isWeb: Observable<boolean>;

  count: number;

  isLoading = true;

  gameType: string;

  VideoStreakAnswerStatus = VideoStreakAnswerStatus;

  private unsubscribe$: Subject<void> = new Subject();

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.count = Math.ceil(window.innerWidth / 40);
  }

  constructor(
    private answerService: AnswerService,
    private router: Router,
    private streakService: StreakService,
    private activatedRoute: ActivatedRoute,
    private breakpointObserverService: BreakpointObserverService,
    public cmsContentService: CmsContentService,
    private metaService: MetaService
  ) {
  }

  ngOnInit(): void {
    this.metaService.setMetaTags(this.cmsContentService.cmsContent$.value['seo_content']['streak-game-history']);

    const gameId = +this.activatedRoute.snapshot.paramMap.get('id');
    this.gameType = this.activatedRoute.snapshot.queryParams.type;

    this.isWeb = this.breakpointObserverService.isWeb;

    this.count = Math.ceil(window.innerWidth / 40);

    const request$ = this.gameType === 'video' ?
      this.streakService.getStreakHistory(gameId) :
      this.streakService.getStreakTextHistory(gameId)

    request$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((questions: StreakHistoryQuestion[]) => {
        this.questions = questions
          .filter(question => question.status)
          .map(question => {
            const newQuestion = cloneDeep(question);
            if (question.status === VideoStreakAnswerStatus.TIME_IS_UP) {
              newQuestion.userAnswer = 'No answer';
            }
            if (question.status === VideoStreakAnswerStatus.PENDING && this.gameType !== 'text') {
              newQuestion.userAnswer = 'Waiting...';
            }

            if ((question.status === VideoStreakAnswerStatus.CORRECT
                || question.status === VideoStreakAnswerStatus.INCORRECT) &&
              !newQuestion.userAnswer) {
              newQuestion.userAnswer = '-'
            }

            if (this.gameType === 'text') {
              // @ts-ignore
              newQuestion.question = {text: question.answeredQuestionText}
            }
            return newQuestion;
          });
        this.isLoading = false;
      })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}


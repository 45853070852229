<div class="nft-item-container" *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig">
  <div class="nft-date">{{nft.receivedAt | date: 'dd MMM YYYY'}}</div>
  <div class="content-container">
    <div class="image-container">
      <hun-image-with-loader
        class="nft-image"
        [imageSrc]="nft.prizeImageUrl"
        [overlayHeight]="'300px'"
        [spinnerSize]="60"
      >
      </hun-image-with-loader>
    </div>
    <div class="nft-buttons-container">
    <span
      [id]="'tooltip' + nft.id"
      id="tooltip"
      class="material-icons"
      *ngIf="nft.status === NftStatusEnum.FAILED">help_outline</span>
      <button
        (click)="onSaveToWallet()"
        [disabled]="nft.status === NftStatusEnum.TRANSFERRED || nft.status === NftStatusEnum.TRANSFERRING"
        class="regular-button"
        [ngClass]="{'failed': nft.status === NftStatusEnum.FAILED}"
        mat-flat-button
        color="primary">
        {{buttonText}}
      </button>
      <button
        (click)="onGetInfo()"
        class="regular-button  secondary-button"
        mat-flat-button
        color="primary">
        {{cmsContentConfig['text_content']['locker-prize-info-button']}}
      </button>
      <button
        [cdkCopyToClipboard]="nftUrl"
        (cdkCopyToClipboardCopied)="onCopied($event)"
        class="regular-button  secondary-button"
        mat-flat-button
        color="primary">
        <img src="../../../../../../assets/image/arrow.svg">
      </button>
    </div>
  </div>

</div>

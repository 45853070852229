export enum LeaderbordPeriodEnum {
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Heroes = 'Heroes'
}

export enum RequestLeaderboard {
  Weekly = 'THIS_WEEK',
  Monthly = 'THIS_MONTH',
  Heroes = 'MONTH'
}

<ng-container *ngIf="cmsContent$ | async as cmsContentConfig">
  <h2 *ngIf="streakList?.length">{{cmsContentConfig['text_content']['games-streak-section']}}</h2>
  <ng-container *ngIf="isWeb|async ; else mobile">
    <div class="carusel" *ngIf="isWeb|async" >
      <div
        class="carusel-container"
        [ngClass]="{'scrollable': streakList?.length > 3 || !(isWeb|async)}"
        #carusel
        infinite-scroll
        [horizontal]="true"
        [infiniteScrollDistance]="1"
        [fromRoot]="true"
        [scrollWindow]="false"
        (scrolled)="onScroll()"
      >
        <div *ngFor="let item of streakList" class="single-item-container">
          <hun-card
            [item]="item"
            [cmsContent$]="cmsContent$"
            [showTimer]="true"
            [showButton]="true"
            [buttonText]="cmsContentConfig['text_content']['games-play-button-streak-card']"
            [titleField]="titleFieldName"
            [topTagsPosition]="true"
            [isSquareImage]="false"
            [chipListName]="chips"
            [isStreak]="true"
            [timerLabel]="true"
            [imageName]="(isWeb | async) ? 'backgroundImageDesktopUrl' : 'backgroundImageMobileUrl'"
            [cmsImageName]="'default-streak-desktop-BG'"
            (selectedCard)="handleSelectCard($event)"
          ></hun-card>
        </div>
      </div>
      <!--  <mat-icon *ngIf="(isWeb|async)&& streakList?.length > 3" (click)="scrollToRight()">keyboard_arrow_right</mat-icon>-->
    </div>

  </ng-container>
  <ng-template #mobile>
    <div class="cdk-virtual-scroll-data-source-example" *ngIf="streakList?.length">
      <cdk-virtual-scroll-viewport
        orientation="horizontal"
        #carousel
        scrol
        [itemSize]="size"
        (scrolledIndexChange)="activeElementIndex = $event"
        class="example-viewport">
        <div
          *cdkVirtualFor="let item of streakList"
          class="example-item" [ngClass]="{'last': isAllStreakLoaded}">
          <hun-card
            [item]="item"
            [showTimer]="true"
            [showButton]="true"
            [buttonText]="cmsContentConfig['text_content']['games-play-button-streak-card']"
            [cmsContent$]="cmsContent$"
            [titleField]="titleFieldName"
            [topTagsPosition]="true"
            [isSquareImage]="false"
            [chipListName]="chips"
            [isStreak]="true"
            [timerLabel]="true"
            [imageName]="(isWeb | async) ? 'backgroundImageDesktopUrl' : 'backgroundImageMobileUrl'"
            [cmsImageName]="'default-streak-mobile-BG'"
            (selectedCard)="handleSelectCard($event)"
          ></hun-card>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
    <hun-carousel-indicator
      [current]="activeElementIndex"
      [length]="streakList.length">
    </hun-carousel-indicator>
  </ng-template>
</ng-container>



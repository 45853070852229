export const SETTINGS_USER_NAVIGATION_CONFIG = {
  GAMES: {
    label: 'Games',
    link: '',
    icon: 'game',
  },
  LEADERBOARD: {
    label: 'Leaderboard',
    link: 'leaderboard',
    icon: 'prise',
  },
  LOCKER: {
    label: 'Locker',
    link: 'locker',
    icon: 'nfts',
  },
  PROFILE: {
    label: 'Account',
    link: 'profile-settings',
    icon: 'account',
  },
  LOG_IN: {
    label: 'Log In',
    link: 'auth',
    icon: 'account',
  }
}

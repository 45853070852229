import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {filter} from "rxjs/operators";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CurrentLocationService {
  /**
   * Page path
   */
  currentPage$ = new BehaviorSubject<string>('');

  /**
   * Whole page name with space and upper case
   */
  pageName$ = new BehaviorSubject<string>('');

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,

  ) { }

  setCurrentPage (pageName) {
    this.currentPage$.next(pageName);
  }

  getCurrentPage () {
    return this.currentPage$;
  }

  setCurrentPageName (pageName) {
    this.pageName$.next(pageName);
  }

  getCurrentPageName () {
    return this.pageName$;
  }

  /**
   * Listening all changes of url from router and based on it create page name and page path
   */
  getCurrentLocation() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        let pagePath;
        let pageName;

        // @ts-ignore
        const segments = this.activatedRoute.firstChild.snapshot._urlSegment.segments;
        if (!segments.length) {
          pagePath = ''
          pageName = 'Games'
        } else {
          pagePath = segments[segments.length - 1].path;
          if (pagePath === 'auth') {
            pageName = 'Log In';
          } else {
            pageName  = this.createNameWithSpaces(pagePath);
          }
        }
        this.setCurrentPage(pagePath);
        this.setCurrentPageName(pageName);
      });
  }

  /**
   * create page name with space and upper cases based on page path
   * @param name page path
   */

  createNameWithSpaces(name) {
    const pageName = name.replaceAll('-', ' ');
    const pageNameArray = pageName.split(' ');
    const capitalizedNameArray = pageNameArray.map(word => word.charAt(0).toUpperCase() + word.substring(1));
    return capitalizedNameArray.join(' ');
  }
}

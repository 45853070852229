<ng-container *ngIf="cmsContent$ | async as cmsContentConfig">
  <div *ngIf="trandingGamesList?.length">
    <h2>{{cmsContentConfig['text_content']['games-trending-section']}}</h2>
    <ng-container *ngIf="isWeb|async ; else mobile">
      <div class="carusel" *ngIf="isLoaded">
        <mat-icon *ngIf="(isWeb|async)&& trandingGamesList?.length > 3" (click)="scrollToLeft()">keyboard_arrow_left</mat-icon>
        <div
          class="carusel-container"
          infinite-scroll
          [horizontal]="true"
          [infiniteScrollDistance]="1"
          [fromRoot]="true"
          [scrollWindow]="false"
          (scrolled)="onScroll()"
          #carusel
          [ngClass]="{'scrollable': (isWeb|async) ? trandingGamesList?.length > 3 : trandingGamesList?.length > 1}">
          <div *ngFor="let item of trandingGamesList" class="single-item-container">
            <hun-card
              [item]="item"
              [showButton]="false"
              [showTimer]="true"
              [topTagsPosition]="false"
              [titleField]="titleFieldName"
              [chipListName]="getChipListArray(item)"
              [subTitleField]="subTitleFieldName"
              [imageName]="'prizeImageUrl'"
              (selectedCard)="handleSelectCard($event)"
              [timerLabel]="true"
              [isTrending]="true"
            ></hun-card>
          </div>
        </div>
        <mat-icon *ngIf="(isWeb|async)&& trandingGamesList?.length > 3" (click)="scrollToRight()">keyboard_arrow_right</mat-icon>
      </div>
    </ng-container>
    <ng-template #mobile>
      <div class="cdk-virtual-scroll-data-source-example">
        <cdk-virtual-scroll-viewport
          orientation="horizontal"
          #carousel
          scrol
          [itemSize]="270"
          (scrolledIndexChange)="activeElementIndex = $event"
          class="example-viewport">
          <div
            *cdkVirtualFor="let item of trandingGamesList"
            class="example-item" [ngClass]="{'last': isAllGamesLoaded}">
            <hun-card
              [item]="item"
              [showButton]="false"
              [showTimer]="true"
              [topTagsPosition]="false"
              [titleField]="titleFieldName"
              [chipListName]="getChipListArray(item)"
              [subTitleField]="subTitleFieldName"
              [imageName]="'prizeImageUrl'"
              (selectedCard)="handleSelectCard($event)"
              [timerLabel]="true"
              [isTrending]="true"
            ></hun-card>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
      <hun-carousel-indicator
        [current]="activeElementIndex"
        [length]="isAllGamesLoaded ? trandingGamesList.length - 1 : trandingGamesList.length">
      </hun-carousel-indicator>
    </ng-template>

  </div>
</ng-container>





import { Injectable } from '@angular/core';
import { UriConfig } from 'src/app/app.config';
import {HttpClient, HttpParams} from "@angular/common/http";
import {LocalStorageService} from "./local-storage.service";
import {catchError, switchMap} from "rxjs/operators";
import {BehaviorSubject, Observable, of, throwError} from "rxjs";
import {SettingsConfigDetail} from "../../shared/models/SettingsConfigDetail";
import { SettingAvailability } from 'src/app/shared/enums/settingAvailability';
import {SnackBarService} from "./snack-bar.service";

@Injectable({
  providedIn: 'root'
})
export class SaasSetingsService {

  settingConfigArray$ = new BehaviorSubject<SettingsConfigDetail[]>([]);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private localStorageService: LocalStorageService,
    private snackBarService: SnackBarService,
  ) { }

  getSettingsHash() {
    return this.http.get(this.uriConfig.settings + '/check-modifying');
  }

  getSettingsList(): Observable<SettingsConfigDetail[]> {
    let params = new HttpParams();
    params = params.append('availability', SettingAvailability.B2C);
    params = params.append('availability', SettingAvailability.BOTH);

    return this.http.get<SettingsConfigDetail[]>(this.uriConfig.settings + '/b2c', { params });
  }


  loadSettings(): Observable<SettingsConfigDetail> {
    return this.getSettingsHash().pipe(
      switchMap((hashResponse:{hash: string}) => {
        const hash = this.localStorageService.getSettingsHash();
        const savedSettingConfig = this.localStorageService.getSettingsConfig();
        if (savedSettingConfig && hash === hashResponse.hash) {
          this.settingConfigArray$.next(savedSettingConfig);
          return of(savedSettingConfig);
        } else {
          return this.getSettingsList().pipe(
            switchMap((settings: SettingsConfigDetail[]) => {
              this.localStorageService.setSettingsHash(hashResponse.hash);
              this.localStorageService.setSettingsConfig(settings)
              this.settingConfigArray$.next(settings);
              return of(settings);
            })
          );
        }
      }),
      catchError(err => {
        this.snackBarService.showSnackBar(err.error.message);
        return throwError(err.error.message);
      })
    );
  }
}

<ng-container *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig">
  <div
    *ngIf="!isLoading"
    class="main-upsell-container"
    [ngClass]="{'desktop-style': isWeb | async}">
    <div class="upsell-header-container">
      <div class="header">
        {{cmsContentConfig['text_content']['upsell-header-text']}}
      </div>
      <div class="description">
        {{cmsContentConfig['text_content']['upsell-description-text']}}
      </div>
    </div>
    <div class="squiggly-background">
      <div class="squiggly-line">
        <div class="arrow-up" *ngFor="let _ of [].constructor(count + 2)"></div>
      </div>
      <div class="background-fill">
        <div class="upsell-container">
          <div class="nft-header">
            {{upsellNftCollectionDetails.upsellTitle}}
          </div>
          <div class="nft-description">
            {{upsellNftCollectionDetails.upsellDescription}}
          </div>
          <div class="upsell-image-container">
            <hun-image-with-loader
              class="upsell-image"
              [imageSrc]="upsellNftCollectionDetails.upsellImageUrl"
              [overlayHeight]="'203px'"
              [spinnerSize]="60"
            >
            </hun-image-with-loader>
          </div>
          <button
            class="regular-button"
            mat-flat-button
            (click)="onNavigateToOpenSea()"
            color="primary">
            {{cmsContentConfig['text_content']['upsell-open-sea-button']}}
          </button>
          <div class="one-row-buttons">
            <button
              (click)="onNavigateToLobby()"
              class="regular-button secondary-button navigate"
              mat-flat-button
              color="primary">
              {{cmsContentConfig['text_content']['upsell-back-to-lobby-button']}}
            </button>

            <button
              class="regular-button secondary-button share-button"
              mat-flat-button
              (click)="openShareDialog()"
              color="primary">
              {{cmsContentConfig['text_content']['share-button']}}
              <mat-icon class="material-icons material-icons-outlined reply-icon">reply</mat-icon>
            </button>
          </div>

        </div>

      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="isLoading" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthContainerComponent} from './auth-container.component';
import {SignUpComponent} from './components/sign-up/sign-up.component';
import {RouterModule} from "@angular/router";
import {AuthRoutingModule} from "./auth.routing.module";
import {ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "../material/material.module";
import {AuthHeaderComponent} from "../shared/modules/common-control-components/components/auth-header/auth-header.component";
import {MainAuthScreenComponent} from './components/main-auth-screen/main-auth-screen.component';
import {SignInComponent} from './components/sign-in/sign-in.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {CommonControlComponentsModule} from "../shared/modules/common-control-components/common-control-components.module";
import {SetNewPasswordComponent} from "../shared/modules/common-control-components/components/set-new-password/set-new-password.component";


@NgModule({
  declarations: [
    SignUpComponent,
    MainAuthScreenComponent,
    SignInComponent,
    ForgotPasswordComponent
  ],
  exports: [
    SignUpComponent,
    SignInComponent,
    MainAuthScreenComponent,
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AuthRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    CommonControlComponentsModule,
  ]
})
export class AuthModule {
}

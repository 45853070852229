import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {BreakpointObserverService} from "./core/breakpoint-observer/breakpoint-observer.service";
import {Observable, of, Subject} from "rxjs";
import {ViewportScroller} from "@angular/common";
import {CurrentLocationService} from "./core/services/current-location.service";
import {Title} from "@angular/platform-browser";
import {filter, switchMap, takeUntil} from "rxjs/operators";
import {AuthService} from "./core/services/auth.service";
import {UserService} from "./core/services/user.service";
import {IntercomService} from "./core/intercom/intercom.service";
import { AppInitializeService } from './core/services/app-initialize.service';
import {CmsContentService} from "./core/services/cms-content.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  pageYoffset = 0;

  @HostListener('window:scroll', ['$event']) onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }

  title = 'hunch-frontend-b2c';

  isWeb: Observable<boolean>;

  pageName: string;

  private APP_NAME = 'Hunch App';

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private titleService: Title,
    private breakpointObserverService: BreakpointObserverService,
    private scroll: ViewportScroller,
    private currentLocationService: CurrentLocationService,
    private authService: AuthService,
    private userService: UserService,
    private intercomService: IntercomService,
    public appInitializeService: AppInitializeService,
    public cmsContentService: CmsContentService
  ) {
    this.currentLocationService.getCurrentLocation()
  }

  ngOnInit(): void {
    this.isWeb = this.breakpointObserverService.isWeb;
    this.currentLocationService.getCurrentLocation();
    this.currentLocationService.getCurrentPageName()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(pageName => {
        this.pageName = pageName;
        // this.titleService.setTitle(pageName + ' - ' + this.APP_NAME);
      });


    this.authService.processeCurrentUser();
    this.authService.getIsCurrentUser()
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((response) => {
          if (!response) return of(null);

          return this.userService.getCurrentUser();
        }),
        filter(response => response))
      .subscribe(user => {
        this.userService.setUserDetails(user);
        this.intercomService.launchIntercomService(user);
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }

  isPageWithFooter(): boolean {
    return this.pageName === 'Leaderboard' ||
      this.pageName === 'Games' ||
      this.pageName === 'Locker' ||
      this.pageName === 'Profile Settings' ||
      this.pageName === 'Log In';
  }
}

<div
  *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig"
  class="nft-landing-container"
  [ngClass]="{'desktop-style': isWeb | async}">
  <mat-toolbar
    *ngIf="!(isWeb | async)">
    <hun-back-button
      [customClickAction]="false"
      [opacityBackground]="true"
    >
    </hun-back-button>
  </mat-toolbar>
  <div class="nft-landing-header">
    <h1 class="game-name">
      {{cmsContentConfig['text_content']['nft-explained-header-text']}}
    </h1>
  </div>
  <div class="nft-landing-body">
    <div class="explain-description">
      <div class="text">
        {{cmsContentConfig['text_content']['nft-explained-description-text']}}
      </div>
    </div>
    <div class="squiggly-line-light">
      <img>
    </div>
    <h2>
      {{cmsContentConfig['text_content']['nft-explained-h2-1-text']}}
    </h2>

    <div class="squiggly-background">
      <div class="squiggly-line">
        <div class="arrow-up" *ngFor="let _ of [].constructor(count + 2)"></div>
      </div>
      <div class="background-fill">
        <div class="explain-full-text">
          <h3>{{cmsContentConfig['text_content']['nft-explained-h3-1-text']}}</h3>
          <div>{{cmsContentConfig['text_content']['nft-explained-div-1-text']}}</div>
          <h3>{{cmsContentConfig['text_content']['nft-explained-h3-2-text']}}</h3>
          <div>{{cmsContentConfig['text_content']['nft-explained-div-2-text']}}</div>
          <h3>{{cmsContentConfig['text_content']['nft-explained-h3-3-text']}}</h3>
          <div>{{cmsContentConfig['text_content']['nft-explained-div-3-text']}}</div>
        </div>
      </div>
    </div>
  </div>


</div>


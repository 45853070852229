<ng-container *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig">
  <hun-header-panel
    *ngIf="((isWeb | async) || !isHeaderWithBackground) && isShowBar; else darkheader"
    [isWeb]="isWeb"
    [isLoggedIn]="isLoggedIn"
  ></hun-header-panel>
  <ng-template #darkheader>
    <hun-header-with-background
      *ngIf="isShowBar && isShowDarkHeader"
      [isShowBackButton]="isShowBackButton"
      [title]="pageTitle"
      [chipValue]="userPts"
      [currentLocation]="currentLocation"
      [filterOption]="pageFilterOption"
      [cmsContent$]="cmsContentService.cmsContent$"
      (selectedFilter)="onSelectedFilter($event)">
    </hun-header-with-background>
  </ng-template>
  <router-outlet></router-outlet>
  <hun-control-panel
    [isLoggedIn]="isLoggedIn"
    [isWeb]="isWeb"
    [userPts]="userPts"
    [userShortName]="userShortName"
    *ngIf="!(isWeb | async) && isShowBar">
  </hun-control-panel>
</ng-container>


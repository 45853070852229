import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export class PasswordValidator {
  /**
   * Validate password for each rule
   * @param regex - regexp rule to validate password
   * @param error - object where field this is name of error and boolean value
   */
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);

      return valid ? null : error;
    };
  }
}

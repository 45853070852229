import { Injectable } from '@angular/core';
import {LocalStorageService} from "./local-storage.service";
import {APP_DATA} from "../../general.app.config";
import {HeaderFilterModel} from "../../shared/models/HeaderFilterModel";
import { NftScreenEnum } from 'src/app/shared/enums/nftScreenEnum';
import {PagesWithFilterEnum} from "../../shared/enums/pagesWithFilterEnum";
import {CmsContentService} from "./cms-content.service";

@Injectable({
  providedIn: 'root'
})
export class ProcessTextService {

  constructor(
    private localStorageService: LocalStorageService,
    private cmsContentService: CmsContentService
) { }

  createShortNumberFollowers(number: number) {
    const followers = number === 1 ? 'FOLLOWER' : 'FOLLOWERS';
    let counter;
    if (number < 1000) {
      counter = number + ' ' + followers;
    } else {
      const integer = number / 1000;
      if (integer > 0) {
        counter = integer + ' K ' + followers;
      } else {
        counter = number + ' ' + followers;
      }
    }
    return counter;
  }

  createLongNumberFollowers (number: number) {
    const followers = number === 0 || number > 1 ?  ' Followers' : ' Follower'
    return number.toLocaleString() + followers;
  }

  capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  createFilter(page:PagesWithFilterEnum) {
    const content = this.cmsContentService.cmsContent$.value;
    const latestSelectionFilter = this.localStorageService.getItem(APP_DATA.LATEST_SELECTION_FILTER);
    let menuConfig: HeaderFilterModel[];
    switch (page){
      case PagesWithFilterEnum.locker:
        menuConfig = [
          {label: content['text_content']['locker-prizes-label'], state: false, name: NftScreenEnum.NFT},
          {label: content['text_content']['locker-games-label'], state: false, name: NftScreenEnum.GAME}
        ]
        break;
      case PagesWithFilterEnum.leaderboard:
        menuConfig = [
          {label: content['text_content']['leaderboard-weekly-label'], state: false, name: 'Weekly'},
          {label: content['text_content']['leaderboard-monthly-label'], state: false, name: 'Monthly'},
          {label: content['text_content']['leaderboard-heroes-label'], state: false, name: 'Heroes'}
        ]
    }
    if (latestSelectionFilter) {
      menuConfig.find(item => item.name === latestSelectionFilter).state = true;
    } else {
      menuConfig[0].state = true;
    }
    return menuConfig;
    // // const latestSelectionFilter = this.localStorageService.getItem(APP_DATA.LATEST_SELECTION_FILTER);
    // return filterOption.map((item, index) => {
    //   if (latestSelectionFilter) {
    //     return {state: item === latestSelectionFilter, label: item};
    //   }
    //   return {state: !(!!index), label: item};
    // });
  }

  cutTextToCharacter (text: string, character: number) {
    if (text.length < character) return text;
    return text.slice(0, character).trim() + '...';
  }

  cutTextToCharacterV2 (text: string, character: number) {
    if (text.length <= character) return text;
    return text.slice(0, character).trim() + '...';
  }

  createShortNumber(number: number) {
    let counter: string | number = number;
    if (number >= 1000) {
      const integer = number / 1000;
      counter = integer + 'K';
    }
    return counter;
  }
}

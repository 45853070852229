<div class="close" id="stayButton" mat-dialog-close>
  <mat-icon>close</mat-icon>
</div>
<div mat-dialog-content>


  <hun-image-with-loader
    *ngIf="imageUrl"
    [imageSrc]="imageUrl"
    [spinnerSize]="60"
  >
  </hun-image-with-loader>
</div>

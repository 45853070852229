import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SnackBarService} from "../../../core/services/snack-bar.service";
import {Observable, Subject} from "rxjs";
import {BreakpointObserverService} from "../../../core/breakpoint-observer/breakpoint-observer.service";
import {environment} from "../../../../environments/environment";
import {SaasSetingsService} from "../../../core/services/saas-setings.service";
import {map, takeUntil} from 'rxjs/operators';
import {CMSContentConfigModel} from "../../../shared/models/CMSContentConfigModel";
import {CmsContentService} from "../../../core/services/cms-content.service";

declare const google: any;
declare const FB: any;

@Component({
  selector: 'hun-main-auth-screen',
  templateUrl: './main-auth-screen.component.html',
  styleUrls: ['./main-auth-screen.component.scss']
})
export class MainAuthScreenComponent implements OnInit {

  @Input() isTokenValid: boolean;

  @Input() cmsContent$: Observable<CMSContentConfigModel>;

  @Output() loginWithEmail = new EventEmitter<boolean>();

  @Output() googleUser = new EventEmitter<any>();

  @Output() facebookUser = new EventEmitter<any>();

  isWeb: Observable<boolean>;

  tokenClient: any;

  googleId = environment.googleId;

  facebookId = environment.facebookId;

  isSocialLoginPermission$ = this.saasSetingsService.settingConfigArray$
    .pipe(
      map(config => config.find(configItem => configItem.key === 'social_login_options')?.value === 'true')
    )

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private snackBarService: SnackBarService,
    private breakpointObserverService: BreakpointObserverService,
    private saasSetingsService: SaasSetingsService,
    private cmsContentService: CmsContentService
  ) {
  }

  ngOnInit(): void {
    //TODO delete when task 799 will be closed
    this.saasSetingsService.settingConfigArray$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(config => console.log(config));

    if (!this.isTokenValid) this.snackBarService.showSnackBar(this.cmsContentService.cmsContent$.value['text_content']['snack-bar-invalid-token-text']);
    this.isWeb = this.breakpointObserverService.isWeb;
    this.initGoogleTokenClient();
  }

  handleLoginWithGoogle() {
    this.tokenClient.requestAccessToken();
  }

  initGoogleTokenClient() {
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: this.googleId,
      scope: 'email profile openid https://www.googleapis.com/auth/devstorage.full_control https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
      prompt: 'select_account',
      callback: this.processGoogleUserLogin.bind(this),
    });

  }

  initFacebookTokenClient() {
    FB.init({
      appId: this.facebookId,
      autoLogAppEvents: true,
      xfbml: true,
      cookie: false,
      status: false,
      version: 'v15.0'

    });
  }

  handleLoginWithFacebook() {
    this.initFacebookTokenClient();
    FB.login((response) =>  {
      if (!response || !response.authResponse) return;
      this.facebookUser.emit(response.authResponse);
    }, {scope: 'public_profile,email'});
  }

  processGoogleUserLogin(tokenResponse) {
    this.googleUser.emit(tokenResponse);
  }

  handleLoginWithEmail () {
    this.loginWithEmail.emit(true);
  }

}

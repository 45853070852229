<div class="close" id="stayButton" mat-dialog-close>
  <mat-icon>close</mat-icon>
</div>
<div class="mat-dialog-content">
  <div class="content">
    <div
      class="header"
      [ngClass]="{'small-name': smallText && !(isWeb | async)}">
      {{name}}
    </div>
    <div class="url">
      <input matInput readonly [value]="url"/>
    </div>

    <share-buttons theme="circles-dark"
                   [include]="['copy', 'facebook', 'twitter']"
                   [showIcon]="true"
                   [showText]="false"
                   [url]="url"
                   [title]="name"
                   class="pt-5">
    </share-buttons>
  </div>
</div>


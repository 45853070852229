<ng-container *ngIf="cmsContent$ | async as cmsContentConfig">
  <div class="monthly-container" *ngIf="leadersList.length">
    <div [style.text-align]="'center'">Winners decided at {{endDate | formatDate}}</div>
    <hun-countdown-timer
      *ngIf="endDate"
      [addDotsBetween]="true"
      [showDescriptionBelow]="true"
      [showSeconds]="true"
      [addZero]="true"
      [endDate]="endDate"
      class="tag-chip">
    </hun-countdown-timer>
    <ng-container *ngFor="let leader of leadersList; let i = index">
      <hun-short-leader-point
        [cmsContent$]="cmsContent$"
        class="single"
        [leader]="leader">
      </hun-short-leader-point>
    </ng-container>
  </div>
  <div *ngIf="!leadersList.length && !isLoading" class="empty-text">
    {{cmsContentConfig['text_content']['leaderboard-empty-text']}}
  </div>
</ng-container>


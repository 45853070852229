import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './components/footer/footer.component';
import {MaterialModule} from "../../../material/material.module";
import {ControlPanelComponent} from "./components/control-panel/control-panel.component";
import {HeaderPanelComponent} from "./components/header-pannel/header-panel.component";
import {RouterModule} from "@angular/router";
import { TagChipComponent } from './components/tag-chip/tag-chip.component';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';
import { CardComponent } from './components/card-component/card.component';
import { ButtonComponent } from './components/button/button.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { BackButtonComponent } from './components/back-button/back-button.component';
import { SetNewPasswordComponent } from './components/set-new-password/set-new-password.component';
import {ReactiveFormsModule} from "@angular/forms";
import {AuthHeaderComponent} from "./components/auth-header/auth-header.component";
import { HeaderWithBackgroundComponent } from './components/header-with-background/header-with-background.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { HeaderFilterComponent } from './components/leaderboard-filter/header-filter.component';
import { ImageWithLoaderComponent } from './components/image-with-loader/image-with-loader.component';
import { DialogWithImageComponent } from './components/dialog/dialog-with-image/dialog-with-image.component';
import { CarouselIndicatorComponent } from './components/carousel-indicator/carousel-indicator.component';
import {ShareModalComponent} from './components/share-modal/share-modal.component';
import {ShareButtonsModule} from "ngx-sharebuttons/buttons";
import {ShareIconsModule} from "ngx-sharebuttons/icons";

@NgModule({
  declarations: [
    FooterComponent,
    ControlPanelComponent,
    HeaderPanelComponent,
    TagChipComponent,
    CountdownTimerComponent,
    CardComponent,
    ButtonComponent,
    BackButtonComponent,
    SetNewPasswordComponent,
    AuthHeaderComponent,
    HeaderWithBackgroundComponent,
    DialogComponent,
    HeaderFilterComponent,
    ImageWithLoaderComponent,
    DialogWithImageComponent,
    CarouselIndicatorComponent,
    ShareModalComponent,
  ],
  exports: [
    FooterComponent,
    ControlPanelComponent,
    HeaderPanelComponent,
    TagChipComponent,
    CountdownTimerComponent,
    CardComponent,
    ButtonComponent,
    BackButtonComponent,
    SetNewPasswordComponent,
    AuthHeaderComponent,
    HeaderWithBackgroundComponent,
    HeaderFilterComponent,
    ImageWithLoaderComponent,
    CarouselIndicatorComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    ShareButtonsModule,
    ShareIconsModule,
  ]
})
export class CommonControlComponentsModule {
}

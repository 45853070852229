<h1 mat-dialog-title>{{data.dialogTitle}}</h1>
<div mat-dialog-content>
  <p>{{data.dialogContent}}</p>
</div>
<div class="buttons">
  <button
    id="stayButton"
    *ngIf="data.showDeclineButton"
    class="regular-button secondary-button"
    mat-flat-button
    mat-dialog-close>{{data.labelOk}}</button>
  <button
    class="regular-button"
    mat-flat-button
    color="primary"
    (click)="closeDialog()" >{{data.labelNo}}</button>
</div>

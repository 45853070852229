<ng-container *ngIf="cmsContent$ | async as cmsContentConfig">
  <div class="auth-form-container">
    <form
      [formGroup]="signUpForm"
      *ngIf="!isUserSignedIn; else confirmationMassage">
      <mat-label>{{cmsContentConfig['text_content']['sign-up-first-name-label']}}</mat-label>
      <mat-form-field appearance="outline" [floatLabel]="'always'" [ngClass]="{'validation-error123': getError('firstName')}">
        <input
          matInput
          required
          [formControlName]="'firstName'"/>
        <mat-error
          class="select-error"
          *ngIf="signUpForm.get('firstName').hasError('required')">
          <span class="text"> {{cmsContentConfig['text_content']['error-madnatory']}}</span>
        </mat-error>
        <mat-error *ngIf="!signUpForm?.get('firstName').hasError('required') && signUpForm?.get('firstName').hasError('maxlength')">
          <span> {{cmsContentConfig['text_content']['error-max-15-characters']}}</span>
        </mat-error>
        <mat-error *ngIf="!signUpForm?.get('firstName').hasError('required') && signUpForm?.get('firstName').hasError('minlength')">
          <span> {{appData.MIN_LENGHT_3}}</span>
        </mat-error>
        <mat-error *ngIf="!signUpForm?.get('firstName').hasError('required') && signUpForm?.get('firstName').hasError('pattern')">
          <span> {{cmsContentConfig['text_content']['error-dont-support-special-characters']}}</span>
        </mat-error>
      </mat-form-field>

      <mat-label>{{cmsContentConfig['text_content']['sign-up-last-name-label']}}</mat-label>
      <mat-form-field appearance="outline" [floatLabel]="'always'"  [ngClass]="{'validation-error123': getError('lastName')}">
        <input
          matInput
          required
          [formControlName]="'lastName'"/>
        <mat-error
          class="select-error"
          *ngIf="signUpForm.get('lastName').hasError('required')">
          <span class="text"> {{cmsContentConfig['text_content']['error-madnatory']}}</span>
        </mat-error>
        <mat-error *ngIf="!signUpForm?.get('lastName').hasError('required') && signUpForm?.get('lastName').hasError('maxlength')">
          <span> {{cmsContentConfig['text_content']['error-max-15-characters']}}</span>
        </mat-error>
        <mat-error *ngIf="!signUpForm?.get('lastName').hasError('required') && signUpForm?.get('lastName').hasError('minlength')">
          <span> {{appData.MIN_LENGHT_3}}</span>
        </mat-error>
        <mat-error *ngIf="!signUpForm?.get('lastName').hasError('required') && signUpForm?.get('lastName').hasError('pattern')">
          <span> {{cmsContentConfig['text_content']['error-dont-support-special-characters']}}</span>
        </mat-error>
      </mat-form-field>

      <mat-label>{{cmsContentConfig['text_content']['sign-up-email-label']}}</mat-label>
      <mat-form-field appearance="outline" [floatLabel]="'always'">
        <input
          matInput
          required
          [formControlName]="'email'"/>
        <mat-error
          class="select-error"
          *ngIf="signUpForm.get('email').hasError('required')">
          <span class="text"> {{cmsContentConfig['text_content']['error-madnatory']}}</span>
        </mat-error>
        <mat-error
          class="select-error"
          *ngIf="!signUpForm.get('email').hasError('required') && signUpForm.get('email').hasError('email')">
          <span class="text"> {{cmsContentConfig['text_content']['error-incorrect-email']}}</span>
        </mat-error>
      </mat-form-field>

      <mat-label>{{cmsContentConfig['text_content']['sign-up-password-label']}}</mat-label>
      <mat-form-field appearance="outline"
                      [ngClass]="{'validation-error123':
                    hasPasswordError() && signUpForm?.get('password').touched && signUpForm?.get('password').hasError('required')}">
        <input
          matInput
          type="password"
          name="password"
          [formControlName]="'password'"/>
        <mat-error *ngIf="signUpForm?.get('password').hasError('required')">
          <span> {{cmsContentConfig['text_content']['error-madnatory']}}</span>
        </mat-error>
        <mat-error *ngIf="hasPasswordError()">
          <span> {{errorMassage}}</span>
        </mat-error>
      </mat-form-field>

      <mat-label>{{cmsContentConfig['text_content']['sign-up-confirm-password-label']}}</mat-label>
      <mat-form-field appearance="outline">
        <input
          matInput
          type="password"
          name="passwordConfirmation"
          [formControlName]="'passwordConfirmation'"
        />
        <mat-error *ngIf="signUpForm?.get('passwordConfirmation').hasError('required')">
          <span> {{cmsContentConfig['text_content']['error-madnatory']}}</span>
        </mat-error>
      </mat-form-field>
    </form>
  </div>

  <button
    [disabled]="!signUpForm.valid"
    *ngIf="!isUserSignedIn"
    class="regular-button"
    mat-flat-button
    (click)="onSignUp()"
    color="primary">
    {{pageName}}
  </button>

  <ng-template #confirmationMassage>
    <div class="confirmation-text">
      {{cmsContentConfig['text_content']['sign-up-confirm-link-label']}}
    </div>
  </ng-template>
</ng-container>


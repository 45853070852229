import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {LeaderboardsService} from "../../../../../core/services/leaderboards.service";
import {HOME_SCREEN_ROUTES} from "../../../../../home-screen/home-screen.routing.config";
import {YourNftService} from "../../../../../core/services/your-nft.service";
import {HeaderFilterModel} from "../../../../models/HeaderFilterModel";

@Component({
  selector: 'hun-header-filter',
  templateUrl: './header-filter.component.html',
  styleUrls: ['./header-filter.component.scss']
})
export class HeaderFilterComponent implements OnInit, OnChanges {

  @Input() filterOption: HeaderFilterModel[];

  @Input() location: string;

  @Output() selectedFilter = new EventEmitter<any>();

  constructor(
    private leaderboardsService: LeaderboardsService,
    private yourNftService: YourNftService,
  ) { }

  ngOnInit(): void {
  }

  selectFilter(filter) {
    this.filterOption.forEach(filter => filter.state = false);
    this.filterOption.find(f => f.name === filter.name).state = !filter.state;
    if (this.location === HOME_SCREEN_ROUTES.LEADERBOARD) {
      this.leaderboardsService.setSelectedFilter(filter.name);
      this.selectedFilter.emit(filter.name);
    } else {
      this.yourNftService.setSelectedFilter(filter.name);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.location && changes.location.currentValue) {
      const selectedFilter = this.filterOption.find(filter => filter.state).name;
      if (this.location === HOME_SCREEN_ROUTES.LEADERBOARD) {
        this.leaderboardsService.setSelectedFilter(selectedFilter);
      } else {
        this.yourNftService.setSelectedFilter(selectedFilter);
      }
    }
  }
}

<div class="my-game-container" *ngIf="cmsContent$ | async as cmsContentConfig">
  <div class="game-item-image-container">
    <hun-image-with-loader
      class="game-item-image"
      [imageSrc]="game.type.toLowerCase().includes('streak') ? (isWeb | async) ? game.backgroundImageDesktopUrl : game.backgroundImageMobileUrl : game.prizeImageUrl"
      [overlayHeight]="'173px'"
      [spinnerSize]="40"
    >
    </hun-image-with-loader>
  </div>
  <div class="game-content-container">
    <mat-chip-list>
      <mat-chip>
        {{processTextService.capitalizeFirstLetter(game.type)}}
      </mat-chip>
      <mat-chip *ngIf="game.points">
        {{game.points}}{{cmsContentConfig['text_content']['games(profile)-points-label']}}
      </mat-chip>
    </mat-chip-list>
    <div class="game-name">
      {{processTextService.cutTextToCharacter(game.name, 24)}}
    </div>
    <button
      class="regular-button  secondary-button"
      mat-flat-button
      (click)="onSeeResult()"
      color="primary">
      {{cmsContentConfig['text_content']['locker-game-result-button']}}
    </button>
  </div>

</div>
<div *ngIf="isLoading" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable} from "rxjs";
import {BreakpointObserverService} from "../../../../../core/breakpoint-observer/breakpoint-observer.service";
import {CMSContentConfigModel} from "../../../../models/CMSContentConfigModel";

@Component({
  selector: 'hun-header-with-background',
  templateUrl: './header-with-background.component.html',
  styleUrls: ['./header-with-background.component.scss']
})
export class HeaderWithBackgroundComponent implements OnInit, OnChanges {

  isWeb: Observable<boolean>;

  isDataLoaded = false;

  @Input() isShowBackButton: boolean;

  @Input() title: string;

  @Input() chipValue: number;

  @Input() currentLocation: string;

  @Input() filterOption: {state: boolean, label: string}[];

  @Output() selectedFilter = new EventEmitter<any>();

  @Input() cmsContent$: Observable<CMSContentConfigModel>;

  constructor(
    private breakpointObserverService: BreakpointObserverService,
  ) {
  }

  ngOnInit(): void {
    this.isWeb = this.breakpointObserverService.isWeb;
  }

  onSelectedFilter(selectedFilter: string) {
    this.selectedFilter.emit(selectedFilter);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.chipValue && changes.chipValue.currentValue !== undefined) {
      this.isDataLoaded = true;
    }
  }

}

import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {UserService} from "../../../../../core/services/user.service";
import {takeUntil} from "rxjs/operators";
import {CmsContentService} from "../../../../../core/services/cms-content.service";

@Component({
  selector: 'hun-header-panel',
  templateUrl: './header-panel.component.html',
  styleUrls: ['./header-panel.component.scss']
})
export class HeaderPanelComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isWeb: Observable<boolean>

  @Input() isLoggedIn: boolean;

  userPts: number;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private userService: UserService,
    public cmsContentService: CmsContentService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.isLoggedIn && changes.isLoggedIn.currentValue) {
      this.userService.getCurrentUser()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(user => {
          this.userPts = user.ptsAmount;
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

<ng-container *ngIf="cmsContent$ | async as cmsContentConfig">
  <mat-toolbar *ngIf="!(isWeb | async) && !isForgotPassword">
    <hun-back-button
      [customClickAction]="false"
      [buttonTextColor]="cmsContentConfig['colour_content']['primary-300']"
      [buttonTextShadow]="''"
      [opacityBackground]="true">
    </hun-back-button>
  </mat-toolbar>
  <hun-auth-header
    *ngIf="!isForgotPassword"
    [isWeb]="isWeb"
    [pageName]="cmsContentConfig['text_content']['change-password-header-text']"
    [isShowBackButton]="true"
    [isShowLogo]="false"
  >
  </hun-auth-header>
  <form [formGroup]="changePassword">
    <mat-label *ngIf="!isForgotPassword">{{cmsContentConfig['text_content']['change-password-old-password-button']}}</mat-label>
    <mat-form-field *ngIf="!isForgotPassword" appearance="outline">
      <input
        matInput
        type="password"
        name="password"
        [formControlName]="'oldPassword'"/>
      <mat-error *ngIf="changePassword?.get('oldPassword').hasError('required')">
        <span> {{cmsContentConfig['text_content']['error-madnatory']}}</span>
      </mat-error>
      <mat-error *ngIf="changePassword?.get('oldPassword').hasError('required')">
        <span> {{cmsContentConfig['text_content']['error-madnatory']}}</span>
      </mat-error>
      <mat-error *ngIf="changePassword?.get('oldPassword').hasError('server')">
        <span> {{changePassword?.get('oldPassword').getError('server')}}</span>
      </mat-error>
    </mat-form-field>

    <mat-label>{{cmsContentConfig['text_content']['change-password-new-password-button']}}</mat-label>
    <mat-form-field appearance="outline">
      <input
        matInput
        type="password"
        name="password"
        [formControlName]="'password'"/>
      <mat-error *ngIf="changePassword?.get('password').hasError('required')">
        <span> {{cmsContentConfig['text_content']['error-madnatory']}}</span>
      </mat-error>

      <mat-error *ngIf="
    hasPasswordError() &&
    changePassword.get('password').touched &&
    !changePassword?.get('password').hasError('required')" class="error-list">
        <span> {{errorMassage}}</span>
      </mat-error>

    </mat-form-field>

    <mat-label>{{cmsContentConfig['text_content']['change-password-confirm-password-button']}}</mat-label>
    <mat-form-field appearance="outline">
      <input
        matInput
        type="password"
        name="passwordConfirmation"
        [formControlName]="'passwordConfirmation'"
      />
      <mat-error *ngIf="changePassword?.get('passwordConfirmation').hasError('required')">
        <span> {{cmsContentConfig['text_content']['error-madnatory']}}</span>
      </mat-error>

      <mat-error
        *ngIf="!changePassword?.get('passwordConfirmation').hasError('required') &&
    changePassword?.get('passwordConfirmation').hasError('equalTo')">
        <span> {{cmsContentConfig['text_content']['error-password-dont-match']}}</span>
      </mat-error>
    </mat-form-field>
    <div class="general-validation-massage">
      {{cmsContentConfig['text_content']['change-password-explain-text']}}
    </div>

    <hun-button
      [buttonTitle]="changeButtonTitle || buttonTitle"
      [disabled]="!changePassword.valid"
      [isLoading]="false"
      (clicked)="onChangePassword()"
    >
    </hun-button>
  </form>
</ng-container>



import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ProcessTextService} from "../../core/services/process-text.service";
import {LocationStrategy} from "@angular/common";
import {BrandsService} from "../../core/services/brands.service";
import {UpsellDetailsModel} from "../models/UpsellDetail";
import {Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {BreakpointObserverService} from "../../core/breakpoint-observer/breakpoint-observer.service";
import {IntercomService} from "../../core/intercom/intercom.service";
import {LocalStorageService} from "../../core/services/local-storage.service";
import {APP_DATA} from "../../general.app.config";
import {DialogService} from "../modules/common-control-components/components/dialog/dialog.service";
import {CmsContentService} from "../../core/services/cms-content.service";
import {MetaService} from "../../core/services/meta.service";

@Component({
  selector: 'hun-upsell',
  templateUrl: './upsell.component.html',
  styleUrls: ['./upsell.component.scss']
})
export class UpsellComponent implements OnInit, OnDestroy {

  isWeb: Observable<boolean>;

  count: number;

  upsellNftCollectionDetails: UpsellDetailsModel;

  isLoading = true;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.count = Math.ceil(window.innerWidth / 40);
  }

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    public processTextService: ProcessTextService,
    private location: LocationStrategy,
    private brandsService: BrandsService,
    private activatedRoute: ActivatedRoute,
    private breakpointObserverService: BreakpointObserverService,
    private intercomService: IntercomService,
    private localStorageService: LocalStorageService,
    private dialogService: DialogService,
    public cmsContentService: CmsContentService,
    private metaService: MetaService
  ) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit(): void {
    this.metaService.setMetaTags(this.cmsContentService.cmsContent$.value['seo_content']['upsell']);

    this.isWeb = this.breakpointObserverService.isWeb;

    this.brandsService.getUpsell()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(collectionDetails => {
        this.isLoading = false;
        this.upsellNftCollectionDetails = collectionDetails;
      })
    this.count = Math.ceil(window.innerWidth / 40);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onNavigateToOpenSea() {
    this.intercomService
      .sendUpsellEvent({'upsell-source-url': this.upsellNftCollectionDetails.upsellSourceUrl});
    window.open(this.upsellNftCollectionDetails.upsellSourceUrl, "_blank");
  }

  onNavigateToLobby() {
    this.router.navigate(['/']);
  }

  openShareDialog() {
    const game = this.localStorageService.getItem(APP_DATA.ACTIVE_GAME);
    const url = this.localStorageService.getItem(APP_DATA.ACTIVE_GAME_URL);
    this.dialogService.openShareModal(game.name, url);
  }

}

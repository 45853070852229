import {Component, HostListener, OnInit} from '@angular/core';
import {BreakpointObserverService} from "../../core/breakpoint-observer/breakpoint-observer.service";
import {Observable} from "rxjs";
import {CmsContentService} from "../../core/services/cms-content.service";
import {MetaService} from "../../core/services/meta.service";

@Component({
  selector: 'hun-nft-explained',
  templateUrl: './nft-explained.component.html',
  styleUrls: ['./nft-explained.component.scss']
})
export class NftExplainedComponent implements OnInit {
  isWeb: Observable<boolean>;
  count: number;
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.count = Math.ceil(window.innerWidth / 40);
  }


  constructor(
    private breakpointObserverService: BreakpointObserverService,
    public cmsContentService: CmsContentService,
    private metaService: MetaService
  ) { }

  ngOnInit(): void {
    this.metaService.setMetaTags(this.cmsContentService.cmsContent$.value['seo_content']['nft-explained']);

    this.isWeb = this.breakpointObserverService.isWeb;
    this.count = Math.ceil(window.innerWidth / 40);
  }

}

<div class="container" [ngClass]="{'desktop-style': isWeb | async}">
  <hun-streak-games
    [cmsContent$]="cmsContentService.cmsContent$"
    (loaded)="onStreakGameLoaded($event)">
  </hun-streak-games>

  <hun-tranding-games
    [cmsContent$]="cmsContentService.cmsContent$"
    (loaded)="onTrandingGameLoaded($event)"
    [isWeb]="isWeb">
  </hun-tranding-games>

  <!--  TODO: comment as we dont have api brands in stg now, need to decide delete it or will be another api-->
  <!--  <hun-top-brands-->
  <!--    (loaded)="onTopBrandsLoaded($event)"-->
  <!--    [isWeb]="isWeb"-->
  <!--  >-->
  <!--  </hun-top-brands>-->

  <hun-upcoming-games
    [cmsContent$]="cmsContentService.cmsContent$"
    [chipNameList]="upcomingGameChips"
    [isWeb]="isWeb"
    [isSmall]="isSmall"
    [isMedium]="isMedium"
    [isXSmall]="isXSmall"
  >
  </hun-upcoming-games>
</div>
<div *ngIf="!isAllSectionLoaded()" class="disabled-overlay general" [ngClass]="{'desktop-style': isWeb | async}">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>

<div class="auth-form-container" *ngIf="cmsContent$ | async as cmsContentConfig">
  <form [formGroup]="emailForm" *ngIf="!isSendingResetRequest; else passwordSend">
    <mat-label>{{cmsContentConfig['text_content']['forgot-password-email-label']}}</mat-label>
    <mat-form-field appearance="outline" [floatLabel]="'always'">
      <input
        matInput
        type="email"
        required
        [formControlName]="'email'"/>
      <mat-error
        class="select-error"
        *ngIf="emailForm.get('email').hasError('required')">
        <span class="text"> {{cmsContentConfig['text_content']['error-madnatory']}}</span>
      </mat-error>
      <mat-error
        class="select-error"
        *ngIf="!emailForm.get('email').hasError('required') && emailForm.get('email').hasError('email')">
        <span class="text"> {{cmsContentConfig['text_content']['error-incorrect-email']}}</span>
      </mat-error>
      <mat-error *ngIf="emailForm?.get('email').hasError('server')">
        <span> {{emailForm?.get('email').getError('server')}}</span>
      </mat-error>
    </mat-form-field>
    <button
      [disabled]="!emailForm.valid"
      class="regular-button"
      mat-flat-button
      (click)="onResetPassword()"
      color="primary">
      {{cmsContentConfig['text_content']['forgot-password-reset-button']}}
    </button>
  </form>

  <ng-template #passwordSend>
    <div class="confirmation-text">
      <div>
        If an account exists with this email address, we'll send a password reset link to it.
      </div>
    </div>
  </ng-template>

</div>

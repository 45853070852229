import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {UriConfig} from "../../app.config";
import {BehaviorSubject, Observable} from "rxjs";
import {UserNftDetailsModel} from "../../shared/models/NFTDetail";

@Injectable({
  providedIn: 'root'
})
export class YourNftService {

  private selectedFilter$ = new BehaviorSubject<string>(null);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }


  getUserNfts (pageNumber): Observable<{ records: UserNftDetailsModel[], total: number }> {
    const requestBody = {
      pageNumber,
      pageSize: 20,
    };
    let params = new HttpParams();
    Object.keys(requestBody).forEach(param => {
      params = params.set(param, requestBody[param]);
    });
    return this.http.get<{ records: UserNftDetailsModel[], total: number }>(
      this.uriConfig.nft,
      { params }
    );
  }

  transferNft(nftId) {
    return this.http.get(
      this.uriConfig.transfer + '/transfer/' + nftId
    );
  }

  setSelectedFilter(filter) {
    this.selectedFilter$.next(filter);
  }

  getSelectedFilter() {
    return this.selectedFilter$;
  }
}

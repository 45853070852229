// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";

@Injectable()
export class UriConfig {
  baseApiUrl = environment.apiUrl;

  login = `${this.baseApiUrl}auth/local/signin`;

  signIp = `${this.baseApiUrl}auth/local/signup`;

  signInGoogle = `${this.baseApiUrl}auth/google`;

  signInFacebook = `${this.baseApiUrl}auth/facebook`;

  logout = `${this.baseApiUrl}auth/logout`;

  refresh = `${this.baseApiUrl}auth/refresh`;

  resetPassword = `${this.baseApiUrl}auth/reset-password`;

  restorePassword = `${this.baseApiUrl}auth/restore-password`;

  validateResetToken = `${this.baseApiUrl}auth/validate-reset-password`;

  confirm = `${this.baseApiUrl}auth/local/confirm`;

  changePassword = `${this.baseApiUrl}auth/change-password`;

  resendConfirmationLetter = `${this.baseApiUrl}auth/local/resend-confirmation`;

  brands = `${this.baseApiUrl}gaming-brands`;

  vendors = `${this.baseApiUrl}vendors`;

  rounds = `${this.baseApiUrl}rounds`;

  questions = `${this.baseApiUrl}questions`;

  answers = `${this.baseApiUrl}answers`;

  users = `${this.baseApiUrl}users`;

  files = `${this.baseApiUrl}files`;

  leaderboard = `${this.baseApiUrl}leaderboards/b2c`;

  nft = `${this.baseApiUrl}prizes/b2c`;

  transfer = `${this.baseApiUrl}prizes`;

  streak = `${this.baseApiUrl}streaks`;

  streakDay = `${this.baseApiUrl}streaks`;

  settings = `${this.baseApiUrl}settings`;

  cms = `${this.baseApiUrl}cms`;
}

import {Pipe, PipeTransform} from '@angular/core';
import {dateFormatEnum} from "../../enums/dateFormatEnum";

@Pipe({
  name: 'formatDate'
})

export class FormatDatePipe implements PipeTransform {

  transform(date: string | Date, monthFormat = 'long'): string {
    const localDate = new Date(date);
    const formattedDate = new Date(
      localDate.getUTCFullYear(),
      localDate.getUTCMonth(),
      localDate.getUTCDate() ,
      localDate.getUTCHours(),
      localDate.getUTCMinutes(),
      localDate.getUTCSeconds());

    const monthNumber = formattedDate.getMonth() + 1;
    const day = formattedDate.getDate();

    const numericMonth = monthNumber < 10 ? '0' + monthNumber : monthNumber;
    const weekDay = formattedDate.toLocaleString('en-IE', {weekday: 'long'})
    const fullDayNumber = day.toString().length === 1 ? '0' + day.toString() : day.toString();
    const time = formattedDate
      .toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})
      .replace(':','.')
      .replace(' ', '')
      .toLowerCase();
    switch (monthFormat) {
      case dateFormatEnum.LONG:
        return `${time} ${weekDay} ${numericMonth}/${fullDayNumber} (UTC)`;
    }

    return null;
  }

}

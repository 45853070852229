<ng-container  *ngIf="cmsContent$ | async as cmsContentConfig">
  <div class="auth-form-container">
    <form [formGroup]="signInForm">

      <mat-label>{{cmsContentConfig['text_content']['sign-in-email-label']}}</mat-label>
      <mat-form-field appearance="outline" [floatLabel]="'always'">
        <input
          matInput
          required
          [formControlName]="'email'"/>
        <mat-error
          class="select-error"
          *ngIf="signInForm.get('email').hasError('required')">
          <span class="text"> {{cmsContentConfig['text_content']['error-madnatory']}}</span>
        </mat-error>
        <mat-error
          class="select-error"
          *ngIf="!signInForm.get('email').hasError('required') && signInForm.get('email').hasError('email')">
          <span class="text"> {{cmsContentConfig['text_content']['error-incorrect-email']}}</span>
        </mat-error>
      </mat-form-field>

      <mat-label>{{cmsContentConfig['text_content']['sign-in-password-label']}}</mat-label>
      <mat-form-field appearance="outline">
        <input
          matInput
          type="password"
          name="password"
          [formControlName]="'password'"/>
        <mat-error *ngIf="signInForm?.get('password').hasError('required')">
          <span> {{cmsContentConfig['text_content']['error-madnatory']}}</span>
        </mat-error>
        <mat-error *ngIf="signInForm?.get('password').hasError('server')">
          <span> {{signInForm?.get('password').getError('server')}}</span>
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="bottom-container">
    <div class="forgot-your-password">
      <a routerLink="/auth/forgotten-password">
        {{cmsContentConfig['text_content']['sign-in-is-forgot-link']}}
      </a>
    </div>
    <button
      [disabled]="!signInForm.valid"
      class="regular-button"
      mat-flat-button
      (click)="onSignIn()"
      color="primary">
      {{pageName}}
    </button>

  </div>
</ng-container>



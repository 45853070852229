<ng-container *ngIf="cmsContent$ | async as cmsContentConfig">
  <div class="description">{{cmsContentConfig['text_content']['main-auth-description-text']}}</div>
  <div class="buttons">
    <ng-container *ngIf="isSocialLoginPermission$ | async">
      <button mat-flat-button class="login-with-social-button facebook" (click)="handleLoginWithFacebook()">
        <img src="../../../../assets/image/icons/facebook.svg">
        {{cmsContentConfig['text_content']['main-auth-facebook-button']}}
      </button>
      <button mat-flat-button class="login-with-social-button google" (click)="handleLoginWithGoogle()">
        <img src="../../../../assets/image/icons/google.svg">
        {{cmsContentConfig['text_content']['main-auth-google-button']}}
      </button>
    </ng-container>
    <a routerLink="/auth/sign-up">
      <button mat-flat-button color="primary">
        <img src="../../../../assets/image/icons/email.svg">
        {{cmsContentConfig['text_content']['main-auth-email-button']}}
      </button>
    </a>
  </div>
  <div class="terms-text">
    <div>
      {{cmsContentConfig['text_content']['main-auth-privacy-text']}} <a routerLink="/terms-of-use">Terms of Use</a> and <a routerLink="/privacy-policy">Privacy Policy</a>
    </div>
  </div>
  <div class="sign-in-button" [ngClass]="{'desktop': isWeb | async}">
    <div>{{cmsContentConfig['text_content']['main-auth-sign-in-text']}}</div>
    <a routerLink="/auth/sign-in">
      <button
        class="regular-button"
        mat-flat-button
        color="primary">
        {{cmsContentConfig['text_content']['main-auth-sign-in-button']}}
      </button>
    </a>
  </div>
  <div class="space" [ngClass]="{'desktop-style': isWeb | async}"></div>
</ng-container>






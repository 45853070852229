import { Injectable } from '@angular/core';
import { finalize } from "rxjs/operators";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { CmsContentService } from './cms-content.service';
import { SaasSetingsService } from './saas-setings.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitializeService {

  appInitializing$ = new BehaviorSubject(false);

  constructor(
    private saasSetingsService: SaasSetingsService,
    private cmsContentService: CmsContentService
  ) { }


  init(): Observable<any> {
    this.appInitializing$.next(true);

    return combineLatest([
      this.saasSetingsService.loadSettings(),
      this.cmsContentService.loadCmsContent()
    ]).pipe(
      finalize(() => this.appInitializing$.next(false))
    );
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators
} from "@angular/forms";
import {PasswordValidator} from "../password-validator";
import {CustomValidators} from "ng2-validation";
import {APP_DATA} from "../../../general.app.config";
import {Observable} from "rxjs";
import {SignUpUser} from "../../../shared/models/signUpUser";
import {SignUpFormType} from "../../../shared/FormsInterface/sign-up-form-interface";
import {CMSContentConfigModel} from "../../../shared/models/CMSContentConfigModel";
import {PasswordValidationRuleProviderService} from "../password-validation-rule-provider.service";

@Component({
  selector: 'hun-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  @Input() errorMessage: string;

  @Input() isUserSignedIn: boolean;

  @Input() pageName: string;

  @Input() isWeb: Observable<boolean>;

  @Input() cmsContent$: Observable<CMSContentConfigModel>;

  @Output() userData = new EventEmitter<SignUpUser>();

  signUpForm: FormGroup<SignUpFormType>;

  appData = APP_DATA;

  errorMassage: string;

  constructor(
    private fb: NonNullableFormBuilder,
    private passwordValidationProvider: PasswordValidationRuleProviderService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.signUpForm.get('password').valueChanges
      .subscribe(() => this.errorMassage = '');
  }

  private buildForm(): void {
    const passwordControl = new FormControl<string | null>('', Validators.compose([
      Validators.required,
      PasswordValidator.patternValidator(/\d/, {hasNumber: true}),
      PasswordValidator.patternValidator(/[A-Z]/, {hasUpperCase: true}),
      PasswordValidator.patternValidator(/[a-z]/, {hasLowerCase: true}),
      PasswordValidator.patternValidator(/[#?!\[\]~`@$%^&\\'*\-<>(){}|\/"]/, {hasSpecialCharacters: true}),
      Validators.minLength(8),
      Validators.maxLength(20),
    ]));
    const passwordConfirmControl = new FormControl<string | null>('', [Validators.required,
      CustomValidators.equalTo(passwordControl)]);

    this.signUpForm = this.fb.group({
      firstName: ['',
        [Validators.required,
        Validators.minLength(3),
        Validators.maxLength(15),
        Validators.pattern("^[a-zA-Z-]*$")]
      ],
      lastName: ['',
        [Validators.required,
          Validators.minLength(3),
          Validators.maxLength(15),
          Validators.pattern("^[a-zA-Z-]*$")]
      ],
      email: ['', [Validators.required, Validators.email]],
      password: passwordControl,
      passwordConfirmation: passwordConfirmControl,
    });
  }

  hasPasswordError(): boolean {
    let response = false;
    for (let i = 0; i < this.passwordValidationProvider.validatorsRule.length - 1; i++) {
      const errorName = this.passwordValidationProvider.validatorsRule[i].name;
      response = this.signUpForm.get('password').hasError(errorName)
        || !this.signUpForm.value.password;
      this.errorMassage = this.passwordValidationProvider.validatorsRule[i].massage
      if (response) break;
    }
    return response;
  }

  onSignUp() {
    const user = {...new SignUpUser, ...this.signUpForm.value};
    user.role = 'B2C'
    this.userData.emit(user);
  }

  getError(name) {
    if (!this.signUpForm.get(name)) return false;
    if (!this.signUpForm.get(name).errors) return false;
    const errorsLen = Object.keys(this.signUpForm.get(name).errors).length;
    return this.signUpForm.get(name).touched && errorsLen > 1;
  }


}

import { Injectable } from '@angular/core';
import {CmsContentService} from "../../core/services/cms-content.service";
import {APP_DATA} from "../../general.app.config";

@Injectable({
  providedIn: 'root'
})
export class PasswordValidationRuleProviderService {

  validatorsRule = []

  constructor(
    private cmsContentService: CmsContentService
  ) {
    this.generateValidators()
  }

  generateValidators() {
    const content = this.cmsContentService.cmsContent$.value['text_content'];

    this.validatorsRule = [
      {name: 'hasNumber', massage: content['error-password-iclude-digit']},
      {name: 'hasUpperCase', massage: content['error-password-iclude-upper-case']},
      {name: 'hasLowerCase', massage: content['error-password-iclude-lower-case']},
      {name: 'hasSpecialCharacters', massage:content['error-password-iclude-special-characters']},
      {name: 'minlength', massage: content['error-password-iclude-8-characters']},
      {name: 'maxlength', massage: content['error-max-20-characters']},
    ];

  }
}

import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {AuthViewEnum} from "../../../../../auth/enums/auth-view.enum";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'hun-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss']
})
export class AuthHeaderComponent implements OnInit, OnDestroy {

  @Input() pageName: string;

  @Input() isShowBackButton: boolean;

  @Input() isShowLogo: boolean;

  @Input() isWeb: Observable<boolean>;

  @Input() backRoute: string;

  @Input() view: AuthViewEnum;

  isWebView: boolean;

  viewEnum = AuthViewEnum;

  private unsubscribe$: Subject<void> = new Subject();

  constructor() { }

  ngOnInit(): void {
    this.isWeb
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => this.isWebView = response);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isShowButton() {
    if (this.isWebView) return false;

    if (!this.isShowBackButton) return false;

    return true;
  }

}

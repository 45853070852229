import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeScreenComponent} from "./home-screen.component";
import {HOME_SCREEN_ROUTES} from "./home-screen.routing.config";
import {GamesComponent} from "./content-tabs/games/games.component";
import {LeaderboardComponent} from "./content-tabs/leaderboard/leaderboard.component";
import {YourNftsComponent} from "./content-tabs/your-nfts/your-nfts.component";
import {ProfileComponent} from "./content-tabs/profile/profile.component";
import {ProfileGuard} from "../core/guards/profile.guard";
import {AuthContainerComponent} from "../auth/auth-container.component";

const routes: Routes = [
  {
    path: '',
    component: HomeScreenComponent,
    children: [
      {
        path: HOME_SCREEN_ROUTES.GAMES,
        component: GamesComponent,
        pathMatch: 'full'
      },
      {
        path: HOME_SCREEN_ROUTES.LEADERBOARD,
        component: LeaderboardComponent,
      },
      {
        path: HOME_SCREEN_ROUTES.YOUR_NFTS,
        component: YourNftsComponent,
      },
      {
        path: HOME_SCREEN_ROUTES.PROFILE + '/' + HOME_SCREEN_ROUTES.CHANGE_PASSWORD,
        component: ProfileComponent,
      },
      {
        path: HOME_SCREEN_ROUTES.PROFILE,
        canActivate:[ProfileGuard],
        component: ProfileComponent,
      },
      {
        path: HOME_SCREEN_ROUTES.AUTH,
        component: AuthContainerComponent,
      },

    ]

  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class HomeScreenRoutingModule { }

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {fromEvent, Observable, Subject, Subscription} from "rxjs";
import {debounceTime, first, startWith, switchMap, take, takeUntil, tap} from "rxjs/operators";
import {cloneDeep} from "lodash";
import {Router} from "@angular/router";
import {BreakpointObserverService} from "../../../core/breakpoint-observer/breakpoint-observer.service";
import {APP_ROUTES} from "../../../app.routes.config";
import {StreakService} from "../../../core/services/streak.service";
import {ProcessTextService} from "../../../core/services/process-text.service";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {CMSContentConfigModel} from "../../../shared/models/CMSContentConfigModel";

@Component({
  selector: 'hun-streak-games',
  templateUrl: './streak-games.component.html',
  styleUrls: ['./streak-games.component.scss']
})
export class StreakGamesComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() isWeb: Observable<boolean>;

  @Input() cmsContent$: Observable<CMSContentConfigModel>;

  @Output() loaded = new EventEmitter<boolean>();

  @ViewChild('carusel', {read: ElementRef}) public carusel: ElementRef<any>;

  @ViewChild('carousel', {read: CdkVirtualScrollViewport}) public _carousel: CdkVirtualScrollViewport;

  private unsubscribe$: Subject<void> = new Subject();

  isLoaded = false;

  streakList = [];

  isAllStreakLoaded = false;

  pageNumber = 0;

  titleFieldName = 'name';

  chips:{name: string, label: string}[] = [];

  chipsItems: {streak: string, day: string, prize: string};

  private magnet$: Subscription;

  public activeElementIndex = 0;

  public scrollEnded = true;

  isCarouselSet = false;

  size: number;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.size = document.getElementsByClassName('example-item')[0].clientWidth;
    this.setMobileCarousel();
  }

  constructor(
    private router: Router,
    private streakService: StreakService,
    private breakpointObserverService: BreakpointObserverService,
    private processTextService: ProcessTextService,
  ) {
  }

  ngOnInit(): void {
    this.cmsContent$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(cmsContent => {
        this.chips = [
          {name: cmsContent['text_content']['games-prize-streak-card'].toLowerCase(), label: 'prize'},
          {name: cmsContent['text_content']['games-day-streak-card'].toLowerCase(), label: 'day'}
        ]
        for (let i = 0; i < 4; i++) {
          this.chips.push({name: `sport_${i}`, label: `sport_${i}`});
        }
        this.chipsItems = {
          streak: cmsContent['text_content']['games-streak-streak-card'],
          day: cmsContent['text_content']['games-day-streak-card'],
          prize: cmsContent['text_content']['games-prize-streak-card'],
        }
      })
    this.isWeb = this.breakpointObserverService.isWeb;

    this.fetchStreakGames();
  }

  scrollToLeft() {
    this.carusel.nativeElement.scrollLeft -= 232;
  }

  scrollToRight() {
    this.carusel.nativeElement.scrollLeft += 232;
  }

  onScroll() {
    if (this.isAllStreakLoaded) return;
    this.fetchStreakGames();
  }

  fetchStreakGames() {
    this.pageNumber += 1;
    this.streakService.getStreakRounds(this.pageNumber)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({records, total}) => {
        this.loaded.emit(true);
        this.isLoaded = true;
        this.isAllStreakLoaded = !records.length;
        // this.prepareStreakList(records.filter(r => r.type === 'VIDEO_STREAK'));
        this.prepareStreakList(records);
        if (this.streakList.length >= total) this.isAllStreakLoaded = true;
        if (!this.isCarouselSet) this.setMobileCarousel();
      })
  }

  prepareStreakList(streaks) {
    const {streak, prize, day} = this.chipsItems;
    const newRecords = streaks.map(streakItem => {
      const newStreak = this.getItem(streakItem)
      newStreak.prize = prize;
      newStreak.day = streak + (streakItem.day ? ` - ${day} ` + streakItem.day : '');
      return newStreak;
    })
    this.streakList = [...this.streakList, ...newRecords];
  }

  handleSelectCard(streak) {
    this.router.navigate([
      `${APP_ROUTES.GAME}/${APP_ROUTES.STREAK}/${streak.id}`,
    ]);
  }

  getItem(item) {
    const newItem = cloneDeep(item);
    item.sport.forEach((sportType, index) => {
      const name = 'sport_' + index;
      newItem[name] = this.processTextService.capitalizeFirstLetter(sportType);
    })
    return newItem;
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.magnet$) {
      this.magnet$.unsubscribe();
    }
  }

  setMobileCarousel() {
    this.isWeb
      .pipe(first())
      .subscribe(val => {
        setTimeout(() => {
          if (!val && this._carousel) {
            this.size = document.getElementsByClassName('example-item')[0].clientWidth;
            fromEvent(this._carousel.elementRef.nativeElement, 'touchstart').subscribe(() => (this.scrollEnded = false));
            this.isCarouselSet = true;
            const scrolled$ = fromEvent(this._carousel.elementRef.nativeElement, 'scroll');
            this.magnet$ = fromEvent(this._carousel.elementRef.nativeElement, 'touchend')
              .pipe(
                switchMap(() => scrolled$.pipe(startWith(null as Event), debounceTime(40), take(1))))
              .subscribe(() => {
                this.scrollEnded = true;
              });
            this._carousel.scrolledIndexChange.subscribe(val => {
              if (val === this.streakList.length - 4) {
                this.onScroll();
              }
            })
          }
        }, 100)
      })

  }
}

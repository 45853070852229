<button
  class="regular-button"
  mat-flat-button
  [disabled]=isDisabled()
  (click)="onClickEvent()"
  color="primary">
  <span *ngIf="!isLoading; else loader">
      {{buttonTitle}}
  </span>
  <ng-template #loader>
    <mat-spinner [diameter]="30" color="#fff"></mat-spinner>
  </ng-template>
</button>

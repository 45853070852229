export const APP_DATA = {
  MANDATORY: 'Please, fill this field',
  DONT_MATCH: 'Passwords don\'t match',
  EMAIL_ERROR: 'Incorrect email',
  INCLUDE_DIGIT: 'Must include a digit',
  INCLUDE_LOWER_CASE: 'Must contain a lowercase letter',
  INCLUDE_UPPER_CASE: 'Must contain an uppercase letter',
  MIN_LENGHT: 'Must contain at least 8 characters',
  MIN_LENGHT_3: 'Must contain at least 3 characters',
  MAX_LENGHT: 'Must contain 20 characters maximum',
  MAX_LENGHT_15: 'Must contain 15 characters maximum',
  SPECIAL_CHARACTERS: 'Must contain a special character',
  SPECIAL_CHARACTER: 'Please, remove unsupported characters',
  MOBILE_WIDTH: 768,
  MAX_SCORE_VALUE: 999,
  SUMMARY_SCREEN_ID: '0000',
  LATEST_SELECTION_FILTER: 'selectedFilter',
  LOCAL_STORAGE_ACTIVE_ANSWER: 'activeAnswer',
  LOCAL_STORAGE_SELECTED_ANSWERS: 'selectedAnswers',
  LOCAL_STORAGE_HISTORICAL_SELECT: 'historicalSelect',
  ACTIVE_GAME: 'activeGame',
  ACTIVE_GAME_URL: 'activeGameUrl',
  FROM_RESULT: 'fromResult',
  MIN_PLAYER_COUNT: 100
};

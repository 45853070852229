import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {intercomLib} from "./intercom.lib";
import {LocalStorageService} from "../services/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class IntercomService {

  constructor(
    private localStorageService: LocalStorageService,
  ) {
    this.initIntercomService();
  }

  launchIntercomService(user: any, metaMaskWalletAddress?: string): void {
    if (user && user.email) {
      this.startUserMessenger(user, metaMaskWalletAddress);
    } else {
      this.startGuestMessenger();
    }
  }

  private initIntercomService(): void {
    const appId = environment.intercomId;

    window['intercomSettings'] = {
      app_id: appId,
      hide_default_launcher: true
    };

    try {
      intercomLib(window, appId);
    } catch (error) {
      console.log('error init intercom service');
    }
  }

  private startGuestMessenger() {
    // window['Intercom']('shutdown');

    window['Intercom']('boot', { app_id: environment.intercomId });
    window['Intercom']('hide');

    // windowRef.Intercom('update');
  }

  private startUserMessenger(user: any, metaMaskWalletAddress?: string) {
    const loginType = this.localStorageService.getLoginType();
    const intercomBody = new IntercomBody();
    intercomBody.app_id = environment.intercomId;
    intercomBody.name = `${user.firstName} ${user.lastName}`;
    intercomBody.email = user.email;
    intercomBody.user_id = user.id;
    intercomBody.login_method = loginType;
    if (user.metaMaskWalletAddress) {
      intercomBody.meta_mask_wallet_address = user.metaMaskWalletAddress;
    }
    if (metaMaskWalletAddress) {
      intercomBody.meta_mask_wallet_address = metaMaskWalletAddress;
    }
    window['Intercom']('boot', intercomBody);
    window['Intercom']('hide');

  }

  sendUpsellEvent(metadata) {
    window['Intercom']('trackEvent', 'Upsell Link Clicked', metadata);
  }
}

export class IntercomBody {
  app_id: string
  name: string
  email: string
  user_id: string
  login_method: string
  meta_mask_wallet_address: string
}

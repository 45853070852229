import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UriConfig} from "../../app.config";
import {BehaviorSubject, Observable} from "rxjs";
import {UserDetailsModel} from "../../shared/models/UserDetail";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userDetails$ = new BehaviorSubject<UserDetailsModel>(null);

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,) { }

  getCurrentUser(): Observable<UserDetailsModel> {
    return this.http.get<UserDetailsModel>(this.uriConfig.users);
  }

  saveUserData(body): Observable<UserDetailsModel>{
    return this.http.patch<UserDetailsModel>(this.uriConfig.users, body);
  }

  setUserDetails (user) {
    this.userDetails$.next(user);
  }

  getUserDetails() {
    return this.userDetails$;
  }
}

import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {LocalStorageService} from "../services/local-storage.service";


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) {
  }

  canActivate() {
    if (this.authService.getCurrentUser()) {
      const email = this.localStorageService.getItem('email');
      const role = this.localStorageService.getItem('role');
      this.authService.setSentryUser({email, role});
      this.router.navigate(['/']);
    }
    return true;
  }
}

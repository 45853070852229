export enum StreakVideoGameInternalStatusEnum {
  Prepare,
  WatchVideo,
  AnswerTimer,
  FetchingAnswer,
  Correct,
  Uncorrect,
  Scipped
}

export enum StreakGameState {
  Prepare,
  Playing,
  Result,
  No_Answer
}

export enum VideoStreakAnswerStatus {
  PENDING = 'PENDING',
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
  TIME_IS_UP = 'TIME_IS_UP'
}

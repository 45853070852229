<div class="heroes-container" *ngIf="cmsContent$ | async as cmsContentConfig">
  <ng-container *ngFor="let leader of leadersList; let i = index">
    <ng-container *ngIf="i <3; else shortItem">
      <hun-full-leader-point
        [order]="i"
        [setLeader]="leader">
      </hun-full-leader-point>
    </ng-container>
    <ng-template #shortItem>
      <hun-short-leader-point
        [cmsContent$]="cmsContent$"
        [leader]="leader">
      </hun-short-leader-point>
    </ng-template>
  </ng-container>
  <div *ngIf="!leadersList.length && !isLoading" class="empty-text">
    {{cmsContentConfig['text_content']['leaderboard-empty-text']}}
  </div>
</div>

<div class="weekly-container" *ngIf="cmsContent$ | async as cmsContentConfig">
  <ng-container *ngFor="let leader of leadersList">
    <hun-short-leader-point
      [cmsContent$]="cmsContent$"
      [leader]="leader">
    </hun-short-leader-point>
  </ng-container>
  <div *ngIf="!leadersList.length && !isLoading" class="empty-text">
    {{cmsContentConfig['text_content']['leaderboard-empty-text']}}
  </div>
</div>

import { Injectable } from '@angular/core';
import {UserLoginEnum} from "../../shared/enums/userLoginEnum";
import {SettingsConfigDetail} from "../../shared/models/SettingsConfigDetail";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private TOKEN_KEY = 'auth-token';
  private REFRESHTOKEN_KEY = 'auth-refreshtoken';
  private USER_KEY = 'auth-user';
  private LOGIN_TYPE = 'login_type';
  private SETTINGS_HASH = 'settings-hash';
  private CMS_CONTENT_HASH = 'cms-content-hash';
  private SETTINGS = 'settings';
  private CMS_CONTENT = 'cms-content';


  constructor() { }

  setItem(prop: string, value: any): void {
    localStorage.setItem(prop, JSON.stringify(value));
  }

  getItem(prop: string): any {
    return JSON.parse(localStorage.getItem(prop));
  }

  removeItem(prop: string) {
    localStorage.removeItem(prop);
  }

  clear() {
    localStorage.clear();
  }

  setCmsContentHash(value: string) {
    this.setItem(this.CMS_CONTENT_HASH, value);
  }

  getCmsContentHash() {
    return this.getItem(this.CMS_CONTENT_HASH);
  }

  // TO DO: need add type to cms content
  setCmsContent(value: any) {
    this.setItem(this.CMS_CONTENT, value);
  }

  // TO DO: need add type to cms content
  getCmsContent():any {
    return this.getItem(this.CMS_CONTENT);
  }

  getToken(): string {
    return this.getItem(this.TOKEN_KEY)
  }

  getRefreshToken(): string {
    return this.getItem(this.REFRESHTOKEN_KEY)
  }

  setToken(token: string) {
    this.setItem(this.TOKEN_KEY, token)
  }

  setRefreshToken(refreshToken: string) {
    this.setItem(this.REFRESHTOKEN_KEY, refreshToken)
  }

  setLoginType(type: UserLoginEnum) {
    this.setItem(this.LOGIN_TYPE, type);
  }

  getLoginType() {
    return this.getItem(this.LOGIN_TYPE);
  }

  getSettingsConfig() {
    return this.getItem(this.SETTINGS);
  }

  setSettingsConfig(config: SettingsConfigDetail[]) {
    this.setItem(this.SETTINGS, config)
  }

  getSettingsHash() {
    return this.getItem(this.SETTINGS_HASH);
  }

  setSettingsHash(hash: string) {
    this.setItem(this.SETTINGS_HASH, hash)
  }

  deleteLoginType() {
    this.removeItem(this.LOGIN_TYPE);
  }

  logOut() {
    this.removeItem(this.TOKEN_KEY);
    this.removeItem(this.REFRESHTOKEN_KEY);
    this.removeItem('email');
    this.removeItem('role');
    this.removeItem(this.LOGIN_TYPE);
    this.clear();
  }
}

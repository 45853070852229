import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {APP_ROUTES} from "./app.routes.config";
import {PrivacyPolicyComponent} from "./shared/policy/privacy-policy/privacy-policy.component";
import {TermsOfUseComponent} from "./shared/policy/terms-of-use/terms-of-use.component";
import {AuthContainerComponent} from "./auth/auth-container.component";
import {NftExplainedComponent} from "./shared/nft-explained/nft-explained.component";
import {UpsellComponent} from "./shared/upsell/upsell.component";
import {SummaryScreenStrComponent} from "./shared/summary-screen/summary-screen-str.component";
import {TermsOfConditionComponent} from "./shared/policy/terms-of-condition/terms-of-condition.component";
import { OnlyFrameAccessGuard } from './core/guards/only-frame-access.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [ OnlyFrameAccessGuard ],
    children: [
      {
        path: '',
        loadChildren: () => import('./home-screen/home-screen.module').then((m) => m.HomeScreenModule),
      },
      {
        path: 'auth/user-activate',
        component: AuthContainerComponent,
        pathMatch: 'prefix'
      },
      {
        path: 'restorePassword',
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
      },
      {
        path: 'terms-of-use',
        component: TermsOfUseComponent,
      },
      {
        path: 'terms-and-conditions',
        component: TermsOfConditionComponent,
      },
      {
        path: 'prize-explained',
        component: NftExplainedComponent,
      },
      {
        path: 'upsell',
        component: UpsellComponent,
      },
      {
        path: APP_ROUTES.STREAK_HISTORY + '/' + ':id',
        component: SummaryScreenStrComponent,
      },
      {
        path: APP_ROUTES.BRAND,
        loadChildren: () => import('./home-screen/components/top-brands/top-brand.module').then((m) => m.TopBrandModule),
      },
      {
        path: APP_ROUTES.GAME,
        loadChildren: () => import('./home-screen/components/games.module').then((m) => m.GamesModule),
      },
      {
        path: 'frame',
        loadChildren: () => import('./frame/frame.module').then((m) => m.FrameModule),
      },
      {
        path: '**',
        redirectTo:  ''
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

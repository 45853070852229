import {Component, Input, OnInit} from '@angular/core';
import {SingleLeaderModel} from "../../../../../shared/models/LeaderboardsDetail";
import {CmsContentService} from "../../../../../core/services/cms-content.service";
import {Observable} from "rxjs";
import {CMSContentConfigModel} from "../../../../../shared/models/CMSContentConfigModel";

@Component({
  selector: 'hun-heroes',
  templateUrl: './heroes.component.html',
  styleUrls: ['./heroes.component.scss']
})
export class HeroesComponent implements OnInit {

  @Input() leadersList: SingleLeaderModel[];

  @Input() isLoading: boolean;

  @Input() cmsContent$: Observable<CMSContentConfigModel>;

  constructor(
    public cmsContentService: CmsContentService
  ) {
  }

  ngOnInit(): void {
  }
}

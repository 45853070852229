import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AuthViewEnum} from "./enums/auth-view.enum";
import {BehaviorSubject, combineLatest, Observable, of, Subject, throwError} from "rxjs";
import {catchError, filter, switchMap, takeUntil, tap} from "rxjs/operators";
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from "@angular/router";
import {SignUpUser} from "../shared/models/signUpUser";
import {AuthService} from "../core/services/auth.service";
import {BreakpointObserverService} from "../core/breakpoint-observer/breakpoint-observer.service";
import {LocalStorageService} from "../core/services/local-storage.service";
import {SnackBarService} from "../core/services/snack-bar.service";
import {User} from "../shared/models/User";
import {UserLoginEnum} from "../shared/enums/userLoginEnum";
import {UserService} from "../core/services/user.service";
import {DialogService} from "../shared/modules/common-control-components/components/dialog/dialog.service";
import {cloneDeep} from "lodash";
import {CmsContentService} from "../core/services/cms-content.service";

@Component({
  selector: 'hun-auth',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.scss']
})
export class AuthContainerComponent implements OnInit, OnDestroy {

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.count = Math.ceil(window.innerWidth / 40);
  }

  isWeb: Observable<boolean>;

  viewEnum = AuthViewEnum;

  view: AuthViewEnum;

  errorMessage = '';

  token: string;

  isSendingResetRequest = false;

  isTokenValid = true;

  pageName: string;

  isUserSignedIn = false;

  count: number;

  isPasswordChanged = false;

  isShowBackButton: boolean;

  isShowLogo: boolean;

  signInFormError$ = new BehaviorSubject<{ massage, field }>(null);

  resetPasswordFormError$ = new BehaviorSubject<{ massage, field }>(null);

  isLoading = false;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private breakpointObserverService: BreakpointObserverService,
    private localStorageService: LocalStorageService,
    public snackBarService: SnackBarService,
    private userService: UserService,
    private dialogService: DialogService,
    public cmsContentService: CmsContentService
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.count = Math.ceil(window.innerWidth / 40);
    this.isWeb = this.breakpointObserverService.isWeb;
    this.processRouteParams();
  }

  private processRouteParams(): void {
    combineLatest([this.route.url, this.route.queryParams])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([url, params]: [any, any]) => {
        this.isSendingResetRequest = false;
        let view;
        if (url[1] && url[1].path) {
          view = url[1].path;
        } else if (url[0] && url[0].path && url[0].path !== 'auth') {
          view = url[0].path;
        } else {
          view = AuthViewEnum.AUTH;
        }
        if (params && params.token) {
          const {token} = params;
          this.token = token;
          if (view === AuthViewEnum.USER_ACTIVATE) {
            this.isLoading = true;
            let userToken;
            this.authService.confirmEmailRegistration({token})
              .pipe(
                switchMap(response => {
                  if (response.accessToken) {
                    this.localStorageService.setToken(response.accessToken);
                    this.localStorageService.setRefreshToken(response.refreshToken);
                    userToken = response;
                    this.isTokenValid = true;
                    return this.userService.getCurrentUser();
                  }
                  view = AuthViewEnum.SIGN_IN;
                  this.processRoute(AuthViewEnum.SIGN_IN);

                  return of(null);
                }),
                catchError((err) => {
                  this.isLoading = false;
                  this.isTokenValid = false;
                  return err;
                }),
              )
              .subscribe(
                (user) => {
                  if (!user) {
                    this.isLoading = false;
                    view = AuthViewEnum.SIGN_IN;
                    this.processRoute(AuthViewEnum.SIGN_IN);
                    return;
                  }
                  this.processLoggedUser(user);
                  this.authService.setCurrentLoginType(UserLoginEnum.email);
                  this.localStorageService.setLoginType(UserLoginEnum.email);
                  this.isLoading = false;
                  return;
                },
                () => {
                  this.isLoading = false;
                  this.isTokenValid = false;
                  return this.processRoute(AuthViewEnum.AUTH);
                });
          }
          if (view === AuthViewEnum.RESTORE_PASSWORD) {
            view = AuthViewEnum.SET_NEW_PASSWORD;
            this.authService.validateRestoreToken({token})
              .pipe(
                tap(tokens => {
                  if (tokens.accessToken) {
                    return this.userService.getCurrentUser()
                  }
                  return of(null)
                }),
                takeUntil(this.unsubscribe$))
              .subscribe(({isValid}) => {
                this.isTokenValid = isValid;
                return this.processRoute(AuthViewEnum.SET_NEW_PASSWORD);
              })
          }
        } else {
          if (view === AuthViewEnum.SET_NEW_PASSWORD) {
            view = AuthViewEnum.AUTH;
            this.location.go('/auth');
          }
          this.processRoute(view);
        }

        this.isShowBackButton = view === AuthViewEnum.SIGN_IN ||
          view === AuthViewEnum.SIGN_UP ||
          view === AuthViewEnum.FORGOT_PASSWORD;

        this.isShowLogo = view === AuthViewEnum.SIGN_IN ||
          view === AuthViewEnum.SIGN_UP ||
          view === AuthViewEnum.SET_NEW_PASSWORD;

        this.pageName = this.getPageName(view);

      });
  }

  private processRoute(view: AuthViewEnum): void {
    this.view = view;
    this.cdr.markForCheck();
  }

  isTextComponent() {
    return this.isUserSignedIn;
  }

  handleUserSignUp(user: SignUpUser) {
    this.authService.userSignUp(user)
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
          this.isUserSignedIn = true
        },
        err => {
          if (typeof err.error.message === 'string') {
            this.snackBarService.showSnackBar(err.error.message);
          } else {
            err.error.message.forEach(error => this.snackBarService.showSnackBar(error));
          }
        });
  }

  handleUserSignIn(user: User) {
    const newUser = {...new User, ...user}
    this.authService.userSignIn(newUser)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
          this.processLoggedUser(newUser, data);
          this.authService.setCurrentLoginType(UserLoginEnum.email);
          this.localStorageService.setLoginType(UserLoginEnum.email);
        },
        err => {
          if (err.status === 422) {
            this.dialogService.open({
              dialogContent: this.cmsContentService.cmsContent$.value['text_content']['confirmation-email-send-popup-explain-text'],
              labelOk: this.cmsContentService.cmsContent$.value['text_content']['confirmation-email-send-popup-ok-button'],
              labelNo: this.cmsContentService.cmsContent$.value['text_content']['confirmation-email-send-popup-resend-button']
            }).pipe(
              filter(response => response),
              takeUntil(this.unsubscribe$))
              .subscribe(() => {
                const body = cloneDeep(newUser) as any;
                delete body.password;
                body.destinationURL = '';
                this.authService.resendConfirmationLetter(body)
                  .pipe(takeUntil(this.unsubscribe$))
                  .subscribe(() => this.snackBarService.showSnackBar(
                    this.cmsContentService.cmsContent$.value['text_content']['snack-bar-send-email-text']
                  ));
              });
            return;
          }
          const massage = err.error.message;
          this.signInFormError$.next({massage, field: 'password'});
          this.errorMessage = err.error.message;
        }
      )
  }

  handleSignInFormChanged() {
    this.signInFormError$.next({massage: null, field: 'password'});
  }

  handleLoginWithEmail() {
    this.location.go('/auth/sign-up');
    this.view = AuthViewEnum.SIGN_UP;
  }

  handleLoginWithGoogle(googleAccess) {
    this.authService.userSignInWithGoogle(googleAccess.access_token)
      .pipe(switchMap(data => {
        this.setLocalStorageUserItem(data);
        return this.userService.getCurrentUser();
      }))
      .subscribe(
        googleUser => {
          this.processLoggedUser(googleUser);
          this.authService.setCurrentLoginType(UserLoginEnum.google);
          this.localStorageService.setLoginType(UserLoginEnum.google);
        },
        err => {
        }
      )
  }

  handleLoginWithFacebook(facebookAccess) {
    this.authService.userSignInWithFacebook(facebookAccess.accessToken)
      .pipe(switchMap(data => {
        this.setLocalStorageUserItem(data);
        return this.userService.getCurrentUser();
      }))
      .subscribe(facebookUser => {
          this.processLoggedUser(facebookUser);
          this.authService.setCurrentLoginType(UserLoginEnum.facebook);
          this.localStorageService.setLoginType(UserLoginEnum.facebook);
        },
        err => {
        }
      )
  }

  handleForgotPassword(email: string) {
    const body = {...new User, email}
    this.authService.resetPassword(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
          this.isSendingResetRequest = true;
        },
        err => {
          const massage = JSON.parse(err.error).message;
          this.resetPasswordFormError$.next({massage, field: 'email'});
        });
  }

  handleChangeTitle() {
    if (this.view === AuthViewEnum.FORGOT_PASSWORD && this.isSendingResetRequest) {
      this.pageName = this.cmsContentService.cmsContent$.value['text_content']['forgot-password-confirmation-text'];
    }
  }

  handleSetNewPassword({password}) {
    this.isPasswordChanged = true;
    const {token} = this;
    const requestBody = {password, token};
    this.authService.restorePassword(requestBody).subscribe(() => {
        this.isPasswordChanged = false;
        this.location.go('/auth/sign-in');
        this.view = AuthViewEnum.SIGN_IN;
        this.processRoute(this.view);
        this.pageName = this.getPageName(this.view);
        this.snackBarService.showSnackBar(
          this.cmsContentService.cmsContent$.value['text_content']['snack-bar-password-changed-text']
        );
      },
      error => {
        this.snackBarService.showSnackBar(error.error.message);
      });
  }

  getPageName(address: string): string {
    const config = this.cmsContentService.cmsContent$.value['text_content']
    switch (address) {
      case AuthViewEnum.AUTH:
        return config['main-auth-header-text'];
      case AuthViewEnum.FORGOT_PASSWORD:
      case AuthViewEnum.SET_NEW_PASSWORD:
        return config['forgot-password-header-text'];
      case AuthViewEnum.SIGN_IN:
          return config['sign-in-sign-in-button'];
      case AuthViewEnum.SIGN_UP:
          return  config['sign-up-sign-up-button'];
      default:
        const upperCasedAddress = address.charAt(0).toUpperCase() + address.slice(1);
        return upperCasedAddress.replace('-', ' ');
    }
  }

  processLoggedUser(user, data = null) {
    let {email, role} = user;
    if (data) {
      this.setLocalStorageUserItem(data, email, role);
    } else {
      this.localStorageService.setItem('email', email);
    }

    if (!role) role = 'B2C';

    this.authService.setSentryUser({email, role});
    this.authService.processeCurrentUser();

    this.router.navigate(['/']);
  }

  setLocalStorageUserItem(data, email = '', role = 'B2C') {
    this.localStorageService.setItem('email', email);
    this.localStorageService.setItem('role', role);
    this.localStorageService.setToken(data.accessToken);
    this.localStorageService.setRefreshToken(data.refreshToken);
  }
}

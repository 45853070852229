<ng-container *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig">
  <div
    [ngClass]="{
  'desktop-style': isWeb | async,
  'not-small': !(isXSmall | async),
  'logged-in-container': (isWeb | async) && isLoggedIn}"
    class="your-nft-container"
    infinite-scroll
    [infiniteScrollDistance]="0.5"
    (scrolled)="onScroll()">
    <h1 *ngIf="isWeb | async" [ngClass]="{'logged-in-header': (isWeb | async) && isLoggedIn}">
      {{cmsContentConfig['text_content']['locker-header-label']}}
    </h1>
    <div *ngIf="(isWeb | async) && isLoggedIn" class="chip-container">
      <hun-tag-chip [chipValue]="userPts ? userPts + cmsContentConfig['text_content']['games(profile)-points-label'] : ''"></hun-tag-chip></div>
    <hun-header-filter
      *ngIf="isWeb | async"
      [location]="HOME_SCREEN_ROUTES.YOUR_NFTS"
      [filterOption]="pageFilterOption">
    </hun-header-filter>
    <ng-container [ngSwitch]="selectedTab">
      <div
        class="items-container"
        [ngClass]="{
      'empty': !nftList.length,
      'logged-in-user': !isLoggedIn}"
        *ngSwitchCase="NftScreenEnum.NFT">
        <div class="item" *ngFor="let item of nftList">
          <hun-nft-item
            [nft]="item"
            [openedId]="openedTippyId"
            (tippyOpened)="onTippyOpened($event)"
            (isNftTranfered)="onNftTranfered()">
          </hun-nft-item>
        </div>
        <div *ngIf="!nftList.length && !isLoading" class="empty-massage">
          <ng-container *ngTemplateOutlet="emptyPageMassage"></ng-container>
        </div>
      </div>
      <div
        class="items-container"
        [ngClass]="{
      'empty': !gameList.length,
      'logged-in-user': !isLoggedIn}"
        *ngSwitchCase="NftScreenEnum.GAME">
        <div
          class="item"
          *ngFor="let item of gameList">
          <hun-game-item
            [cmsContent$]="cmsContentService.cmsContent$"
            [isWeb]="isWeb"
            [game]="item">
          </hun-game-item>
        </div>
        <div *ngIf="!gameList.length && !isLoading" class="empty-massage">
          <ng-container *ngTemplateOutlet="emptyPageMassage"></ng-container>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-template #emptyPageMassage>
    <ng-container [ngTemplateOutlet]="isLoggedIn ? emptyList : pleaseLogin">
      <ng-template
        #emptyList>
        {{selectedTab === NftScreenEnum.NFT ?
        cmsContentConfig['text_content']['locker-empty-prizes-text'] :
        cmsContentConfig['text_content']['locker-empty-games-text']}}
      </ng-template>
      <ng-template #pleaseLogin> {{cmsContentConfig['text_content']['locker-need-sign-in-text'].replace('{tab_name}', selectedTab.split(' ')[1])}}</ng-template>
    </ng-container>
  </ng-template>
</ng-container>
<div *ngIf="isLoading" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>
<div class="space" [ngClass]="{
'desktop-style': isWeb | async
}"></div>

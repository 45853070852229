<div class="list-container" [className]=" 'list-container ' + 'item' + order" *ngIf="cmsContent$ | async as cmsContentConfig">
  <div class="image-cintainer">
    <hun-image-with-loader
      [imageSrc]="leader.prizeImageUrl"
      [overlayHeight]="'76px'"
      [spinnerSize]="40"
    >
    </hun-image-with-loader>
  </div>
  <hun-short-leader-point [leader]="leader" [cmsContent$]="cmsContent$"></hun-short-leader-point>
</div>

<div *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig">
  <mat-toolbar
    *ngIf="!(isWeb | async) && isShowBackButton">
    <hun-back-button
      [customClickAction]="false"
      [buttonTextColor]="cmsContentConfig['colour_content']['primary-300']"
      [buttonTextShadow]="''"
      [opacityBackground]="true">
    </hun-back-button>
  </mat-toolbar>
  <div class="auth-layout" [ngClass]="{'text-massage': isTextComponent(), 'desktop-style': isWeb | async}">
    <hun-auth-header
      *ngIf="!isLoading"
      [ngClass]="{'set-password': view === viewEnum.SET_NEW_PASSWORD}"
      [isWeb]="isWeb"
      [pageName]="pageName"
      [isShowBackButton]="isShowBackButton"
      [isShowLogo]="isShowLogo"
      [view]="view"
    >
    </hun-auth-header>
    <div class="main-container">
      <ng-container [ngSwitch]="view">
        <hun-sign-up
          *ngSwitchCase="viewEnum.SIGN_UP"
          [attr.attr-data-autotestId]="'login_auth-container'"
          [errorMessage]="errorMessage"
          [isUserSignedIn]="isUserSignedIn"
          [pageName]="pageName"
          [isWeb]="isWeb"
          [cmsContent$]="cmsContentService.cmsContent$"
          (userData)="handleUserSignUp($event)"
        >
        </hun-sign-up>

        <hun-sign-in
          *ngSwitchCase="viewEnum.SIGN_IN"
          [attr.attr-data-autotestId]="'login_auth-container'"
          [pageName]="pageName"
          [errorMessage]="errorMessage"
          [isWeb]="isWeb"
          [formError$]="signInFormError$"
          [cmsContent$]="cmsContentService.cmsContent$"
          (loginData)="handleUserSignIn($event)"
          (formChanged)="handleSignInFormChanged()"
        >
        </hun-sign-in>

        <hun-main-auth-screen
          *ngSwitchCase="viewEnum.AUTH"
          [isTokenValid]="isTokenValid"
          [cmsContent$]="cmsContentService.cmsContent$"
          (loginWithEmail)="handleLoginWithEmail()"
          (googleUser)="handleLoginWithGoogle($event)"
          (facebookUser)="handleLoginWithFacebook($event)"
        >
        </hun-main-auth-screen>

        <hun-forgot-password
          *ngSwitchCase="viewEnum.FORGOT_PASSWORD"
          [isWeb]="isWeb"
          [formError$]="resetPasswordFormError$"
          [isSendingResetRequest]="isSendingResetRequest"
          [cmsContent$]="cmsContentService.cmsContent$"
          (email)="handleForgotPassword($event)"
          (onChangeTitle)="handleChangeTitle()"
        >
        </hun-forgot-password>

        <hun-set-new-password
          *ngSwitchCase="viewEnum.SET_NEW_PASSWORD"
          [isWeb]="isWeb"
          [isPasswordChanged]="isPasswordChanged"
          [isForgotPassword]="true"
          [changeButtonTitle]="cmsContentConfig['text_content']['forgot-password-reset-button']"
          [cmsContent$]="cmsContentService.cmsContent$"
          (newPassword)="handleSetNewPassword($event)"
        >
        </hun-set-new-password>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="isLoading" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>









<ng-container *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig">
  <div *ngIf="isPreviewMode" class="preview-line">
    <div class="botton-container">
      <button
        (click)="publishPreview()"
        class="admin-button"
        mat-flat-button
        color="primary"
        id="play-game">
        {{cmsContentConfig['text_content']['games-publish-button']}}
      </button>
    </div>
  </div>
  <div
    id="main-content-area"
    class="main-play-game-container"
    *ngIf="!isLoading"
    [ngClass]="{
  'desktop-style': isWeb | async, 'preview': isPreviewMode}">
    <div class="header-streak-game" *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig">
      <img
        class="game-background"
        [src]="cmsContentConfig['image_content']['streak-landing-header']">
      <mat-toolbar>
        <hun-back-button
          *ngIf="!(isWeb | async) && !isPreviewMode"
          [disable]="isPreviewMode"
          [customClickAction]="false"
          [opacityBackground]="true"
        >
        </hun-back-button>
      </mat-toolbar>
      <div class="game-details">
        <div class="game-name">{{streakGame.name}}</div>
        <div class="game-olay-details">
          <div class="first-line">
            <div class="number-of-players" *ngIf="streakGame.playersCount >= appData.MIN_PLAYER_COUNT">
              {{streakGame.playersCount}} {{cmsContentConfig['text_content']['streak-landing-players-label']}}&nbsp;|&nbsp;
            </div>
            <div class="game-open-date">
              {{streakGame.openDate| date: 'dd MMM YYYY'}}
            </div>
          </div>
          <div class="second-line">
            <hun-countdown-timer
              *ngIf="!isHideTimer()"
              [endDate]="streakGame.closeDate"
              [timerLabel]="true"
              [showSeconds]="false"
              [isShortView]="true"
              class="tag-chip">
            </hun-countdown-timer>
            <div class="closed-label" *ngIf="isHideTimer()">
              {{cmsContentConfig['text_content']['streak-landing-timer-close-label']}}
            </div>
            <div class="buttons-container">
              <button
                [disabled]="isPreviewMode"
                mat-flat-button
                class="regular-button small-button"
                (click)="onShowHistory()">
                {{cmsContentConfig['text_content']['streak-page-history-button']}}
              </button>
              <button
                [disabled]="isPreviewMode"
                class="regular-button secondary-button share-button"
                mat-flat-button
                (click)="openShareDialog()"
                color="primary">
                {{cmsContentConfig['text_content']['share-button']}}
                <mat-icon class="material-icons material-icons-outlined reply-icon">reply</mat-icon>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="winner-massage" *ngIf="isUserWinner">
      <div>{{cmsContentConfig['text_content']['streak-landing-winners-first-line-text']}}</div>
      <div>{{cmsContentConfig['text_content']['streak-landing-winners-second-line-text']}}
      </div>
    </div>
    <div
      class="rows-content-container"
      [ngClass]="{
    'small-screen': manyItems,
    'winner-screen': isUserWinner}">

      <div class="content" *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig">
        <div
          class="row"
          *ngFor="let row of rows, let j = index"
          [ngClass]="
      {'reverse-odd': !(this.rows.length % 2 ? !(j % 2) : j % 2)}">
          <div
            class="item"
            *ngFor="let item of row, let i = index"
            [ngClass]="{
        'is-small': isSmall | async,
        'current': item.isCurrent,
        'progressed': item.isProgressed,
        'is-max-progress': item.isMaxProgress,
        'empty': (row[i+1] && !row[i+1].day) || !item.day,
        'before': (row[i+1] && !row[i+1].day) && item.day,
        'last': j === 0 && i === row.length - 1}">
            <div class="icon-container" *ngIf="!item.isMaxProgress && !(item.isCurrent && streakGame.hasPlayedToday)">
              <div class="streak-number-circle">{{item.day}}</div>
            </div>
            <div class="icon-container" *ngIf="item.isMaxProgress">
              <mat-icon>check_circle</mat-icon>
            </div>
            <div class="icon-container timer" *ngIf="item.isCurrent && streakGame.hasPlayedToday">
              <mat-icon>timer</mat-icon>
            </div>
            <div
              *ngIf="item.day; else empty"
              class="day-item">
              <ng-container [ngSwitch]="item.prizeType">
                <ng-container *ngSwitchCase="'POINTS'">
                <span class="points" *ngIf="item.points; else noPrize">
                  <img
                    class='points-coin'
                    [src]="cmsContentConfig['image_content']['points-prize']">
                  {{item.points}} {{cmsContentConfig['text_content']['streak-points-label']}}
                </span>
                  <ng-template #noPrize>
                    <img
                      class='default-prize'
                      [src]="cmsContentConfig['image_content']['streak-day-no-prize']">
                  </ng-template>

                </ng-container>
                <ng-container *ngSwitchCase="'NFT'">
                  <div class="nft-prize" (click)="showNft(item.nftSourceImageUrl)">
                    <hun-image-with-loader
                      *ngIf="item.nftSourceImageUrl"
                      [imageSrc]="item.nftSourceImageUrl"
                      [overlayHeight]="'44px'"
                      [fixedOverleyWidth]="'44px'"
                      [spinnerSize]="20">
                    </hun-image-with-loader>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'SFT'">
                  <div class="nft-prize" (click)="showNft(item.nftSourceImageUrl)">
                    <hun-image-with-loader
                      *ngIf="item.nftSourceImageUrl"
                      [imageSrc]="item.nftSourceImageUrl"
                      [overlayHeight]="'44px'"
                      [fixedOverleyWidth]="'44px'"
                      [spinnerSize]="20">
                    </hun-image-with-loader>
                  </div>
                </ng-container>
              </ng-container>

            </div>
            <ng-template #empty>
              <div class="day-item empty">
              </div>
            </ng-template>
          </div>
        </div>
      </div>

    </div>


    <div class="play-game-button squiggly-background">
      <div class="squiggly-line">
        <div class="arrow-up" *ngFor="let _ of [].constructor(count + 2)"></div>
      </div>
      <div class="background-fill">
        <div class="game-description">
          {{streakGame.description}}
        </div>
      </div>
    </div>

    <div
      class="button-container" *ngIf="!isHideTimer()">
      <button
        [disabled]="(streakGame.type === RoundTypeEnum.VideoStreak && streakGame.hasPlayedToday) || isPreviewMode"
        class="play-game regular-button"
        (click)="onPlayGame()"
        mat-flat-button
        color="primary">
        {{streakGame.type === RoundTypeEnum.VideoStreak && streakGame.hasPlayedToday ?
        cmsContentConfig['text_content']['streak-landing-play-tomorrow-button'] :
        cmsContentConfig['text_content']['streak-landing-play-button']}}
      </button>
    </div>
  </div>
</ng-container>


<div *ngIf="isLoading" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>


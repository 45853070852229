import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from "../services/auth.service";

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,

  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.endsWith("refresh")) {
      if (request.url.includes('logout')) {
        const token = this.getRefreshToken();
        const authReq = request.clone({
            setHeaders: {
              Authorization: 'Bearer ' + token
            },
          });
        return next.handle(authReq);
      }
      if (request.headers.get('Authorization') !== null) {
        return next.handle(request);
      } else {
        const token = this.getCurrentUser();
        const authReq = token
          ? request.clone({
            setHeaders: {
              Authorization: 'Bearer ' + token
            },
          })
          : request;
        return next.handle(authReq);
      }
    } else {
      return next.handle(request)
    }

  }

  private getCurrentUser() {
    return this.authService.getCurrentUser();
  }

  private getRefreshToken() {
    return this.authService.getRefreshToken();
  }
}

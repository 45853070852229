export enum AuthViewEnum {
  SIGN_IN = "sign-in",
  FORGOT_PASSWORD = "forgotten-password",
  SET_NEW_PASSWORD="set-new-password",
  SIGN_UP = "sign-up",
  AUTH = "authentification",
  USER_ACTIVATE = "user-activate",
  RESTORE_PASSWORD = "restorePassword",
  AUTH_SIGN_IN = 'auth/sign-in',
  AUTH_SIGN_UP = 'auth/sign-up',
  AUTH_FORGOTTEN_PASSWORD = 'auth/forgotten-password',
}

import {
  Component,
  ElementRef, EventEmitter, HostListener,
  Input, OnDestroy,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import {fromEvent, Observable, Subject, Subscription} from "rxjs";
import {APP_ROUTES} from "../../../app.routes.config";
import {Router} from "@angular/router";
import {GameService} from "../../../core/services/game.service";
import {debounceTime, first, startWith, switchMap, take, takeUntil} from "rxjs/operators";
import {TrendingGameDetailsModel} from "../../../shared/models/TrendingGameDetail";
import {cloneDeep} from "lodash";
import {ProcessTextService} from "../../../core/services/process-text.service";
import {RoundTypeEnum} from "../../../shared/enums/roundTypeEnum";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {CmsContentService} from "../../../core/services/cms-content.service";
import {CMSContentConfigModel} from "../../../shared/models/CMSContentConfigModel";

@Component({
  selector: 'hun-tranding-games',
  templateUrl: './tranding-games.component.html',
  styleUrls: ['./tranding-games.component.scss']
})
export class TrandingGamesComponent implements OnInit, OnDestroy {

  @ViewChild('carusel', { read: ElementRef }) public carusel: ElementRef<any>;

  @ViewChild('carousel', {read: CdkVirtualScrollViewport}) public _carousel: CdkVirtualScrollViewport;

  @Output() loaded = new EventEmitter<boolean>();

  @Input() isWeb: Observable<boolean>;

  @Input() cmsContent$: Observable<CMSContentConfigModel>;

  subTitleFieldName = 'brandName';

  chipNameList = [
    {name: 'type', label: 'type'},
    {name: 'sport', label: 'sport'},
  ];

  trandingGamesList = [];

  titleFieldName = 'name';

  imageFieldName = 'nftSourceImageUrl';

  pageNumber = 0;

  isAllGamesLoaded = false;

  isLoaded = false;

  private unsubscribe$: Subject<void> = new Subject();

  private magnet$: Subscription;

  public activeElementIndex = 0;

  public scrollEnded = true;

  isCarouselSet = false;

  isShowCarousel: boolean;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.setMobileCarousel();
  }
  constructor(
    private router: Router,
    private gameService: GameService,
    private processTextService: ProcessTextService,
  ) { }

  ngOnInit(): void {
    this.fetchAllGames();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.magnet$) {
      this.magnet$.unsubscribe();
    }
  }

  scrollToLeft() {
    this.carusel.nativeElement.scrollLeft -= 232;
  }

  onScroll() {
    if (this.isAllGamesLoaded) return;
    this.fetchAllGames();
  }

  scrollToRight() {
    this.carusel.nativeElement.scrollLeft += 232;
  }

  handleSelectCard(card) {
    this.router.navigate([
      `${APP_ROUTES.GAME}/${card.id}`,
    ]);
  }

  fetchAllGames() {
    this.pageNumber += 1;
    this.gameService.getTrendingGame(this.pageNumber)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((games: { records: TrendingGameDetailsModel[], total: number }) => {
        this.loaded.emit(true);
        this.isLoaded = true;
        if (!games.records.length) return;
        this.prepareGameList(games.records);
        if (this.trandingGamesList.length >= games.total) this.isAllGamesLoaded = true;
        if (!this.isCarouselSet) this.setMobileCarousel();

      });
  }

  prepareGameList(gameList) {
    const newGamelist = gameList.map(game => {
      const newGame = cloneDeep(game)
      if (!newGame.brandImageUrl) {
        newGame.brandImageUrl = '../../../../../../assets/image/White-logo.png';
      }
      newGame.type = this.processTextService.capitalizeFirstLetter(game.type);
      newGame.sport = (game.sport && game.sport.length) && this.processTextService.capitalizeFirstLetter(game.sport[0]);

      return newGame;
    })
    this.trandingGamesList = [...this.trandingGamesList, ...newGamelist];
  }

  getChipListArray(item) {
    if (item.type.toUpperCase() === RoundTypeEnum.Sport) return this.chipNameList;
    return this.chipNameList.filter(chip => chip.label.toUpperCase() !== RoundTypeEnum.Sport);
  }

  setMobileCarousel() {
    this.isWeb
      .pipe(first())
      .subscribe(val => {
        setTimeout(() => {
          if (!val && this._carousel) {
            fromEvent(this._carousel.elementRef.nativeElement, 'touchstart').subscribe(() => (this.scrollEnded = false));
            this.isCarouselSet = true;
            const scrolled$ = fromEvent(this._carousel.elementRef.nativeElement, 'scroll');
            this.magnet$ = fromEvent(this._carousel.elementRef.nativeElement, 'touchend')
              .pipe(
                switchMap(() => scrolled$.pipe(startWith(null as Event), debounceTime(40), take(1))))
              .subscribe(() => {
                this.scrollEnded = true;
              });
            this._carousel.scrolledIndexChange.subscribe(val => {
              if (val === this.trandingGamesList.length - 4) {
                this.onScroll();
              }
            })
          }
        }, 100)
      })
  }

}

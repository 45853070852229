import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {SETTINGS_USER_NAVIGATION_CONFIG} from "../../../../../user-navigation.routing.config";
import {CurrentLocationService} from "../../../../../core/services/current-location.service";
import {CmsContentService} from "../../../../../core/services/cms-content.service";

@Component({
  selector: 'hun-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss']
})
export class ControlPanelComponent implements OnInit, OnChanges {

  @Input() userPts: number;

  @Input() userShortName: number;

  @Input() isWeb: Observable<boolean>;

  @Input() isLoggedIn: boolean;

  userNavigationConfig = SETTINGS_USER_NAVIGATION_CONFIG;

  homeIsNotActive: boolean;

  isDataLoaded = false;

  constructor(
    private router: Router,
    private currentLocationService: CurrentLocationService,
    public cmsContentService: CmsContentService,
  ) {
  }

  ngOnInit(): void {
    this.currentLocationService.getCurrentPage()
      .subscribe(response => {
        this.homeIsNotActive = !!response;
      });
  }

  isActive(path: string) {
    if (!path && this.router.url && this.router.url !== '/') return false;
    if (!this.router.url.includes(path)) return false;
    return this.router.isActive(path, false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.userPts && changes.userPts.currentValue !== undefined) {
      this.isDataLoaded = true;
    }
  }
}

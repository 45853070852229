export const APP_ROUTES = {
  HOME: '',
  AUTH: 'auth',
  PROFILE: 'profile-settings',
  PRIVACY_POLICY: 'privacy-policy',
  TERMS_OF_USE: 'terms-of-use',
  TERMS_OF_CONDITION: 'terms-and-conditions',
  BRAND:'brand',
  GAME:'game',
  STREAK:'streak',
  PLAYING_GAME:'playing',
  PLAYING_STREAK:'playing-streak',
  GAME_SUMMARY:'game-summary',
  STREAK_HISTORY:'streak-history'
};

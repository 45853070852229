import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'hun-image-with-loader',
  templateUrl: './image-with-loader.component.html',
  styleUrls: ['./image-with-loader.component.scss']
})
export class ImageWithLoaderComponent implements OnInit {

  @Input() imageSrc: string;

  @Input() overlayHeight: string;

  @Input() fixedOverleyWidth = '100%';

  @Input() spinnerSize: number;

  @Input() additionClass: string[];

  @Input() additionOverlayClass: string[];

  @Output() onLoaded = new EventEmitter<void>();

  isImageLoading = false;

  constructor() { }

  ngOnInit(): void {
  }

  onImageLoaded () {
    this.isImageLoading = true;
    this.onLoaded.emit();
  }

  getClassName () {
    const classes = {};
    if (this.additionClass) {
      this.additionClass.forEach((className) => {
        classes[className] = true;
      });
    }
    return classes;
  }

  getOverlayClassName() {
    const classes = {};
    if (this.additionOverlayClass) {
      this.additionOverlayClass.forEach((className) => {
        classes[className] = true;
      });
    }
    return classes;
  }

}

<div class="header-filter">
  <mat-chip-list selectable [multiple]="false">
    <mat-chip
      *ngFor="let filter of filterOption"
      [selected]="filter.state"
      (click)="selectFilter(filter)"
    >
      {{filter.label}}
    </mat-chip>
  </mat-chip-list>
</div>

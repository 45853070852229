import {Component, OnInit} from '@angular/core';
import {combineLatest, Observable, of, Subject} from "rxjs";
import {BreakpointObserverService} from "../../../core/breakpoint-observer/breakpoint-observer.service";
import {ProcessTextService} from "../../../core/services/process-text.service";
import {YourNftService} from "../../../core/services/your-nft.service";
import {filter, takeUntil, tap} from "rxjs/operators";
import {HOME_SCREEN_ROUTES} from "../../home-screen.routing.config";
import {NftScreenEnum} from "../../../shared/enums/nftScreenEnum";
import {GameService} from "../../../core/services/game.service";
import {AuthService} from "../../../core/services/auth.service";
import {LocalStorageService} from "../../../core/services/local-storage.service";
import {APP_DATA} from "../../../general.app.config";
import {cloneDeep} from "lodash";
import {RoundTypeEnum} from "../../../shared/enums/roundTypeEnum";
import {PlayedGameDetailModel} from "../../../shared/models/PlayedGame";
import {UserService} from "../../../core/services/user.service";
import {SaasSetingsService} from "../../../core/services/saas-setings.service";
import {SettingsConfigDetail} from "../../../shared/models/SettingsConfigDetail";
import {CmsContentService} from "../../../core/services/cms-content.service";
import {NftStatusEnum} from "../../../shared/enums/nftStatusEnum";
import {PagesWithFilterEnum} from "../../../shared/enums/pagesWithFilterEnum";
import {MetaService} from "../../../core/services/meta.service";

@Component({
  selector: 'hun-your-nfts',
  templateUrl: './your-nfts.component.html',
  styleUrls: ['./your-nfts.component.scss']
})
export class YourNftsComponent implements OnInit {

  isWeb: Observable<boolean>;

  isXSmall: Observable<boolean>;

  pageFilterOption: { state: boolean, label: string, name: string }[];

  isLoading = false;

  pageNumber = 0;

  nftList = [];

  gameList = [];

  HOME_SCREEN_ROUTES = HOME_SCREEN_ROUTES;

  isAllLoaded = false;

  selectedTab: NftScreenEnum;

  NftScreenEnum = NftScreenEnum;

  isLoggedIn: boolean;

  openedTippyId: number;

  userPts: number;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private breakpointObserverService: BreakpointObserverService,
    private processTextService: ProcessTextService,
    private yourNftService: YourNftService,
    private gameService: GameService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private saasSetingsService: SaasSetingsService,
    public cmsContentService: CmsContentService,
    private metaService: MetaService
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.metaService.setMetaTags(this.cmsContentService.cmsContent$.value['seo_content']['locker-screen']);

    this.saasSetingsService.settingConfigArray$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(config => console.log(config));
    this.isWeb = this.breakpointObserverService.isWeb;
    this.isXSmall = this.breakpointObserverService.isSmallScreen;

    this.pageFilterOption = this.processTextService.createFilter(PagesWithFilterEnum.locker);
    this.isLoading = false
    this.userService.getUserDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userDetails => {
        this.userPts = userDetails?.ptsAmount;
      })

    combineLatest([
      this.yourNftService.getSelectedFilter(),
      this.authService.getIsCurrentUser(),
    ])
      .pipe(
        filter(([selectedPeriod, user]) => !!selectedPeriod && this.selectedTab !== selectedPeriod),
        tap(([selectedPeriod, user]: [NftScreenEnum, any]) => {
          this.selectedTab = selectedPeriod;

          const latestSelectionFilter = this.localStorageService.getItem(APP_DATA.LATEST_SELECTION_FILTER);
          if (latestSelectionFilter) {
            this.selectedTab = latestSelectionFilter;
          }
          this.pageNumber = 0;
          this.isAllLoaded = false;
          this.isLoggedIn = user;
          if (!user) return of(null);

          if (this.selectedTab === NftScreenEnum.NFT) {
            return this.fetchNftsInfo();
          } else {
            return this.fetchYourGameInfo();
          }
        }),
        takeUntil(this.unsubscribe$))
      .subscribe(() => {
      })
  }

  onScroll() {
    if (!this.isLoggedIn) return;
    if (this.isAllLoaded) return;
    this.gameService.setIsLoadedStatus(false);
    if (this.selectedTab === NftScreenEnum.NFT) {
      return this.fetchNftsInfo(false);
    } else {
      return this.fetchYourGameInfo(false);
    }
  }

  fetchNftsInfo(isLoadFromScratch = true) {
    if (isLoadFromScratch) this.nftList = [];
    this.isLoading = true;
    this.pageNumber += 1;
    this.yourNftService.getUserNfts(this.pageNumber)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({records, total}) => {
        this.isLoading = false;
        this.nftList = [...this.nftList, ...records];

        if (this.nftList.length >= total) this.isAllLoaded = true;
      });
  }

  fetchYourGameInfo(isLoadFromScratch = true) {
    if (isLoadFromScratch) this.gameList = [];
    this.isLoading = true;
    this.pageNumber += 1;
    if (this.gameService.getIsLoadedStatus()) {
      this.gameService.getLoadedGameList()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((gameResponse) => {
          this.processGameList(gameResponse, false);
          const scrollPosition = this.gameService.getScrollPosition();
          if (scrollPosition) {
            setTimeout(() => {
              window.scroll(0, scrollPosition);
            }, 0);
            this.gameService.setScrollPosition(0);
          }
        })
    } else {
      this.gameService.getPlayedGame(this.pageNumber)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((gameResponse) => {
          this.processGameList(gameResponse, true);
        });
    }
  }

  processGameList({records, total}, isServerLoad) {
    this.isLoading = false;
    const newRecords = records.map((record: PlayedGameDetailModel) => {
      const newRecord = cloneDeep(record);

      if (record.type.toLowerCase().includes('streak')) {
        // @ts-ignore
        newRecord.type = 'Streak';
        // @ts-ignore
        newRecord.fullType = record.fullType || record.type;
        newRecord.backgroundImageDesktopUrl = record.backgroundImageDesktopUrl || '../../../../assets/image/only-logo.svg';
        newRecord.backgroundImageMobileUrl = record.backgroundImageMobileUrl || '../../../../assets/image/only-logo.svg';
      } else {
        newRecord.prizeImageUrl = record.prizeImageUrl || '../../../../assets/image/only-logo.svg';
      }
      return newRecord;
    })
    this.gameList = [...this.gameList, ...newRecords];
    this.localStorageService.removeItem(APP_DATA.LATEST_SELECTION_FILTER);
    if (this.gameList.length >= total) this.isAllLoaded = true;
    if (isServerLoad) this.gameService.setLoadedGameList({records: this.gameList, total});
  }

  onNftTranfered() {
    this.pageNumber = 0;
    this.fetchNftsInfo(true);
  }

  onTippyOpened(id) {
    this.openedTippyId = id;
  }
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {FormGroup, NonNullableFormBuilder, Validators} from "@angular/forms";
import {APP_DATA} from "../../../general.app.config";
import {Location} from "@angular/common";
import {takeUntil} from "rxjs/operators";
import {FormsService} from "../../../core/services/forms.service";
import {SignInFormType} from "../../../shared/FormsInterface/sign-in-form-interface";
import {CMSContentConfigModel} from "../../../shared/models/CMSContentConfigModel";

@Component({
  selector: 'hun-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {

  @Input() pageName: string;

  @Input() isWeb: Observable<boolean>;

  @Input() errorMessage: string;

  @Input() formError$: Subject<{ massage, field }>;

  @Input() cmsContent$: Observable<CMSContentConfigModel>;

  @Output() loginData = new EventEmitter<{ email: string, password: string }>();

  @Output() formChanged = new EventEmitter<void>();

  signInForm: FormGroup<SignInFormType>;

  appData = APP_DATA;

  isServerError = false;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private fb: NonNullableFormBuilder,
    private location: Location,
    private formsService: FormsService,
  ) {
    this.buildForm();

  }

  ngOnInit(): void {
    this.formError$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorObject) => {
        if (!errorObject) return;

        if (errorObject.massage) {
          const {massage, field} = errorObject;
          this.isServerError = true;
          this.formsService.bindErrors(massage, this.signInForm, field);
        } else {
          const {field} = errorObject;
          this.isServerError = false;
          this.formsService.bindErrors(null, this.signInForm, field);
        }
      });

    this.signInForm.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        if (this.isServerError) {
          this.formChanged.emit();
        }
      });
  }

  private buildForm(): void {
    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  onSignIn() {
    this.loginData.emit(this.signInForm.getRawValue());
  }

  back() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.formChanged.emit();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<div *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig" class="main-header-container" [ngClass]="{'desktop-style': isWeb | async}">
  <div class="header-container container" [ngClass]="{'desktop-style': isWeb | async}">
    <hun-tag-chip
      *ngIf="!(isWeb | async) && isLoggedIn"
      [chipValue]="userPts + ' ' + cmsContentConfig['text_content']['games(profile)-points-label']">
    </hun-tag-chip>
    <div [ngClass]="{'space': !(isWeb | async) && isLoggedIn}" class="logo">
      <a [routerLink]="'/'">
        <img src="../../../../../../assets/image/Logo.svg" alt="hunch" title="Go to Dashboard">
      </a>
    </div>
    <hun-control-panel
      *ngIf="isWeb | async"
      [isWeb]="isWeb"
      [userPts]="userPts"
      [isLoggedIn]="isLoggedIn">
    </hun-control-panel>
  </div>
  <div class="squiggly-line">
    <img>
  </div>
</div>

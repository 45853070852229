<ng-container *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig">
  <div class="profile-page-container" *ngIf="!showChangePassword" [ngClass]="{'desktop-style': isWeb | async}">
    <div class="user-personal-info">
      <div class="user-logo">
        <div *ngIf="!currentUser?.avatar?.url; else userAvatarTemplate"
             class="upload-image-button-container">
          <input
            #userAvatarImageInput
            hidden="true"
            type="file"
            onclick="this.value=null"
            (change)="uploadImage($event)"
            accept=".png, .jpg, .jpeg, .svg"/>
          <img [src]="cmsContentConfig['image_content']['default-user-avatar']" (click)="userAvatarImageInput.click()">
          <div class="hover" (click)="userAvatarImageInput.click()">
            <mat-icon>camera_alt</mat-icon>
          </div>
        </div>
        <ng-template #userAvatarTemplate>
          <hun-image-with-loader
            class="user-custom-avatar"
            [imageSrc]="currentUser?.avatar.url"
            [overlayHeight]="'80px'"
            [spinnerSize]="30"
          >
          </hun-image-with-loader>
          <div class="hover">
            <mat-icon (click)="deleteAvatar()">delete_outline</mat-icon>
          </div>
        </ng-template>
      </div>
      <hun-tag-chip [chipValue]="currentUser.ptsAmount + cmsContentConfig['text_content']['games(profile)-points-label']"></hun-tag-chip>
      <div class="register-date">{{cmsContentConfig['text_content']['profile-member-text']}} {{currentUser?.createdAt | date: 'MMM YYYY'}}</div>
      <form [formGroup]="userInfoForm">

        <mat-label>{{cmsContentConfig['text_content']['profile-first-name-label']}}</mat-label>
        <mat-form-field appearance="outline"
                        [ngClass]="{'additional-space': getError('firstName')}">
          <input
            matInput
            name="firstName"
            [formControlName]="'firstName'"/>
          <mat-error *ngIf="userInfoForm?.get('firstName').hasError('required')">
            <span> {{cmsContentConfig['text_content']['error-madnatory']}}</span>
          </mat-error>
          <mat-error
            *ngIf="!userInfoForm?.get('firstName').hasError('required') && userInfoForm?.get('firstName').hasError('maxlength')">
            <span> {{cmsContentConfig['text_content']['error-max-15-characters']}}</span>
          </mat-error>
          <mat-error
            *ngIf="!userInfoForm?.get('firstName').hasError('required') && userInfoForm?.get('firstName').hasError('minlength')">
            <span> {{appData.MIN_LENGHT_3}}</span>
          </mat-error>
          <mat-error
            *ngIf="!userInfoForm?.get('firstName').hasError('required') && userInfoForm?.get('firstName').hasError('pattern')">
            <span> {{cmsContentConfig['text_content']['error-dont-support-special-characters']}}</span>
          </mat-error>
        </mat-form-field>

        <mat-label>{{cmsContentConfig['text_content']['profile-last-name-label']}}</mat-label>
        <mat-form-field appearance="outline" [ngClass]="{'additional-space': getError('lastName')}">
          <input
            matInput
            name="lastName"
            [formControlName]="'lastName'"/>
          <mat-error *ngIf="userInfoForm?.get('lastName').hasError('required')">
            <span> {{cmsContentConfig['text_content']['error-madnatory']}}</span>
          </mat-error>
          <mat-error
            *ngIf="!userInfoForm?.get('lastName').hasError('required') && userInfoForm?.get('lastName').hasError('maxlength')">
            <span> {{cmsContentConfig['text_content']['error-max-15-characters']}}</span>
          </mat-error>
          <mat-error
            *ngIf="!userInfoForm?.get('lastName').hasError('required') && userInfoForm?.get('lastName').hasError('minlength')">
            <span> {{appData.MIN_LENGHT_3}}</span>
          </mat-error>
          <mat-error
            *ngIf="!userInfoForm?.get('lastName').hasError('required') && userInfoForm?.get('lastName').hasError('pattern')">
            <span> {{cmsContentConfig['text_content']['error-dont-support-special-characters']}}</span>
          </mat-error>
        </mat-form-field>

        <mat-label>{{cmsContentConfig['text_content']['profile-email-label']}}</mat-label>
        <mat-form-field appearance="outline" [floatLabel]="'always'">
          <input
            matInput
            required
            [formControlName]="'email'"/>
        </mat-form-field>
      </form>
    </div>
    <div class="buttons-container">
      <div class="buttons">
        <button
          [disabled]="!this.userInfoForm.valid"
          class="regular-button"
          mat-flat-button
          (click)="saveProfileChanges()"
          color="primary">
          {{cmsContentConfig['text_content']['profile-save-button']}}
        </button>

        <button
          class="regular-button  secondary-button"
          mat-flat-button
          (click)="onConnectMetamask()"
          color="primary">
         <span *ngIf="!isWalletInPoccess; else loader">
           {{isWalletConnect ? 'Disconnect Wallet'  : cmsContentConfig['text_content']['profile-wallet-button']}}
        </span>
          <ng-template #loader>
            <mat-spinner [diameter]="30" color="#fff"></mat-spinner>
          </ng-template>
          <span class="loader"></span>
        </button>

        <a routerLink="/profile-settings/set-new-password" *ngIf="this.currentUser?.loginType !== 'SOCIAL'">
          <button
            class="regular-button secondary-button"
            mat-flat-button>
            {{cmsContentConfig['text_content']['profile-password-button']}}
          </button>
        </a>

        <button
          (click)="onContactUs()"
          class="regular-button secondary-button"
          mat-flat-button>
          {{cmsContentConfig['text_content']['profile-contact-button']}}
        </button>

        <button
          (click)="onLogOut()"
          class="regular-button secondary-button"
          mat-flat-button>
          {{cmsContentConfig['text_content']['profile-log-out-button']}}
        </button>
      </div>

    </div>
    <div class="space" [ngClass]="{'desktop-style': isWeb | async}"></div>
  </div>


  <ng-container *ngIf="showChangePassword">
    <hun-set-new-password
      [cmsContent$]="cmsContentService.cmsContent$"
      [isWeb]="isWeb"
      [isForgotPassword]="false"
      [isPasswordChanged]="isPasswordChanged"
      [serverErrorMessage]="errorMassage"
      [changeButtonTitle]="cmsContentConfig['text_content']['change-password-change-button']"
      [formError$]="setNewPasswordFormError$"
      (formChanged)="handleSetNewPasswordFormChanged()"
      (onChangeOldPassword)="handleChangeOldPassword()"
      (newPassword)="handleSetNewPassword($event)">
    </hun-set-new-password>
  </ng-container>



</ng-container>

<div *ngIf="isLoading" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>

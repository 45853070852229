import {Component, Input, OnInit} from '@angular/core';
import {PlayedGameDetailModel} from "../../../../../shared/models/PlayedGame";
import {ProcessTextService} from "../../../../../core/services/process-text.service";
import {Router} from "@angular/router";
import {LocalStorageService} from "../../../../../core/services/local-storage.service";
import {NftScreenEnum} from "../../../../../shared/enums/nftScreenEnum";
import {APP_DATA} from "../../../../../general.app.config";
import {GameService} from "../../../../../core/services/game.service";
import {APP_ROUTES} from "../../../../../app.routes.config";
import {RoundTypeEnum} from "../../../../../shared/enums/roundTypeEnum";
import {Observable} from "rxjs";
import {CMSContentConfigModel} from "../../../../../shared/models/CMSContentConfigModel";

@Component({
  selector: 'hun-game-item',
  templateUrl: './game-item.component.html',
  styleUrls: ['./game-item.component.scss']
})
export class GameItemComponent implements OnInit {

  isScrolled = false;

  isLoading = false;

  appData = APP_DATA;

  RoundTypeEnum = RoundTypeEnum;

  @Input() game: PlayedGameDetailModel;

  @Input() isWeb: Observable<boolean>;

  @Input() cmsContent$: Observable<CMSContentConfigModel>;

  constructor(
    public processTextService: ProcessTextService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private gameService: GameService,
  ) {
  }

  ngOnInit(): void {
  }

  onSeeResult() {
    this.localStorageService.setItem(APP_DATA.LATEST_SELECTION_FILTER, NftScreenEnum.GAME);
    this.localStorageService.setItem(APP_DATA.LATEST_SELECTION_FILTER, NftScreenEnum.GAME);
    this.localStorageService.setItem(APP_DATA.FROM_RESULT, true);
    this.gameService.setScrollPosition(window.scrollY)
    this.gameService.setIsLoadedStatus(true);

    if (this.game.type === RoundTypeEnum.Streak) {
      this.router.navigate([
        `${APP_ROUTES.STREAK_HISTORY}/${this.game.id}`
      ],
        // @ts-ignore
        {queryParams: {type: this.game.fullType === RoundTypeEnum.VideoStreak ? 'video' : 'text'}});
    } else {
      this.router.navigate([
        `${APP_ROUTES.GAME}/${APP_ROUTES.PLAYING_GAME}/${this.game.id}`,
      ]);
    }
  }
}

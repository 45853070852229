import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse, HttpHeaders, HTTP_INTERCEPTORS
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';

import {catchError, filter, switchMap, take} from "rxjs/operators";
import {LocalStorageService} from "../services/local-storage.service";
import {AuthService} from "../services/auth.service";
import {SnackBarService} from "../services/snack-bar.service";
import {APP_ROUTES} from "../../app.routes.config";
import {Router} from "@angular/router";
import {CmsContentService} from "../services/cms-content.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private tokenService: LocalStorageService,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private router: Router,
    private cmsContentService: CmsContentService
  ) { }

  /**
   * Add headers and listener for 401 error to add refresh token
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    return next.handle(req).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401 && !req.url.includes('auth/local/confirm') && !req.url.includes('restore-password')) {
        if (!req.url.endsWith('refresh')) {
          return this.handle401Error(req, next, error);
        } else {
          this.authService.logOut().subscribe(() => {
            this.authService.setCurrentUserLoggedOut();
            this.authService.logoutSentry();
            this.authService.logOutLocalStorage();
            this.snackBarService.showSnackBar(this.cmsContentService.cmsContent$.value['text_content']['snack-bar-session-expired-text']);
            this.router.navigate(['/' + APP_ROUTES.AUTH]);
          });
          return throwError(error);
        }
      } else {
        return throwError(error);
      }
    }));
  }

  /**
   * Send refresh token of error response equal 401
   * @param request
   * @param next
   * @private
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler, error) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.tokenService.getRefreshToken();
      if (!token) {
        this.router.navigate(['/frame']);
        return throwError(error);
      }

      const authReq = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        },
      });

        return this.authService.refreshToken(authReq).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;

            this.tokenService.setToken(token.accessToken);
            this.tokenService.setRefreshToken(token.refreshToken);
            this.refreshTokenSubject.next(token);

            const authReq = request.clone({
              headers: request.headers.set(
                'Authorization',
                'Bearer ' + token.accessToken
              ),
            });

            return next.handle(authReq);
          }),
          catchError((err) => {
            this.isRefreshing = false;

            this.tokenService.clear();
            return throwError(err);
          })
        );

    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => {
        const authReq = request.clone({
          headers: request.headers.set(
            'Authorization',
            'Bearer ' + token.accessToken
          ),
        });
        return next.handle(authReq)
      })
    );
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];

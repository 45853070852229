import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogComponent} from "./dialog.component";
import {DialogModel} from "../../../../models/DialogModel";
import {filter, take, takeUntil} from "rxjs/operators";
import {DialogWithImageModel} from "../../../../models/DialogWithImageModel";
import {DialogWithImageComponent} from "./dialog-with-image/dialog-with-image.component";
import {ShareModalComponent} from "../share-modal/share-modal.component";
import {APP_ROUTES} from "../../../../../app.routes.config";
import {Router} from "@angular/router";
import {CmsContentService} from "../../../../../core/services/cms-content.service";

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  dialogRef: MatDialogRef<DialogComponent | DialogWithImageComponent>;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    public cmsContentService: CmsContentService
  ) {
  }

  open(dialogConfig: DialogModel) {
    const defaultConfig = new DialogModel;
    const config = {...defaultConfig, ...dialogConfig}
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: config,
    });
    return this.dialogRef.afterClosed()
      .pipe(
        take(1)
      );
  }

  openImage(dialogConfig: DialogWithImageModel) {
    const defaultConfig = new DialogWithImageModel;
    const config = {...defaultConfig, ...dialogConfig}
    this.dialogRef = this.dialog.open(DialogWithImageComponent, {
      data: config,
      panelClass: ['image-dialog']
    });
    return this.dialogRef.afterClosed()
      .pipe(
        take(1)
      );
  }

  openShareModal(name, url) {
    const dialogRef = this.dialog
      .open(ShareModalComponent, {
        panelClass: ['share-dialog'],
        enterAnimationDuration: '0'
      });
    const componentInstance = dialogRef.componentInstance;
    componentInstance.name = name;
    componentInstance.url = url;
    componentInstance.smallText = name.length > 32;
  }

  openNeedSignIn() {
    const defaultConfig = new DialogModel;
    const dialogConfig = {
      dialogTitle: this.cmsContentService.cmsContent$.value['text_content']['login-popup-header-text'],
      dialogContent: this.cmsContentService.cmsContent$.value['text_content']['login-popup-explain-text'],
      labelOk: this.cmsContentService.cmsContent$.value['text_content']['login-popup-stay-button'],
      labelNo: this.cmsContentService.cmsContent$.value['text_content']['login-popup-confirm-button']
    }

    const config = {...defaultConfig, ...dialogConfig}
    this.dialog.open(DialogComponent, {
      data: config,
    })
      .afterClosed()
      .pipe(
        filter(response => response))
      .subscribe(() => {
        this.router.navigate([
          `${APP_ROUTES.AUTH}`,
        ]);
      });
  }

}

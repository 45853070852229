<div *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig"
     class="control-panel-container"
     [ngClass]="{'desktop-style': isWeb | async}">
  <ul class="control-panel">
    <li class="control-item">
      <a [routerLink]="userNavigationConfig.GAMES.link" [routerLinkActive]="'active-link'"
         [ngClass]="{'disable-active': !isActive(userNavigationConfig.GAMES.link)}">
        <img [src]="isActive(userNavigationConfig.GAMES.link) ?
      '../../../../assets/image/menu-icon/active-menu-icon/' + userNavigationConfig.GAMES.icon + '.svg' :
      '../../../../assets/image/menu-icon/' + userNavigationConfig.GAMES.icon + '.svg'"
             width="24"/>
        <div class="menu-item-label">
          {{cmsContentConfig['text_content']['tabs-games-item']}}
        </div>
      </a>
    </li>

    <li class="control-item">
      <a [routerLink]="userNavigationConfig.LEADERBOARD.link" [routerLinkActive]="'active-link'">
        <img [src]="isActive(userNavigationConfig.LEADERBOARD.link) ?
      '../../../../assets/image/menu-icon/active-menu-icon/' + userNavigationConfig.LEADERBOARD.icon + '.svg' :
      '../../../../assets/image/menu-icon/' + userNavigationConfig.LEADERBOARD.icon + '.svg'"
             width="24"/>
        <div class="menu-item-label">
          {{cmsContentConfig['text_content']['tabs-leaderboard-item']}}
        </div>
      </a>
    </li>

    <li class="control-item">
      <a [routerLink]="userNavigationConfig.LOCKER.link" [routerLinkActive]="'active-link'">
        <img [src]="isActive(userNavigationConfig.LOCKER.link) ?
      '../../../../assets/image/menu-icon/active-menu-icon/' + userNavigationConfig.LOCKER.icon + '.svg' :
      '../../../../assets/image/menu-icon/' + userNavigationConfig.LOCKER.icon + '.svg'"
             width="24"/>
        <div class="menu-item-label">
          {{cmsContentConfig['text_content']['tabs-locker-item']}}
        </div>
      </a>
    </li>

    <li class="control-item logged-user" *ngIf="isLoggedIn; else loginTemplate">
      <a
        [routerLink]="userNavigationConfig.PROFILE.link"
        [routerLinkActive]="'active-link'"
        [routerLinkActiveOptions]="{ exact: true}"

      >
        <img [src]="isActive(userNavigationConfig.PROFILE.link) ?
      '../../../../assets/image/menu-icon/active-menu-icon/' + userNavigationConfig.PROFILE.icon + '.svg' :
      '../../../../assets/image/menu-icon/' + userNavigationConfig.PROFILE.icon + '.svg'"
             width="24"/>
        <div class="menu-item-label" *ngIf="(isWeb | async); else mobileTemplate">
          <span *ngIf="isDataLoaded">
            {{userPts}} {{cmsContentConfig['text_content']['games(profile)-points-label']}}
          </span>
          <mat-spinner *ngIf="!isDataLoaded" [diameter]="18" color="primary"></mat-spinner>

        </div>
        <ng-template #mobileTemplate>
          <div class="menu-item-label">
            {{userShortName}}
          </div>
        </ng-template>
      </a>
    </li>

    <ng-template #loginTemplate>
      <li class="control-item">
        <a [routerLink]="userNavigationConfig.LOG_IN.link" [routerLinkActive]="'active-link'">
          <img [src]="isActive(userNavigationConfig.LOG_IN.link) ?
      '../../../../assets/image/menu-icon/active-menu-icon/' + userNavigationConfig.LOG_IN.icon + '.svg' :
      '../../../../assets/image/menu-icon/' + userNavigationConfig.LOG_IN.icon + '.svg'"
               width="24"/>
          <div class="menu-item-label">
            {{cmsContentConfig['text_content']['tabs-log-in-item']}}
          </div>
        </a>
      </li>
    </ng-template>
  </ul>
</div>

import {UntypedFormGroup} from '@angular/forms';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  bindErrors(errors: any, form: UntypedFormGroup, fieldName: string) {
    if (!fieldName) return;

    const input = form.get(fieldName);
    if (!errors && fieldName)  {
      input.setErrors(null);
      return;
    }

    if (typeof errors === 'string') {
      input.setErrors({server: errors});
    } else {
      input.setErrors({server: errors.join(', ')});
    }
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {UriConfig} from "../../app.config";
import {LeaderBordDetailsModel, UserScoreModel} from "../../shared/models/LeaderboardsDetail";
import {BehaviorSubject, Observable} from "rxjs";
import {RequestLeaderboard} from "../../shared/enums/leaderbordPeriodEnum";

@Injectable({
  providedIn: 'root'
})
export class LeaderboardsService {

  private selectedFilter$ = new BehaviorSubject<string>(null);

  private userSoreInfo$ = new BehaviorSubject<UserScoreModel>(null);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }

  getLeaderboard (body): Observable<LeaderBordDetailsModel> {
    const {pageNumber, period, month} = body;
    const requestBody = {
      pageNumber,
      pageSize: 20,
      sortDirection: 'asc',
      period: RequestLeaderboard[period],
      month
    };
    if (!month) delete requestBody.month;
    let params = new HttpParams();
    Object.keys(requestBody).forEach(param => {
      params = params.set(param, requestBody[param]);
    });
    return this.http.get<LeaderBordDetailsModel>(
      this.uriConfig.leaderboard,
      { params }
    );
  }

  setSelectedFilter(filter) {
    this.selectedFilter$.next(filter);
  }

  getSelectedFilter() {
    return this.selectedFilter$;
  }

  setUserScoreInfo(userInfo) {
    this.userSoreInfo$.next(userInfo);
  }

  getUserScoreInfo() {
    return this.userSoreInfo$;
  }
}

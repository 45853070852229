import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'hun-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() buttonTitle: string;

  @Input() isLoading: boolean;

  @Input() disabled: boolean;

  @Output() clicked = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  isDisabled () : boolean {
    return this.isLoading || this.disabled;
  }

  onClickEvent () {
    this.clicked.emit(true);
  }

}

<mat-card (click)="onCardSelected(item)" *ngIf="cmsContent$ | async as cmsContentConfig">
  <hun-countdown-timer
    *ngIf="showTimer"
    [endDate]="item.closeDate"
    [timerLabel]="timerLabel"
    [isShortView]="true"
    class="tag-chip">
  </hun-countdown-timer>
  <hun-image-with-loader
    mat-card-image
    [imageSrc]="item[imageName] || cmsContentConfig['image_content'][cmsImageName]"
    [overlayHeight]="!isStreak && showButton ? '220px' : '200px'"
    [spinnerSize]="40"
    [additionClass]="isSquareImage ? ['squareImage'] : []"
  >
  </hun-image-with-loader>
  <div *ngIf="topTagsPosition" class="tags top">
    <mat-chip-list>
      <mat-chip *ngFor="let chip of filterChips(chipListName)" [ngClass]="getStyle(chip.label)">
        <hun-image-with-loader
          [overlayHeight]="'32px'"
          [fixedOverleyWidth]="'32px'"
          [spinnerSize]="20"
          [additionOverlayClass]="['brand']"
          *ngIf="chip.isImage"
          [imageSrc]="item[chip.label + 'ImageUrl'] || cmsContentConfig['image_content']['default-brand-logo']"
          matChipAvatar>
        </hun-image-with-loader>
        {{chip.isImage ? item[chip.label+'Name'] : getItemName(item[chip.label])}}
      </mat-chip>
    </mat-chip-list>
  </div>
  <mat-card-header [ngClass]="{'two-title-header': !subTitleField}">
    <mat-card-title class="regular-font-style" [ngClass]="{'long-title': isTrending}">{{item[titleField]}}</mat-card-title>
    <mat-card-subtitle *ngIf="subTitleField">{{item[subTitleField]}}</mat-card-subtitle>
  </mat-card-header>
  <div *ngIf="!topTagsPosition && chipListName" class="tags">
    <mat-chip-list>
      <mat-chip *ngFor="let chip of chipListName" [ngClass]="getStyle(chip.label)">
        {{getItemName(item[chip.name])}}
      </mat-chip>
    </mat-chip-list>
  </div>
  <div class="botton-container">
    <button
      class="regular-button"
      mat-flat-button
      color="primary"
      id="play-game"
      *ngIf="showButton">
      {{buttonText.toUpperCase()}}
    </button>
  </div>

</mat-card>

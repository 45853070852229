<ng-container *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig">
  <button
    *ngIf="!isExitButton; else exitButtonTemplate"
    mat-flat-button
    [ngClass]="{'opacity': opacityBackground}"
    [style.color]="buttonTextColor"
    [style.text-shadow]="buttonTextShadow"
    class="go-back-button"
    (click)="goBack()">
    <span class="icon">< </span>
    <span>{{cmsContentConfig['text_content']['all-pages-back-button']}}</span>
  </button>
  <ng-template #exitButtonTemplate>
    <button
      mat-flat-button
      [ngClass]="{'opacity': opacityBackground}"
      [style.color]="buttonTextColor"
      [style.text-shadow]="buttonTextShadow"
      class="go-back-button"
      (click)="goBack()">
      <mat-icon class="exit-icon">close</mat-icon>
      <span>{{cmsContentConfig['text_content']['games-pages-exit-button']}}</span>
    </button>
  </ng-template>
</ng-container>

import {Component, HostListener, Input} from '@angular/core';
import {SnackBarService} from "../../../../../core/services/snack-bar.service";
import {BreakpointObserverService} from "../../../../../core/breakpoint-observer/breakpoint-observer.service";

@Component({
  selector: 'hun-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent {

  @Input() name: string;

  @Input() url: string;

  @Input() smallText: boolean;

  isWeb = this.breakpointObserverService.isWeb;

  @HostListener('document:click', ['$event'])
  onClick(target: any) {
    if (target.delegateTarget.activeElement.ariaLabel === 'Copy link') {
      this.snackBarService.showSnackBar('URL of the game has been copied to the clipboard')
    }
  }

  constructor(
    private snackBarService: SnackBarService,
    private breakpointObserverService: BreakpointObserverService,
  ) {
  }
}

import {Component, OnDestroy, OnInit,} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {FormGroup, NonNullableFormBuilder, Validators} from "@angular/forms";
import {APP_DATA} from "../../../general.app.config";
import {BreakpointObserverService} from "../../../core/breakpoint-observer/breakpoint-observer.service";
import {AuthService} from "../../../core/services/auth.service";
import {SnackBarService} from "../../../core/services/snack-bar.service";
import {filter, takeUntil} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {UserService} from "../../../core/services/user.service";
import {UserDetailsModel} from "../../../shared/models/UserDetail";
import {FileService} from "../../../core/services/file.service";
import {APP_ROUTES} from "../../../app.routes.config";
import {ProfileFormType} from "../../../shared/FormsInterface/profile-form-interface";
import {UserLoginEnum} from "../../../shared/enums/userLoginEnum";
import {DialogService} from "../../../shared/modules/common-control-components/components/dialog/dialog.service";
import Web3 from "web3";
import {LocalStorageService} from "../../../core/services/local-storage.service";
import {Magic} from "magic-sdk";
import {ConnectExtension} from "@magic-ext/connect";
import {SaasSetingsService} from "../../../core/services/saas-setings.service";
import {SettingsConfigDetail} from "../../../shared/models/SettingsConfigDetail";
import {CmsContentService} from "../../../core/services/cms-content.service";
import {MetaService} from "../../../core/services/meta.service";


declare const google: any;
declare const FB: any;


@Component({
  selector: 'hun-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  magic = new Magic("pk_live_72883081F4705496", {
    network: "goerli",
    locale: "en_US",
    extensions: [new ConnectExtension()]
  } as any);
  // @ts-ignore
  web3 = new Web3(this.magic.rpcProvider);

  showChangePassword = false;

  provider: any

  isWeb: Observable<boolean>;

  isLarge: Observable<boolean>;

  isPasswordChanged = true;

  userInfoForm: FormGroup<ProfileFormType>;

  appData = APP_DATA;

  errorMassage: string;

  currentUser: UserDetailsModel;

  isLoading = false;

  loginType: UserLoginEnum;

  isWalletInPoccess = false;

  isWalletConnect = true;

  walletconnectProvider: any;

  setNewPasswordFormError$ = new BehaviorSubject<{ massage, field }>(null);

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private location: Location,
    private breakpointObserverService: BreakpointObserverService,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private fileService: FileService,
    private router: Router,
    private dialogService: DialogService,
    private localStorageService: LocalStorageService,
    private saasSetingsService: SaasSetingsService,
    public cmsContentService: CmsContentService,
    private metaService: MetaService
  ) {
  }

  ngOnInit(): void {
    this.metaService.setMetaTags(this.cmsContentService.cmsContent$.value['seo_content']['profile-screen']);

    this.saasSetingsService.settingConfigArray$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(config => console.log(config));
    this.localStorageService.removeItem(APP_DATA.LATEST_SELECTION_FILTER)
    this.buildForm();
    this.isLoading = true;
    this.authService.getCurrentLoginType()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(type => this.loginType = type);

    this.userService.getCurrentUser()
      .subscribe(
        (user: UserDetailsModel) => {
          this.isLoading = false;
          this.currentUser = user;
          this.isWalletConnect = !!user.metaMaskWalletAddress;
          this.fillForm();
        },
        (error) => {
          this.isLoading = false;
        })
    this.isWeb = this.breakpointObserverService.isWeb;
    this.isLarge = this.breakpointObserverService.isLargeScreen;

    this.route.url
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((url: any) => {
        if (url[1] && url[1].path === 'set-new-password') this.showChangePassword = true;
      });
  }

  onConnectMetamask() {
    this.isWalletInPoccess = true;
    if (this.currentUser.metaMaskWalletAddress) {
      // @ts-ignore
      this.magic.connect.disconnect()
        .then(() => {})
        .catch(() => {});
      this.updateUserMetamaskWalletData('');
      return;
    }

    this.web3.eth.getAccounts()
      .then(account => {
        this.updateUserMetamaskWalletData(account[0]);

      })
      .catch(err => {
        if (err.code === -32600) {
          this.snackBarService.showSnackBar(this.cmsContentService.cmsContent$.value['text_content']['snack-bar-install-metamask-text']);
        }
        this.isWalletInPoccess = false;
      })

    // const signedMessage = await web3.eth.personal
    //   .sign("My Message", publicAddress, "")
    //   .catch((e) => console.log(e));

    // this.isWalletInPoccess = true;

    // @ts-ignore
    // if (window.ethereum) {
    //   // @ts-ignore
    //   window.ethereum.request({method: 'eth_requestAccounts'}).then(
    //     walletToken => {
    //       this.updateUserMetamaskWalletData(walletToken[0]);
    //     },
    //     err => {
    //       this.snackBarService.showSnackBar(err.message);
    //       this.isWalletInPoccess = false;
    //     });
    // } else {
    //   window.open("https://metamask.app.link/dapp/playhunch.io/");
    //   this.isWalletInPoccess = false;
    // }
  }

  handleSetNewPasswordFormChanged() {
    this.setNewPasswordFormError$.next({massage: null, field: 'oldPassword'});
  }

  updateUserMetamaskWalletData(walletToken) {
    const userData = { metaMaskWalletAddress: walletToken}

    this.userService.saveUserData(userData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
          this.currentUser.metaMaskWalletAddress = response.metaMaskWalletAddress;
          this.isWalletInPoccess = false;
          this.isWalletConnect = !!response.metaMaskWalletAddress;
          this.snackBarService.showSnackBar(this.cmsContentService.cmsContent$.value['text_content']['snack-bar-profile-changed-saved-text'])
        },
        error => {
          this.snackBarService.showSnackBar(error.error.message);
          this.isWalletInPoccess = false;
        });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleSetNewPassword(password) {
    this.isPasswordChanged = false;
    const passwordBody: ChangePassword = {...new ChangePassword, ...password};

    this.authService.changePassword(passwordBody)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.showChangePassword = false;
          this.isPasswordChanged = true;
          this.snackBarService.showSnackBar(this.cmsContentService.cmsContent$.value['text_content']['snack-bar-password-change-text']);
          this.router.navigate([APP_ROUTES.PROFILE]);
        },
        (error) => {
          const massage = error.error.message;
          this.errorMassage = massage;
          this.setNewPasswordFormError$.next({massage, field: 'oldPassword'});
        });
  }

  private buildForm(): void {
    this.userInfoForm = this.fb.group({
      firstName: ['',
        [Validators.required,
          Validators.minLength(3),
          Validators.maxLength(15),
          Validators.pattern("^[a-zA-Z-]*$")]
      ],
      lastName: ['',
        [Validators.required,
        Validators.minLength(3),
        Validators.maxLength(15),
        Validators.pattern("^[a-zA-Z-]*$")]
      ],
      email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
      avatarId: [null],
      metaMaskWalletAddress: [null]
    });
  }

  onContactUs() {
    const link = "mailto:support@playhunch.io";

    window.location.href = link;
  }

  onLogOut() {
    this.dialogService.open({
      dialogTitle: this.cmsContentService.cmsContent$.value['text_content']['logout-popup-header-text'],
      dialogContent: this.cmsContentService.cmsContent$.value['text_content']['logout-popup-explain-text'],
      labelOk: this.cmsContentService.cmsContent$.value['text_content']['logout-popup-stay-button'],
      labelNo: this.cmsContentService.cmsContent$.value['text_content']['logout-popup-confirm-button']
    }).pipe(
      filter(response => response),
      takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.authService.logOut().subscribe(() => {

            if (this.loginType === UserLoginEnum.facebook) {
              FB.logout(function (response) {
              });
            }

            this.authService.setCurrentUserLoggedOut();
            this.authService.logoutSentry();
            this.authService.logOutLocalStorage();
            this.router.navigate(['/' + APP_ROUTES.AUTH]);
          },
          err => {
          });
      })
  }

  uploadImage(event) {
    this.isLoading = true;
    this.uploadFile(event.target.files[0]);
  }

  uploadFile(file) {
    let formdata = new FormData();
    formdata.append("file", file, file.name);
    this.fileService.uploadFile(formdata)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(uploadedFile => {
          this.isLoading = false;
          this.currentUser.avatar = uploadedFile;
        },
        error => {
          this.isLoading = false;
          this.snackBarService.showSnackBar(error.error.message);
        });
  }

  deleteAvatar() {
    this.currentUser.avatar = {id: null, url: ''};
  }

  saveProfileChanges() {
    if (!this.userInfoForm.valid) return;
    this.userInfoForm.patchValue({avatarId: this.currentUser.avatar?.id});
    const userData = this.userInfoForm.value;
    userData.email = this.userInfoForm.get('email').value;

    this.userService.saveUserData(userData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userData) => {
          this.snackBarService.showSnackBar(this.cmsContentService.cmsContent$.value['text_content']['snack-bar-profile-details-saved-text']);
          this.userService.setUserDetails(userData);
        },
        error => {
          this.snackBarService.showSnackBar(error.error.message);
        });
  }

  fillForm() {
    this.userInfoForm.patchValue({firstName: this.currentUser.firstName});
    this.userInfoForm.patchValue({lastName: this.currentUser.lastName});
    this.userInfoForm.patchValue({email: this.currentUser.email});
    if (this.currentUser.avatar) {
      this.userInfoForm.patchValue({avatarId: this.currentUser.avatar.id});
    }
  }

  handleChangeOldPassword() {
    this.errorMassage = ''
  }

  getError(name) {
    if (!this.userInfoForm.get(name)) return false;
    if (!this.userInfoForm.get(name).errors) return false;
    const errorsLen = Object.keys(this.userInfoForm.get(name).errors).length;
    return this.userInfoForm.get(name).touched && errorsLen > 1;
  }
}

export class ChangePassword {
  password: string
  oldPassword: string
}

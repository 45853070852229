import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogModel} from "../../../../models/DialogModel";
import {FocusMonitor} from "@angular/cdk/a11y";

@Component({
  selector: 'hun-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, AfterViewInit {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModel,
    private _focusMonitor: FocusMonitor
  ) { }

  ngOnInit(): void {
  }

  closeDialog () {
    this.dialogRef.close(true);
  }

  ngAfterViewInit(): void {
    this._focusMonitor.stopMonitoring(document.getElementById('stayButton'));
  }
}

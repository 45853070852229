import {Injectable, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BreakpointObserverService implements OnInit{

  private isXSmall$: Observable<boolean>;
  private isSmall$: Observable<boolean>;
  private isMedium$: Observable<boolean>;
  private isLarge$: Observable<boolean>;
  private isXLarge$: Observable<boolean>;
  private isHandset$: Observable<boolean>;
  private isTablet$: Observable<boolean>;
  private isWeb$: Observable<boolean>;
  private isPortrait$: Observable<boolean>;
  private isLandscape$: Observable<boolean>;
  private isSmallScreen$: Observable<boolean>;
  private isXSmallScreen$: Observable<boolean>;
  private isMediumScreen$: Observable<boolean>;
  private isXMediumScreen$: Observable<boolean>;
  private isLargeScreen$: Observable<boolean>;
  private isXLargeScreen$: Observable<boolean>;
  private isXLargeHeight$: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointChanged();
  }

  ngOnInit(): void {
  }

  get isXSmall () {
    return this.isXSmall$;
  }

  get isSmall () {
    return this.isSmall$;
  }

  get isMedium () {
    return this.isMedium$;
  }

  get isLarge () {
    return this.isLarge$;
  }

  get isXLarge () {
    return this.isXLarge$;
  }

  get isHandset () {
    return this.isHandset$;
  }

  get isTablet () {
    return this.isTablet$;
  }

  get isWeb () {
    return this.isWeb$;
  }

  get isPortrait () {
    return this.isPortrait$;
  }

  get isLandscape () {
    return this.isLandscape$;
  }

  get isSmallScreen () {
    return this.isSmallScreen$;
  }

  get isXSmallScreen () {
    return this.isXSmallScreen$;
  }

  get isMediumScreen () {
    return this.isMediumScreen$;
  }

  get isXMediumScreen () {
    return this.isXMediumScreen$;
  }

  get isLargeScreen () {
    return this.isLargeScreen$;
  }

  get isXLargeScreen () {
    return this.isXLargeScreen$;
  }

  get isXLargeHeight () {
    return this.isXLargeHeight$;
  }

  private breakpointChanged() {
    this.isLargeScreen$ =  this.breakpointObserver.observe('(max-width: 1510px)')
      .pipe(
        map(({matches}) => matches),
      );

    this.isXLargeScreen$ =  this.breakpointObserver.observe('(min-width: 1300px)')
      .pipe(
        map(({matches}) => matches),
      );

    this.isXLargeHeight$ =  this.breakpointObserver.observe('(min-height: 1020px)')
      .pipe(
        map(({matches}) => matches),
      );

    this.isSmallScreen$ =  this.breakpointObserver.observe('(max-width: 750px)')
      .pipe(
        map(({matches}) => matches),
      );

    this.isMediumScreen$ =  this.breakpointObserver.observe('(max-width: 1075px)')
      .pipe(
        map(({matches}) => matches),
      );

    this.isXMediumScreen$ =  this.breakpointObserver.observe('(min-width: 900px)')
      .pipe(
        map(({matches}) => matches),
      );

    this.isXSmallScreen$ =  this.breakpointObserver.observe(['(max-width: 400px)'])
      .pipe(
        map(({matches}) => matches),
      );

    this.isSmall$ = this.breakpointObserver
      .observe([Breakpoints.Small])
      .pipe(
        map(({matches}) => matches),
      );
    this.isXSmall$ = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(
        map(({matches}) => matches),
      );

    this.isMedium$ = this.breakpointObserver
      .observe([Breakpoints.Medium])
      .pipe(
        map(({matches}) => matches));

    this.isLarge$ = this.breakpointObserver
      .observe([Breakpoints.Large])
      .pipe(
        map(({matches}) => matches),
      );

    this.isXLarge$ = this.breakpointObserver
      .observe([Breakpoints.XLarge])
      .pipe(
        map(({matches}) => matches),
      );

    this.isHandset$ = this.breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait])
      .pipe(
        map(({matches}) => matches),
      );

    this.isTablet$ = this.breakpointObserver
      .observe([Breakpoints.Tablet, Breakpoints.TabletLandscape])
      .pipe(
        map(({matches}) => matches));

    this.isWeb$ = this.breakpointObserver.observe(['(min-width: 1100px)'])
      .pipe(
        map(({matches}) => matches),
      );

    this.isPortrait$ = this.breakpointObserver
      .observe([Breakpoints.TabletPortrait, Breakpoints.HandsetPortrait, Breakpoints.WebPortrait])
      .pipe(
        map(({matches}) => matches),
      );

    this.isLandscape$ = this.breakpointObserver
      .observe([Breakpoints.WebLandscape, Breakpoints.HandsetLandscape, Breakpoints.TabletLandscape])
      .pipe(
        map(({matches}) => matches),
      );
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {interval, Subscription} from "rxjs";

@Component({
  selector: 'hun-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit {

  private subscription: Subscription;

  @Input() endDate: any;

  @Input() isExpired: boolean;

  @Input() isShortView: boolean;

  @Input() addDotsBetween: boolean;

  @Input() showDescriptionBelow: boolean;

  @Input() showSeconds: boolean;

  @Input() addZero: boolean;

  @Input() timerLabel: boolean;

  @Input() label: string;

  isDateSet = false;

  public dDay: Date;

  milliSecondsInASecond = 1000;

  hoursInADay = 24;

  minutesInAnHour = 60;

  SecondsInAMinute = 60;

  dayInWeek = 7;

  public timeDifference;

  public secondsToDday;

  public minutesToDday;

  public hoursToDday;

  public daysToDday;

  public weeksToDday;

  weeksLabel = 'w';

  daysLabel = 'd';

  hoursLabel = 'h';

  minutesLabel = 'm';

  secondLabel = 's';

  constructor() {
  }

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits(timeDifference) {
    let milliSecondsLeft = timeDifference;

    if (!this.endDate) {
      this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
      this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
      this.isDateSet = true;
      return;
    }

    const milliSecondsInWeek = (this.milliSecondsInASecond * this.SecondsInAMinute * this.minutesInAnHour * this.hoursInADay * this.dayInWeek);
    const milliSecondsInDay = (this.milliSecondsInASecond * this.SecondsInAMinute * this.minutesInAnHour * this.hoursInADay);
    const milliSecondsInHour = (this.milliSecondsInASecond * this.SecondsInAMinute * this.minutesInAnHour);
    const milliSecondsInMinute = (this.milliSecondsInASecond * this.SecondsInAMinute);

    if (this.isShortView) {
      this.daysToDday = Math.floor((milliSecondsLeft) / milliSecondsInDay);
      milliSecondsLeft = this.daysToDday ? milliSecondsLeft - (this.daysToDday * milliSecondsInDay) : milliSecondsLeft;
      this.hoursToDday = Math.floor((milliSecondsLeft) / milliSecondsInHour);
      milliSecondsLeft = this.hoursToDday ? milliSecondsLeft - (this.hoursToDday * milliSecondsInHour) : milliSecondsLeft;

      if (!this.daysToDday) {
        this.minutesToDday = Math.floor((milliSecondsLeft) / milliSecondsInMinute);
      }

    } else {
      this.weeksToDday = Math.floor(timeDifference / milliSecondsInWeek);
      milliSecondsLeft = this.weeksToDday ? milliSecondsLeft - (this.weeksToDday * milliSecondsInWeek) : milliSecondsLeft;

      this.daysToDday = Math.floor((milliSecondsLeft) / milliSecondsInDay);
      milliSecondsLeft = this.daysToDday ? milliSecondsLeft - (this.daysToDday * milliSecondsInDay) : milliSecondsLeft;

      this.hoursToDday = Math.floor((milliSecondsLeft) / milliSecondsInHour);
      milliSecondsLeft = this.hoursToDday ? milliSecondsLeft - (this.hoursToDday * milliSecondsInHour) : milliSecondsLeft;

      this.minutesToDday = Math.floor((milliSecondsLeft) / milliSecondsInMinute);
      milliSecondsLeft = this.minutesToDday ? milliSecondsLeft - (this.minutesToDday * milliSecondsInMinute) : milliSecondsLeft;

      this.secondsToDday = Math.floor((milliSecondsLeft) / this.milliSecondsInASecond);
    }

    if (this.addZero) {
      this.weeksToDday = this.weeksToDday && this.weeksToDday < 10 ? '0' + this.weeksToDday.toString() : this.weeksToDday;
      this.daysToDday = this.daysToDday && this.daysToDday < 10 ? '0' + this.daysToDday.toString() : this.daysToDday;
      this.hoursToDday = this.hoursToDday && this.hoursToDday < 10 ? '0' + this.hoursToDday.toString() : this.hoursToDday;
      this.minutesToDday = this.minutesToDday && this.minutesToDday < 10 ? '0' + this.minutesToDday.toString() : this.minutesToDday;
      this.secondsToDday = this.secondsToDday && this.secondsToDday < 10 ? '0' + this.secondsToDday.toString() : this.secondsToDday;
    }

    this.isDateSet = true;
  }

  ngOnInit() {
    if (this.showDescriptionBelow) {
      this.weeksLabel = 'weeks'
      this.daysLabel = 'days'
      this.hoursLabel = 'hours'
      this.minutesLabel = 'minutes'
      this.secondLabel = 'seconds'
    }
    let date = new Date();
    this.dDay = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds());
    //TODO remove after implement tranding games
    if (this.endDate) {
      this.dDay = new Date(this.endDate);
    }
    this.subscription = interval(1000)
      .subscribe(x => {
        this.getTimeDifference();
      });
  }
}

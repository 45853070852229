// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.staging.ts`.
// The list of file replacements can be found in `angular.json`.

import {api} from "../assets/config/api.config";

export const environment = {
  production: false,
  name: 'local',
  intercomId: 'uhbiiqsy',
  apiUrl: api.dev,
  sentryDsn: '',
  googleId: '290397902038-99mfi7kc0s55beqbsfejt1euurq3lkvn.apps.googleusercontent.com',
  facebookId: '1177230173206336'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

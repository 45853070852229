import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {UriConfig} from "../../app.config";
import {LiveGameDetailsModel} from "../../shared/models/LiveGameDetail";
import {TrendingGameDetailsModel} from "../../shared/models/TrendingGameDetail";
import {GameDetailModel} from "../../shared/models/Game";
import {PlayedGameDetailModel} from "../../shared/models/PlayedGame";
import {SubmittedAnswersDetailsModel} from "../../shared/models/SubmittedAnswersDetail";
import {StreakGameDetailModel} from "../../shared/models/StreakGame";
import {StreakDetailModel} from "../../shared/models/Streak";

@Injectable({
  providedIn: 'root'
})
export class GameService {

  isEmptyList$ = new BehaviorSubject<boolean>(true);

  loadedGameList$ = new BehaviorSubject<{records: LiveGameDetailsModel[], total: number}>(null);

  private isLoadedList = false;

  private scrollPosition: number;

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
  ) { }

  getLiveGames(body): Observable<{records: LiveGameDetailsModel[], total: number }> {
    const {pageNumber, filterOption, showOnlyFollowingBrands, brandId, type} = body
    const requestBody = {
      pageNumber,
      pageSize: 10,
      sortDirection: 'asc',
      sortField: 'name',
      showOnlyFollowingBrands,
      brandId
    };
    if (type) {
      //@ts-ignore
      requestBody.type = type;
    }

    if (filterOption && filterOption.length) {
      //@ts-ignore
      requestBody.sports = filterOption;
    }

    if (!requestBody.brandId) delete requestBody.brandId;

    let params = new HttpParams();
    Object.keys(requestBody).forEach(param => {
      params = params.set(param, requestBody[param]);
    });
    return this.http.get<{records: LiveGameDetailsModel[], total: number }>(
      this.uriConfig.rounds + '/live',
      { params }
    );
  }

  getTrendingGame(pageNumber): Observable<{records: TrendingGameDetailsModel[], total: number }> {
    const requestBody = {pageNumber, pageSize: 10, sortDirection: 'asc', sortField: 'name'};

    let params = new HttpParams();
    Object.keys(requestBody).forEach(param => {
      params = params.set(param, requestBody[param]);
    });

    return this.http.get<{records: TrendingGameDetailsModel[], total: number }>(
      this.uriConfig.rounds + '/trending',
      { params }
    );
  }

  getPlayedGame(pageNumber): Observable<{records: PlayedGameDetailModel[], total: number }> {
    const requestBody = {pageNumber, pageSize: 20, sortDirection: 'asc'};

    let params = new HttpParams();
    Object.keys(requestBody).forEach(param => {
      params = params.set(param, requestBody[param]);
    });

    return this.http.get<{records: PlayedGameDetailModel[], total: number }>(
      this.uriConfig.rounds + '/played',
      { params }
    );
  }

  getSportTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.uriConfig.rounds+ '/sports-types');
  }

  getRoundHistory(roundId): Observable<SubmittedAnswersDetailsModel>{
    return this.http.get<SubmittedAnswersDetailsModel>(
      this.uriConfig.rounds + '/' + roundId + '/history');
  }

  getGameById(gameId): Observable<GameDetailModel> {
    return this.http.get<GameDetailModel>(this.uriConfig.rounds + '/' + gameId + '/details');
  }

  getPreviewGame(uniqueKey, accessToken) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);
    const options = { headers };
    return this.http.get<GameDetailModel | StreakGameDetailModel>(this.uriConfig.rounds + '/preview/' + uniqueKey, options);
  }

  getStreakRounds(pageNumber) {
    const requestBody = {pageNumber, pageSize: 20};

    let params = new HttpParams();
    Object.keys(requestBody).forEach(param => {
      params = params.set(param, requestBody[param]);
    });
    return this.http.get<any>(
      this.uriConfig.rounds + '/streak', { params });
  }

  setListEmptyStatus(isEmpty) {
    this.isEmptyList$.next(isEmpty);
  }

  getListEmptyStatus() {
    return this.isEmptyList$;
  }

  setLoadedGameList(gameList) {
    this.loadedGameList$.next(gameList);
  }

  getLoadedGameList() {
    return this.loadedGameList$;
  }

  getIsLoadedStatus() {
    return this.isLoadedList;
  }

  setIsLoadedStatus(isLoaded) {
    this.isLoadedList = isLoaded;
  }

  getScrollPosition() {
    return this.scrollPosition;
  }

  setScrollPosition(scrollPosition) {
    this.scrollPosition = scrollPosition;
  }
}

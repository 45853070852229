import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {BreakpointObserverService} from "../../../core/breakpoint-observer/breakpoint-observer.service";

@Component({
  selector: 'hun-terms-of-condition',
  templateUrl: './terms-of-condition.component.html',
  styleUrls: ['./terms-of-condition.component.scss']
})
export class TermsOfConditionComponent implements OnInit {

  isWeb: Observable<boolean>;

  constructor(
    private breakpointObserverService: BreakpointObserverService,
  ) { }

  ngOnInit(): void {
    this.isWeb = this.breakpointObserverService.isWeb;
  }

}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {APP_ROUTES} from "../../../../../app.routes.config";
import {Router} from "@angular/router";
import {filter, takeUntil} from "rxjs/operators";
import {DialogService} from "../dialog/dialog.service";
import {AuthService} from "../../../../../core/services/auth.service";
import {Observable, Subject} from "rxjs";
import {CmsContentService} from "../../../../../core/services/cms-content.service";
import {CMSContentConfigModel} from "../../../../models/CMSContentConfigModel";

@Component({
  selector: 'hun-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit, OnDestroy {

  @Input() item: any;

  @Input() showTimer: boolean;

  @Input() isTrending: boolean;

  @Input() showButton: boolean;

  @Input() topTagsPosition: boolean;

  @Input() styleAsSubtitle: boolean;

  @Input() buttonText: string;

  @Input() subTitleField: string;

  @Input() titleField: string;

  @Input() chipListName: any[];

  @Input() isSquareImage: boolean;

  @Input() isStreak: boolean;

  @Input() timerLabel: boolean;

  @Input() imageName: string;

  @Input() cmsImageName: string;

  @Input() cmsContent$: Observable<CMSContentConfigModel>;

  @Output() selectedCard = new EventEmitter<any>();

  isLoggedIn = false;

  isImageLoading = false;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private authService: AuthService,
    public cmsContentService: CmsContentService
  ) {
  }

  ngOnInit(): void {
    this.authService.getIsCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(val => {
        this.isLoggedIn = val;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getStyle(chipName) {
    switch (chipName) {
      case 'type':
        return 'tag-chip-style';
      case 'nft':
        return 'nft-chip-style';
      case 'brand':
        return 'logo-chip-style';
        case 'day':
        return 'day-chip-style';
    }
    return null;
  }

  onCardSelected(item) {
    this.selectedCard.emit(item);
  }

  getItemName(item) {
    if (item.length > 3) return item;
    return item.toUpperCase();
  }

  onImageLoaded() {
    this.isImageLoading = true;
  }

  filterChips(chips) {
    return chips.filter(ch => this.item[ch.label] || ch.label === 'brand');
  }
}

<div class="header-container" [ngClass]="{'without-button': isShowButton()}">
  <div *ngIf="isShowLogo">
    <ng-container>
      <a routerLink="/">
        <img src="../../../../../../assets/image/only-logo.svg"/>
      </a>
    </ng-container>
  </div>
  <h1 [ngClass]="{'long-text': this.view === 'authentification' || this.view === 'sign-up' || this.view === 'sign-in'}">{{pageName}}</h1>
</div>

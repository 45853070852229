import {Component, Input, OnInit} from '@angular/core';
import {SingleLeaderModel} from "../../../../../shared/models/LeaderboardsDetail";
import {cloneDeep} from "lodash";
import {Observable} from "rxjs";
import {CMSContentConfigModel} from "../../../../../shared/models/CMSContentConfigModel";

@Component({
  selector: 'hun-full-leader-point',
  templateUrl: './full-leader-point.component.html',
  styleUrls: ['./full-leader-point.component.scss']
})
export class FullLeaderPointComponent implements OnInit {

  @Input() cmsContent$: Observable<CMSContentConfigModel>;

  leader: SingleLeaderModel;

  @Input() set setLeader (leader: SingleLeaderModel) {
    const newLeader = cloneDeep(leader);
    if (!newLeader.prizeImageUrl) newLeader.prizeImageUrl = '../../../../../../assets/image/nft-2.png';
    this.leader = newLeader;
  }
  @Input() order: number;

  constructor() { }

  ngOnInit(): void {
  }

}

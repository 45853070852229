import {Component, Input, OnInit} from '@angular/core';
import {CmsContentService} from "../../../../../core/services/cms-content.service";

@Component({
  selector: 'hun-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() isLoggedIn: boolean;

  @Input() additionalFooterInfo: boolean;

  currentYear: number;

  constructor(
    public cmsContentService: CmsContentService
  ) { }

  ngOnInit(): void {
    const date = new Date();
    this.currentYear = date.getFullYear();
  }
}

import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {BreakpointObserverService} from "../../../core/breakpoint-observer/breakpoint-observer.service";
import {ActivatedRoute, Router} from "@angular/router";
import {filter, takeUntil} from "rxjs/operators";
import {StreakGameDetailModel, StreakGamePrizeDetailModel} from "../../../shared/models/StreakGame";
import {GameStatus} from "../../../shared/enums/game-status";
import {cloneDeep} from "lodash";
import {APP_ROUTES} from "../../../app.routes.config";
import {StreakService} from "../../../core/services/streak.service";
import {DialogService} from "../../../shared/modules/common-control-components/components/dialog/dialog.service";
import {AuthService} from "../../../core/services/auth.service";
import {DateService} from "../../../core/services/date.service";
import {APP_DATA} from "../../../general.app.config";
import {RoundTypeEnum} from 'src/app/shared/enums/roundTypeEnum';
import {GameService} from "../../../core/services/game.service";
import {SnackBarService} from "../../../core/services/snack-bar.service";
import {Location} from "@angular/common";
import {environment} from "../../../../environments/environment";
import {CmsContentService} from "../../../core/services/cms-content.service";
import {MetaService} from "../../../core/services/meta.service";

@Component({
  selector: 'hun-play-streak-game',
  templateUrl: './streak-game-landing.component.html',
  styleUrls: ['./streak-game-landing.component.scss']
})
export class StreakGameLandingComponent implements OnInit, OnDestroy {

  GameStatus = GameStatus;

  isLoading = true;

  isWeb: Observable<boolean>;

  isSmall: Observable<boolean>;

  isXLargeHeight: Observable<boolean>;

  streakGame: StreakGameDetailModel;

  count: number;

  prizeList: StreakGamePrizeDetailModel[];

  rowCounter: number;

  rows = [];

  currentStreakDayId: number;

  maxAchievedLevel: number;

  currentLevel: number;

  APP_ROUTES = APP_ROUTES;

  currentStreakNumber: number;

  currentStreakId: number;

  streaksTotal: number;

  isLoggedIn = false;

  gameId: number;

  buttonText: string;

  appData = APP_DATA;

  isUserWinner = false;

  manyItems = false;

  RoundTypeEnum = RoundTypeEnum;

  isPreviewMode = false;

  private unsubscribe$: Subject<void> = new Subject();

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.count = Math.ceil(window.innerWidth / 40);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private breakpointObserverService: BreakpointObserverService,
    private streakService: StreakService,
    private router: Router,
    private dialogService: DialogService,
    private authService: AuthService,
    private dateService: DateService,
    private gameService: GameService,
    public snackBarService: SnackBarService,
    private location: Location,
    public cmsContentService: CmsContentService,
    private metaService: MetaService
  ) {
  }

  ngOnInit(): void {
    this.metaService.setMetaTags(this.cmsContentService.cmsContent$.value['seo_content']['streak-game-landing']);
    this.count = Math.ceil(window.innerWidth / 40);
    this.isXLargeHeight = this.breakpointObserverService.isXLargeHeight;
    this.isWeb = this.breakpointObserverService.isWeb;
    this.isSmall = this.breakpointObserverService.isXMediumScreen;

    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams && queryParams.accessToken && queryParams.uniqueKey) {
      this.processAsPreview(queryParams)
    } else {
      this.processAsB2CLogin();
    }
  }

  processAsPreview(queryParams) {
    this.gameService.getPreviewGame(queryParams.uniqueKey, queryParams.accessToken)
      .subscribe((gameDetails: StreakGameDetailModel) => {
          this.isPreviewMode = true;
          this.prepareGame(gameDetails);
        },
        error => {
          this.isLoading = false;
          this.snackBarService.showSnackBar(
            this.cmsContentService.cmsContent$.value['text_content']['snack-bar-preview-error-text']
          );
          this.location.back();
        })
  }

  processAsB2CLogin() {
    this.authService.processeCurrentUser();

    this.authService.getIsCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(val => {
        this.isLoggedIn = val;
      });

    this.gameId = +this.activatedRoute.snapshot.paramMap.get('id');

    this.streakService.getStreakGameById(this.gameId).pipe(takeUntil(this.unsubscribe$))
      .subscribe((streakGame: StreakGameDetailModel) => {
        this.prepareGame(streakGame);
      })
  }

  prepareGame(streakGame: StreakGameDetailModel) {
    this.isLoading = false;

    this.streakGame = streakGame;
    this.isUserWinner = streakGame.maxAchievedLevel === streakGame.prizes.length;
    this.buttonText = streakGame.type === RoundTypeEnum.VideoStreak && streakGame.hasPlayedToday ? 'Play again tomorrow' : 'Play';
    this.streaksTotal = streakGame.prizes.length;
    this.maxAchievedLevel = streakGame.maxAchievedLevel;
    this.currentLevel = streakGame.currentLevel;
    this.currentStreakDayId = streakGame.currentStreakDayId;
    const prepearedAnswers = this.processPrizeList(streakGame.prizes);

    this.rowCounter = Math.ceil(streakGame.prizes.length / 3);
    [...Array(this.rowCounter).keys()].forEach(row => {
      const rowItems = prepearedAnswers.slice(row === 0 ? row : (row * 3), 3 * (row + 1));
      while (rowItems.length < 3) {
        // @ts-ignore
        rowItems.push({});
      }
      this.rows.push(rowItems);
    })
    this.manyItems = this.rowCounter * 100 > window.innerHeight - (115 + 200);
    this.prizeList = this.rows.reverse();

    // @ts-ignore
    const currentRowNumber = this.prizeList.findIndex(arr => arr.some(el => el.isCurrent)) - 1;

    setTimeout(() => {
      const objDiv = document.getElementById("main-content-area");
      const rowHeight = 96;
      objDiv.scrollTo(0, currentRowNumber * rowHeight);
    }, 0);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isHideTimer() {
    return this.dateService.isPreviousDate(this.streakGame.closeDate)
  }

  onPlayGame() {
    if (!this.isLoggedIn) {
      this.redirectToLogin();
      return;
    }
    const {currentStreakNumber, streakGame: {id}, streaksTotal} = this;
    this.router.navigate([
        `${APP_ROUTES.GAME}/${APP_ROUTES.PLAYING_STREAK}/${this.currentStreakDayId}`],
      {queryParams: {steak: currentStreakNumber, roundId: id, total: streaksTotal}}
    );
  }

  processPrizeList(prizes: StreakGamePrizeDetailModel[]) {
    return prizes.map((prize: StreakGamePrizeDetailModel, index: number) => {
      const newPrize = cloneDeep(prize);
      newPrize.prizeType = prize.prize ?  prize.prize.type : 'POINTS';
      newPrize.nftSourceImageUrl = prize.prize && prize.prize.imageUrl;
      newPrize.isProgressed = index < this.currentLevel;
      newPrize.isMaxProgress = this.maxAchievedLevel &&
        this.maxAchievedLevel > this.currentLevel &&
        index === this.maxAchievedLevel;
      if (index === this.currentLevel) {
        newPrize.isCurrent = true;
        const {day, id} = prize;
        this.currentStreakNumber = day;
        this.currentStreakId = id;
      }
      if (newPrize.prizeType === 'POINTS' && !prize.points) {
        newPrize.points = 0;
      }
      return newPrize;
    });
  }

  onShowHistory() {
    if (this.isPreviewMode) return;
    if (!this.isLoggedIn) {
      this.redirectToLogin();
      return;
    }
    this.router.navigate([
      `${APP_ROUTES.STREAK_HISTORY}/${this.gameId}`],
      {queryParams: {type: this.streakGame.type === RoundTypeEnum.VideoStreak ? 'video' : 'text'}}
    );
  }

  redirectToLogin() {
    this.dialogService.openNeedSignIn();
  }

  showNft(imageUrl) {
    this.dialogService.openImage({imageUrl});
  }

  publishPreview() {
    const url = environment.apiUrl.replace('api', 'admin');
    window.opener.postMessage('roundPublish', url);
    window.close();
  }

  openShareDialog() {
    this.dialogService.openShareModal(this.streakGame.name, window.location.href);
  }
}

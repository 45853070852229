import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ProcessTextService} from "./process-text.service";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {UriConfig} from "../../app.config";
import {BrandDetailsModel, SingleBrandDetail} from "../../shared/models/BrandDetail";
import {UpsellDetailsModel} from "../../shared/models/UpsellDetail";

@Injectable({
  providedIn: 'root'
})
export class BrandsService {
  constructor(
    private processTextService: ProcessTextService,
    protected http: HttpClient,
    private uriConfig:UriConfig,
  ) { }

  getBrandsList (pageNumber): Observable<{ records: BrandDetailsModel[], total: number }>{
    const requestBody = {pageNumber, pageSize: 5, sortDirection: 'asc', sortField: 'name'};

    let params = new HttpParams();
    Object.keys(requestBody).forEach(param => {
      params = params.set(param, requestBody[param]);
    })
    return this.http.get<{ records: BrandDetailsModel[], total: number }>(this.uriConfig.brands, { params });
  }

  getBrandById(brandId): Observable<SingleBrandDetail> {
    return this.http.get<SingleBrandDetail>(this.uriConfig.brands + '/' + brandId);
  }

  toggleFollowBrand(brandId, isFollowing): Observable<HttpResponse<any>> {
    return this.http.patch<HttpResponse<any>>(this.uriConfig.brands + '/' + brandId, {isFollowing})
  }

  getUpsell() {
    return this.http.get<UpsellDetailsModel>(this.uriConfig.vendors + '/upsell');
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "../material/material.module";
import {HomeScreenComponent} from './home-screen.component';
import {HomeScreenRoutingModule} from "./home-screen.routing.module";
import {TrandingGamesComponent} from './components/tranding-games/tranding-games.component';
import {CommonControlComponentsModule} from "../shared/modules/common-control-components/common-control-components.module";
import {TopBrandsComponent} from './components/top-brands/top-brands.component';
import {UpcomingGamesComponent} from './components/upcoming-games/upcoming-games.component';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import { GamesComponent } from './content-tabs/games/games.component';
import { LeaderboardComponent } from './content-tabs/leaderboard/leaderboard.component';
import { YourNftsComponent } from './content-tabs/your-nfts/your-nfts.component';
import { ProfileComponent } from './content-tabs/profile/profile.component';
import {ReactiveFormsModule} from "@angular/forms";
import { ClubLandingComponent } from './components/club-landing/club-landing.component';
import { GameLandingComponent } from './components/game-landing/game-landing.component';
import {ScrollingModule} from "@angular/cdk/scrolling";
import {AuthContainerComponent} from "../auth/auth-container.component";
import {AuthModule} from "../auth/auth.module";
import { MonthlyComponent } from './content-tabs/leaderboard/filtered-components/monthly/monthly.component';
import { WeeklyComponent } from './content-tabs/leaderboard/filtered-components/weekly/weekly.component';
import { HeroesComponent } from './content-tabs/leaderboard/filtered-components/heroes/heroes.component';
import { ShortLeaderPointComponent } from './content-tabs/leaderboard/filtered-components/short-leader-point/short-leader-point.component';
import { FullLeaderPointComponent } from './content-tabs/leaderboard/filtered-components/full-leader-point/full-leader-point.component';
import {PipesModule} from "../shared/pipes/pipes.module";
import { NftItemComponent } from './content-tabs/your-nfts/nft-tabs-components/nft-item/nft-item.component';
import { GameItemComponent } from './content-tabs/your-nfts/nft-tabs-components/game-item/game-item.component';
import {ClipboardModule} from "@angular/cdk/clipboard";
import {StreakGamesComponent} from "./components/streak-games/streak-games.component";
import {StreakGameLandingComponent} from "./components/streak-game-landing/streak-game-landing.component";

@NgModule({

    exports: [
        NftItemComponent
    ],
  imports: [
    HomeScreenRoutingModule,
    CommonModule,
    MaterialModule,
    CommonControlComponentsModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    ScrollingModule,
    AuthModule,
    PipesModule,
    ClipboardModule
  ],
  declarations: [
    HomeScreenComponent,
    TrandingGamesComponent,
    TopBrandsComponent,
    UpcomingGamesComponent,
    StreakGamesComponent,
    GamesComponent,
    LeaderboardComponent,
    YourNftsComponent,
    ProfileComponent,
    ClubLandingComponent,
    GameLandingComponent,
    StreakGameLandingComponent,
    AuthContainerComponent,
    MonthlyComponent,
    WeeklyComponent,
    HeroesComponent,
    ShortLeaderPointComponent,
    FullLeaderPointComponent,
    NftItemComponent,
    GameItemComponent
  ],
})
export class HomeScreenModule {
}

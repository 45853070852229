import { Component, OnInit } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {BreakpointObserverService} from "../../../core/breakpoint-observer/breakpoint-observer.service";
import {AuthService} from "../../../core/services/auth.service";
import {LocalStorageService} from "../../../core/services/local-storage.service";
import {APP_DATA} from "../../../general.app.config";
import {SaasSetingsService} from "../../../core/services/saas-setings.service";
import {takeUntil} from "rxjs/operators";
import { CmsContentService } from 'src/app/core/services/cms-content.service';
import {MetaService} from "../../../core/services/meta.service";

@Component({
  selector: 'hun-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {

  isWeb: Observable<boolean>;

  isSmall: Observable<boolean>;

  isMedium: Observable<boolean>;

  isXSmall: Observable<boolean>;

  isLoggedIn = false;

  userPts = 245;

  isStreakGameLoaded = false;

  isTrendingGameLoaded = false;

  isTopBrandsLoaded = true;

  upcomingGameChips = [
    {name: 'type', label: 'type'},
    {name: 'sport', label: 'sport'},
    {name: 'brand', isImage: true, label: 'brand'},
  ]

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private breakpointObserverService: BreakpointObserverService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private saasSetingsService: SaasSetingsService,
    public cmsContentService: CmsContentService,
    private metaService: MetaService
  ) {
  }

  ngOnInit(): void {
    this.metaService.setMetaTags(this.cmsContentService.cmsContent$.value['seo_content']['games-screen']);
    this.saasSetingsService.settingConfigArray$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(config => console.log(config));
    this.cmsContentService.cmsContent$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(content => console.log(content));
    this.localStorageService.removeItem(APP_DATA.LATEST_SELECTION_FILTER)
    this.isWeb = this.breakpointObserverService.isWeb;
    this.isSmall = this.breakpointObserverService.isSmallScreen;
    this.isMedium = this.breakpointObserverService.isMediumScreen;
    this.isXSmall = this.breakpointObserverService.isXSmall;

    this.isLoggedIn = !!this.authService.getCurrentUser();
  }

  isAllSectionLoaded() {
    return this.isStreakGameLoaded && this.isTrendingGameLoaded && this.isTopBrandsLoaded;
  }

  onTopBrandsLoaded (event) {
    this.isTopBrandsLoaded = event;
  }

  onTrandingGameLoaded (event) {
    this.isTrendingGameLoaded = event;
  }

  onStreakGameLoaded (event) {
    this.isStreakGameLoaded = event;
  }

}

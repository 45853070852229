<ng-container *ngIf="cmsContent$ | async as cmsContentConfig">
  <mat-toolbar
    *ngIf="!(isWeb | async) && isShowBackButton">
    <hun-back-button
      [customClickAction]="false"
      [opacityBackground]="true">
    </hun-back-button>
  </mat-toolbar>
  <div class="container"
       [ngClass]="{
     'title-with-property': title,
     'scroll': filterOption}">
    <img src="assets/image/white-Logo.svg">
    <div class="page-title" *ngIf="title" [ngClass]="{'space': title !== 'Locker'}"> {{title}} </div>
    <div *ngIf="title === 'Locker' && chipValue !== undefined" class="chip-container">
      <hun-tag-chip *ngIf="isDataLoaded" [chipValue]="chipValue + ' ' + cmsContentConfig['text_content']['games(profile)-points-label']"></hun-tag-chip>
      <mat-spinner *ngIf="!isDataLoaded" [diameter]="18" color="primary"></mat-spinner>
    </div>
    <div class="filter" *ngIf="filterOption && filterOption.length">
      <hun-header-filter
        [location]="currentLocation"
        [filterOption]="filterOption"
        (selectedFilter)="onSelectedFilter($event)">
      </hun-header-filter>
    </div>
  </div>
</ng-container>



import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators
} from "@angular/forms";
import {CustomValidators} from "ng2-validation";
import {APP_DATA} from "../../../../../general.app.config";
import {PasswordValidator} from "../../../../../auth/components/password-validator";
import {SetNewPasswordFormType} from "../../../../FormsInterface/set-new-password-form-interface";
import {takeUntil} from "rxjs/operators";
import {FormsService} from "../../../../../core/services/forms.service";
import {CMSContentConfigModel} from "../../../../models/CMSContentConfigModel";
import {
  PasswordValidationRuleProviderService
} from "../../../../../auth/components/password-validation-rule-provider.service";

@Component({
  selector: 'hun-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss']
})
export class SetNewPasswordComponent implements OnInit, OnDestroy {

  @Input() isWeb: Observable<boolean>;

  @Input() isForgotPassword:boolean;

  @Input() isPasswordChanged:boolean;

  @Input() changeButtonTitle:string;

  @Input() serverErrorMessage:string;

  @Input() cmsContent$: Observable<CMSContentConfigModel>;

  @Output() newPassword = new EventEmitter<{ password: string, oldPassword: string }>();

  @Output() onChangeOldPassword = new EventEmitter<void>();

  @Output() formChanged = new EventEmitter<void>();

  @Input() formError$: Subject<{ massage, field }>;

  changePassword: FormGroup<SetNewPasswordFormType>;

  buttonTitle = 'Change password';

  appData = APP_DATA;

  errorMassage: string;

  isServerError = false;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private fb: NonNullableFormBuilder,
    private formsService: FormsService,
    private passwordValidationProvider: PasswordValidationRuleProviderService
  ) {
  }

  ngOnInit(): void {
    this.buildForm();

    this.changePassword.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        if (this.isServerError) {
          this.formChanged.emit();
        }
      });

    this.formError$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorObject) => {
        if (!errorObject) return;

        if (errorObject.massage) {
          const {massage, field} = errorObject;
          this.isServerError = true;
          this.formsService.bindErrors(massage, this.changePassword, field);
        } else {
          const {field} = errorObject;
          this.isServerError = false;
          this.formsService.bindErrors(null, this.changePassword, field);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private buildForm(): void {
    const passwordControl = new FormControl<string | null>('', Validators.compose([
      Validators.required,
      PasswordValidator.patternValidator(/\d/, {hasNumber: true}),
      PasswordValidator.patternValidator(/[A-Z]/, {hasUpperCase: true}),
      PasswordValidator.patternValidator(/[a-z]/, {hasLowerCase: true}),
      PasswordValidator.patternValidator(/[#?!\[\]~`@$%^&\\'*\-<>(){}|\/"]/, {hasSpecialCharacters: true}),
      Validators.minLength(8),
      Validators.maxLength(20),
    ]));
    const passwordConfirmControl = new FormControl<string | null>('', [Validators.required,
      CustomValidators.equalTo(passwordControl)]);

    this.changePassword = this.fb.group({
      password: passwordControl,
      passwordConfirmation: passwordConfirmControl,
    });

    if (!this.isForgotPassword) {
      this.changePassword.addControl(
        'oldPassword',
        this.fb.control('', [Validators.required]));
    }
  }

  hasPasswordError(): boolean {
    let response = false;
    for (let i = 0; i < this.passwordValidationProvider.validatorsRule.length; i++) {
      const errorName = this.passwordValidationProvider.validatorsRule[i].name;
      response = this.changePassword.get('password').hasError(errorName)
        || !this.changePassword.value.password;
      this.errorMassage = this.passwordValidationProvider.validatorsRule[i].massage
      if (response) break;
    }
    return response;
  }

  onChangePassword() {
    const password = this.changePassword.value.password
    const oldPassword = this.changePassword.value.oldPassword

    const passwordData = {password, oldPassword};
    this.newPassword.emit(passwordData);
  }

}

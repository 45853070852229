import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'hun-tag-chip',
  templateUrl: './tag-chip.component.html',
  styleUrls: ['./tag-chip.component.scss']
})
export class TagChipComponent implements OnInit, OnChanges {

  @Input() chipValue: string;

  @Input() image: boolean;

  isDataLoaded = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.chipValue && changes.chipValue.currentValue) {
      this.isDataLoaded = true;
    }
  }
}

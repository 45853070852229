import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {BreakpointObserverService} from "../../../core/breakpoint-observer/breakpoint-observer.service";

@Component({
  selector: 'hun-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  isWeb: Observable<boolean>;


  constructor(
    private breakpointObserverService: BreakpointObserverService,
  ) { }

  ngOnInit(): void {
    this.isWeb = this.breakpointObserverService.isWeb;
  }

}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {UriConfig} from "../../app.config";
import {SignUpUser} from "../../shared/models/signUpUser";
import {BehaviorSubject, Observable} from "rxjs";
import * as Sentry from "@sentry/angular";
import {LocalStorageService} from "./local-storage.service";
import {User} from "../../shared/models/User";
import {UserLoginEnum} from "../../shared/enums/userLoginEnum";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  httpOptions = {
    headers: new HttpHeaders({responseType: 'text'}),
  }

  currentUser$ = new BehaviorSubject<boolean>(false);

  loginType$ = new BehaviorSubject<UserLoginEnum>(null);

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
    private localStorageService: LocalStorageService
  ) { }

  userSignUp(user: SignUpUser) {
    return this.http.post(this.uriConfig.signIp, user, this.httpOptions);
  }

  userSignIn(user: User): Observable<{accessToken: string, refreshToken: string }> {
    // @ts-ignore
    return this.http.post(this.uriConfig.login, user, this.httpOptions);
  }

  userSignInWithGoogle (token: string): Observable<{accessToken: string, refreshToken: string }> {
    return this.http.post<{accessToken: string, refreshToken: string }>
    (this.uriConfig.signInGoogle, {token});
  }

  userSignInWithFacebook (token: string): Observable<{accessToken: string, refreshToken: string }> {
    return this.http.post<{accessToken: string, refreshToken: string }>
    (this.uriConfig.signInFacebook, {token});
  }

  setSentryUser(user: sentryUser) {
    const {email} = user;
    Sentry.setUser({email});
  }

  refreshToken (token) {
    return this.http.post<any>(this.uriConfig.refresh, {}, token);
  }

  getCurrentUser() : string {
    return this.localStorageService.getToken();
  }

  getRefreshToken() : string {
    return this.localStorageService.getRefreshToken();
  }

  logoutSentry() {
    Sentry.configureScope(scope => scope.setUser(null));
  }

  validateRestoreToken(body) {
    return this.http.post<any>(this.uriConfig.validateResetToken, body)
  }

  confirmEmailRegistration(body) {
    return this.http.post<any>(this.uriConfig.confirm, body)
  }

  resetPassword(body: User) {
    return  this.http.post(this.uriConfig.resetPassword, body, {responseType: 'text'})
  }

  restorePassword(body): Observable<any> {
    return this.http.post<string>(this.uriConfig.restorePassword, body)
  }

  changePassword(body): Observable<any> {
    return this.http.post<string>(this.uriConfig.changePassword, body)
  }

  logOut() {
    return this.http.delete(this.uriConfig.logout);
  }

  resendConfirmationLetter(body) {
    return this.http.post(this.uriConfig.resendConfirmationLetter, body);
  }

  logOutLocalStorage () {
    this.localStorageService.logOut();
  }

  setCurrentUserLoggedIn () {
    this.currentUser$.next(true);
  }


  getIsCurrentUser () {
    return this.currentUser$;
  }

  setCurrentUserLoggedOut () {
    this.currentUser$.next(false);
  }

  processeCurrentUser() {
    if ( this.getCurrentUser()) this.currentUser$.next(true);
  }

  setCurrentLoginType (type) {
    this.loginType$.next(type);
  }

  getCurrentLoginType () {
    return this.loginType$;
  }
}

export interface sentryUser {
  email: string;
  role: string;
}

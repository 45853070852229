import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthContainerComponent} from "./auth-container.component";
import {AuthViewEnum} from "./enums/auth-view.enum";

const routes: Routes = [
  {
    path: AuthViewEnum.RESTORE_PASSWORD,
    component: AuthContainerComponent,
  },
  {
    path: AuthViewEnum.AUTH_SIGN_UP,
    component: AuthContainerComponent,
  },
  {
    path: AuthViewEnum.AUTH_SIGN_IN,
    component: AuthContainerComponent,
  },
  {
    path: AuthViewEnum.AUTH_FORGOTTEN_PASSWORD,
    component: AuthContainerComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})

export class AuthRoutingModule { }

<div class="club-landing-container"
     [ngClass]="{
     'desktop-style': isWeb | async,
     'with-overlay': isButtonClicked}">
  <div class="header-container">
    <div class="gradient">
      <mat-toolbar
        *ngIf="!(isWeb | async)">
        <hun-back-button
          [customClickAction]="false"
          [opacityBackground]="true"
          *ngIf="!(isWeb | async)">
        </hun-back-button>
      </mat-toolbar>

      <div
        class="club-landing-body">
        <hun-image-with-loader
          class="background"
          *ngIf="brandImageUrl"
          [imageSrc]="brandImageUrl"
          [overlayHeight]="'200px'"
          [spinnerSize]="60"
        >
        </hun-image-with-loader>
        <div *ngIf="!isBrandWithImage" class="default-background"> </div>

        <div class="brand-info-container">
          <div class="brand-name" [style.font-size.px]="desktopFontSize" *ngIf="(isWeb | async) else shortName">
            {{brand?.name}}
          </div>
          <ng-template #shortName>
            <div class="brand-name" [style.font-size.px]="fontSize">
              {{brand?.name}}
            </div>
          </ng-template>


          <span class="followers">
          {{brand?.numberOfFollowers}}
        </span>

          <button
            matRipple
            [matRippleCentered]="true"
            [matRippleRadius]="20"
            [matRippleUnbounded]="false"
            [ngClass]="{'follow': this.isFollowing}"
            class="follow-button"
            mat-flat-button
            color="warn"
            (click)="toggleFollow()">
            <mat-spinner *ngIf="followStatusChanges" [diameter]="18" color="primary"></mat-spinner>
            {{followButtonText}}
          </button>

        </div>
      </div>
    </div>
  </div>

  <div class="brand-description">
    {{brand?.brandDescription}}
  </div>

  <div class="squiggly-line" [ngClass]="{'desktop-style': isWeb | async}">
    <img>
  </div>
  <hun-upcoming-games
    [chipNameList]="upcomingGameChips"
    [isBrandPage]="true"
    [brandId]="brandId"
    [isWeb]="isWeb"
    [isSmall]="isSmall"
    [isMedium]="isMedium"
    [isXSmall]="isXSmall"
  >
  </hun-upcoming-games>
</div>

<div *ngIf="isLoading" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>

<ng-container *ngIf="cmsContentService.cmsContent$ | async as cmsContentConfig">
  <div *ngIf="isPreviewMode" class="preview-line">
    <div class="botton-container">
      <button
        (click)="publishPreview()"
        class="admin-button"
        mat-flat-button
        color="primary"
        id="play-game">
        Publish
      </button>
    </div>
  </div>
  <div
    *ngIf="!isLoading"
    class="club-landing-container"
    [ngClass]="{'desktop-style': isWeb | async, 'preview': isPreviewMode}">
    <div class="content-container">
      <div class="header-container">
        <div class="gradient">
          <mat-toolbar
            *ngIf="!(isWeb | async)">
            <hun-back-button
              [customClickAction]="false"
              [opacityBackground]="true"
              [disable]="isPreviewMode"
              *ngIf="!(isWeb | async) && !isPreviewMode">
            </hun-back-button>
            <div class="brand" (click)="navigateToBrand()" [ngClass]="{'disabled': isPreviewMode}">
              <span class="brand-name">{{game.brandName}}</span>
              <hun-image-with-loader
                [imageSrc]="game.brandImageUrl || cmsContentConfig['image_content']['default-brand-logo']"
                [overlayHeight]="'24px'"
                [fixedOverleyWidth]="'24px'"
                [spinnerSize]="20"
              >
              </hun-image-with-loader>
            </div>
          </mat-toolbar>

          <div class="game-landing-body">
            <hun-image-with-loader
              class="game-background"
              [imageSrc]="(isWeb | async) ?
              game.backgroundImageDesktopUrl || cmsContentConfig['image_content']['default-game-desktop-BG'] :
              game.backgroundImageMobileUrl || cmsContentConfig['image_content']['default-game-mobile-BG']"
              [overlayHeight]="(isWeb | async) ? '200px' : '262px'"
              [spinnerSize]="60"
            >
            </hun-image-with-loader>

            <div class="game-info-container">

              <div class="brand" *ngIf="isWeb | async" (click)="navigateToBrand()"
                   [ngClass]="{'disabled': isPreviewMode}">
                <span class="brand-name">{{game.brandName}}</span>
                <hun-image-with-loader
                  [imageSrc]="game.brandImageUrl || cmsContentConfig['image_content']['default-brand-logo']"
                  [overlayHeight]="'24px'"
                  [fixedOverleyWidth]="'24px'"
                  [spinnerSize]="20"
                >
                </hun-image-with-loader>
              </div>
              <div class="descr">
                <h1 class="game-name">
                  {{game.name}}
                </h1>
                <div class="game-olay-details">
                  <div class="number-of-players" *ngIf="game.playersCount >= appData.MIN_PLAYER_COUNT">
                    {{game.playersCount}} {{playersPlaceholder}}&nbsp;|&nbsp;
                  </div>
                  <div class="game-open-date">
                    {{game.openDate| date: 'dd MMM YYYY'}}
                  </div>
                </div>

                <div class="bottom-header-line">
                  <div class="empty-chank"></div>
                  <div>
                    <hun-countdown-timer
                      *ngIf="isOpenGame()"
                      [endDate]="game.closeDate"
                      [timerLabel]="true"
                      [isShortView]="true"
                      class="tag-chip">
                    </hun-countdown-timer>
                  </div>
                  <div>
                    <button
                      [disabled]="isPreviewMode"
                      class="regular-button secondary-button share-button"
                      mat-flat-button
                      (click)="openShareDialog()"
                      color="primary">
                      {{cmsContentConfig['text_content']['share-button']}}
                      <mat-icon class="material-icons material-icons-outlined reply-icon">reply</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="game.prizeImageUrl">
        <div class="nft-container">
          <div class="nft-content">
            <h2>
              {{cmsContentConfig['text_content']['prediction-landing-prizes-label']}}
            </h2>
            <div>
              <ng-container *ngIf="!isPreviewMode; else disabledLink">
                <a routerLink="/prize-explained">
                  <button mat-flat-button color="accent" class="regular-button explained-button">
                    {{cmsContentConfig['text_content']['prediction-landing-prize-explained-button']}}
                  </button>
                </a>
              </ng-container>
            </div>
            <ng-template #disabledLink>
              <a>
                <button mat-flat-button color="accent" class="regular-button" [disabled]="isPreviewMode">
                  Prize Explained
                </button>
              </a>
            </ng-template>

          </div>
        </div>

        <div class="game-score">
          <div class="game-score-container">
            <hun-short-leader-point *ngIf="winner" [leader]="winner" [cmsContent$]="cmsContentService.cmsContent$"></hun-short-leader-point>
            <hun-image-with-loader
              [imageSrc]="game.prizeImageUrl"
              [overlayHeight]="'300px'"
              [ngClass]="{'space': (!!winner)}"
              [spinnerSize]="60"
            >
            </hun-image-with-loader>
            <div class="nft-win-description" *ngIf="!(!!winner)">
              {{cmsContentConfig['text_content']['prediction-landing-description-text']}}
            </div>
          </div>
        </div>
      </ng-container>

    </div>


    <div class="play-game-button squiggly-background">
      <div class="squiggly-line">
        <div class="arrow-up" *ngFor="let _ of [].constructor(count + 2)"></div>
      </div>
      <div class="background-fill">
        <div class="game-description">
          {{game.description}}
        </div>
      </div>
    </div>

    <div
      class="button-container"
      [ngClass]="{'large': isLarge | async, 'unactive': game.hasPlayed }"
      *ngIf="isOpenGame() || isPreviewMode">
      <button
        [disabled]="isPreviewMode"
        class="play-game regular-button"
        mat-flat-button
        (click)="onPlayGame()"
        color="primary">
        <ng-container *ngTemplateOutlet="buttonTextTemplate"></ng-container>
      </button>
    </div>
    <ng-template #buttonTextTemplate>
      <ng-container *ngIf="this.isPreviewMode || game.hasPlayed; else statusButton">
        {{this.isPreviewMode ?
        cmsContentConfig['text_content']['prediction-landing-open-game-button'] :
        cmsContentConfig['text_content']['prediction-landing-answered-game-button']
        }}
      </ng-container>
      <ng-template #statusButton>
        <ng-container [ngSwitch]="game.status">
          <ng-container *ngSwitchCase="gameStatus.PENDING">
            {{cmsContentConfig['text_content']['prediction-landing-pending-game-button'] + ' ' + dateDiff}}
          </ng-container>
          <ng-container *ngSwitchCase="gameStatus.OPENED">
            {{cmsContentConfig['text_content']['prediction-landing-open-game-button']}}
          </ng-container>
          <ng-container *ngSwitchCase="gameStatus.RESULT_PROCESSING">
            {{cmsContentConfig['text_content']['prediction-landing-answered-game-button']}}
          </ng-container>
        </ng-container>
      </ng-template>
    </ng-template>
  </div>
</ng-container>

<div *ngIf="isLoading" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>


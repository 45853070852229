import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {UserNftDetailsModel} from "../../../../../shared/models/NFTDetail";
import {SnackBarService} from "../../../../../core/services/snack-bar.service";
import {UserService} from "../../../../../core/services/user.service";
import {Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {UserDetailsModel} from "../../../../../shared/models/UserDetail";
import {Router} from "@angular/router";
import {YourNftService} from "../../../../../core/services/your-nft.service";
import {NftStatusEnum} from "../../../../../shared/enums/nftStatusEnum";
import tippy from 'tippy.js';
import {CmsContentService} from "../../../../../core/services/cms-content.service";
import {CMSContentConfigModel} from "../../../../../shared/models/CMSContentConfigModel";

@Component({
  selector: 'hun-nft-item',
  templateUrl: './nft-item.component.html',
  styleUrls: ['./nft-item.component.scss']
})
export class NftItemComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() nft: UserNftDetailsModel;

  @Input() openedId: number;

  @Output() isNftTranfered = new EventEmitter<any>();

  @Output() tippyOpened = new EventEmitter<number>();

  nftUrl: string;

  buttonText: string;

  NftStatusEnum = NftStatusEnum;

  isShowTippy = false;

  target: any;

  tip: any

  thisTip: any;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    public snackBarService: SnackBarService,
    private userService: UserService,
    private router: Router,
    private yourNftService: YourNftService,
    public cmsContentService: CmsContentService
  ) {
  }

  ngOnInit(): void {
    const content = this.cmsContentService.cmsContent$.value
    this.nftUrl = this.nft.prizeAddress;
    switch (this.nft.status) {
      case NftStatusEnum.PENDING:
        this.buttonText = content['text_content']['locker-transfer(pending)-button'];
        break;
      case NftStatusEnum.TRANSFERRING:
        this.buttonText = content['text_content']['locker-transfer(transferring)-button'];
        break;
      case NftStatusEnum.TRANSFERRED:
        this.buttonText = content['text_content']['locker-transfer(transferred)-button'];
        break;
      case NftStatusEnum.FAILED:
        this.buttonText = content['text_content']['locker-transfer(failed)-button'];
        break;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.createTippy();
  }

  createTippy() {
    const supportEmail = this.cmsContentService.cmsContent$.value['text_content']['locker-tooltip-mail'];
    const text = this.cmsContentService.cmsContent$.value['text_content']['locker-tooltip-text'].replace('{mail}', supportEmail);
    const html = `<div class="tooltip-content">
        <div class="hide"><img src="../../../../../../assets/image/icons/cancel.svg" height="10"></div>
        <div class="content">${text}</div>
        </div>`;
    this.target = '#tooltip' + this.nft.id
    tippy(this.target,
      {
        trigger: 'manual',
        arrow: true,
      });

    this.tip = document.querySelector(this.target);
    tippy(this.tip);
    // @ts-ignore
    this.thisTip = this.tip._tippy;
    this.thisTip.setProps({
      content:html,
      allowHTML: true,
      hideOnClick: true,
      interactive: true,
      onShown: this.onShown.bind(this),
      onClickOutside: this.onHide.bind(this),
      arrow: true,
      trigger: 'manual',
      theme: 'light-border',
    });
    const closeBtn = this.thisTip.popper.getElementsByClassName('hide');
    closeBtn[0].onclick = this.onHideByButton.bind(this)
    // @ts-ignore
    this.tip.onclick = this.trigger.bind(this)
  }

  trigger() {
    if (!this.isShowTippy) {
      this.thisTip.show();
      this.isShowTippy = true;
    } else {
      this.thisTip.hide();
      this.isShowTippy = false;
    }
  }

  onShown() {
    this.isShowTippy = true;
    this.tippyOpened.emit(this.nft.id);
  }

  onHide() {
    this.isShowTippy = false;
  }

  onHideByButton() {
    this.thisTip.hide();
    this.isShowTippy = false;
  }

  onCopied(event) {
    if (event)
      this.snackBarService.showSnackBar(this.cmsContentService.cmsContent$.value['text_content']['locker-copy-prize-text']);
  }

  onGetInfo() {
    if (this.nft.rounds.length) {
      const gameId = this.nft.rounds[this.nft.rounds.length - 1].id;
      if (this.nft.roundType.toUpperCase().includes('STREAK')) {
        this.router.navigate([`game/streak/${gameId}`]);
      } else {
        this.router.navigate([`game/${gameId}`]);
      }
    } else {
      this.snackBarService.showSnackBar(this.cmsContentService.cmsContent$.value['text_content']['locker-no-info-prize-text']);
    }
  }

  onSaveToWallet() {
    this.userService.getUserDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userDetail: UserDetailsModel) => {
        if (userDetail.metaMaskWalletAddress) {
          this.yourNftService.transferNft(this.nft.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.isNftTranfered.emit(true);
              },
              () => {
                this.isNftTranfered.emit(true);
              });
        } else {
          this.snackBarService
            .showSnackBar(
              this.cmsContentService.cmsContent$.value['text_content']['locker-meta-mask-error-text']
            );
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.openedId && changes.openedId.currentValue) {
      if (changes.openedId.currentValue !== this.nft.id) {
        this.thisTip.hide();
      }
    }
  }
}
